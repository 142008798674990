import React, { useState } from "react";
import "./EmailTemplate.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API } from "../../../global";
import toast from "react-hot-toast";
import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import { useNavigate } from "react-router";
import { Container } from "react-bootstrap";

const EmailTemplateEdit = (props) => {
  const navigate = useNavigate();

  const [temp_name, setTempName] = useState(props.editdata.template_name);
  const [tempErrorMsg, setTempErrorMsg] = useState(false);
  const [from_name, setFromName] = useState(props.editdata.from_name);
  const [fromNameErrorMsg, setFromErrorMsg] = useState(false);
  const [email, setEmail] = useState(props.editdata.from_email);
  const [emailErrorMsg, setEmailErrorMsg] = useState(false);
  const [email_subject, setEmailSubject] = useState(props.editdata.subject);
  const [subjectError, setSubjectError] = useState(false);
  const [email_body, setEmailBody] = useState(props.editdata.body);
  const [emailBodyError, setEmailBodyError] = useState(false);
  const [loading, setLoading] = useState(false);

  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ size: [] }],
      ["blockquote", "code-block"],
      ["align", { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  const decodeHtmlEntities = (html) => {
    const textarea = document.createElement("textarea");
    textarea.innerHTML = html;
    return textarea.value;
  };

  const isQuillEmpty = (content) => {
    // Trim the value and check if it's equal to the empty Quill content
    return content.trim() === "" || content === "<p><br></p>";
  };

  const validateData = () => {
    let error = false;
    const obj = {
      template_name: temp_name,
      from_mail: email,
      from_name: from_name,
      subject: email_subject,
      body: decodeHtmlEntities(email_body),
    };

    if (!obj.template_name) {
      setTempErrorMsg(true);
      error = true;
    }else{
      setTempErrorMsg(false);
    }


    if (!obj.from_name) {
      setFromErrorMsg(true);
      error = true;
    }else{
      setFromErrorMsg(false);
    }

    if (!obj.from_mail) {
      setEmailErrorMsg(true);
      error = true;
    }else{
      setEmailErrorMsg(false);
    }

    if (!obj.subject) {
      setSubjectError(true);
      error = true;
    }else{
      setSubjectError(false);
    }

    if(isQuillEmpty(email_body)){
      setEmailBodyError(true)
      error = true;
    }else{
      setEmailBodyError(false)
    }

    return error;
  };

  const editTemplate = async () => {
    const obj = {
      template_id: props.editdata._id,
      key: props.editdata.key,
      template_name: temp_name,
      from_mail: email,
      from_name: from_name,
      subject: email_subject,
      body: decodeHtmlEntities(email_body),
    };
    setLoading(true);
    if (validateData()) {
      setLoading(false);
    } else {
      fetch(`${API}/admin/tenant/email-setting/update`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(obj),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            toast.success("Edited Successfully");
            setLoading(false);
            props.fetchData();
          } else {
            if (response.error.code === 421) {
              navigate("/error");
            } else {
              toast.error(response.error.msg);
            }

            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          toast.error("Error occurred please try again");
        });
    }

    // if (isQuillEmpty(email_body)) {
    //   setEmailBodyError(true);
    //   setLoading(false);
    // } else {

    // fetch(`${API}/admin/tenant/email-setting/update`, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //     authorization: `Bearer ${localStorage.getItem("auth_token")}`,
    //   },
    //   body: JSON.stringify(obj),
    // })
    //   .then((res) => res.json())
    //   .then((response) => {
    //     if (response.success) {
    //       toast.success("Edited Successfully");
    //       setLoading(false);
    //       props.fetchData();
    //     } else {
    //       if (response.error.code === 421) {
    //         navigate("/error");
    //       } else {
    //         toast.error(response.error.msg);
    //       }

    //       setLoading(false);
    //     }
    //   })
    //   .catch((err) => {
    //     setLoading(false);
    //     toast.error("Error occurred please try again");
    //   });
    // }
  };

  const back = () => {
    props.closeEdit();
  };

  return (
    <>
      <div className="d-flex justify-content-between pt-4 px-4">
        <button className="icon-div" onClick={() => back()}>
          <img src={arrowImage} alt="" className="backImage" />
        </button>
        <div></div>
      </div>
      <Container fluid style={{ marginTop: "10px" }}>
        <Row>
          <Col>
            <div className="input-div">
              <label className="label-style">Template Name</label>
              <input
                className="input-style"
                type="text"
                value={temp_name}
                onChange={(e) => setTempName(e.target.value)}
              />
              {tempErrorMsg && (
                <p className="errorText">Template Name is required filed</p>
              )}
            </div>
            <div className="input-div">
              <label className="label-style">From Name</label>
              <input
                className="input-style"
                type="text"
                value={from_name}
                onChange={(e) => setFromName(e.target.value)}
              />
              {fromNameErrorMsg && (
                <p className="errorText">From Name is required filed</p>
              )}
            </div>
            <div className="input-div">
              <label className="label-style">Reply to email address</label>
              <input
                className="input-style"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              {emailErrorMsg && (
                <p className="errorText">Reply to email is required filed</p>
              )}
            </div>
          </Col>
        </Row>
        <Row>
          <div className="input-div">
            <label className="label-style">Email Subject</label>
            <input
              className="input-style"
              type="text"
              value={email_subject}
              onChange={(e) => setEmailSubject(e.target.value)}
            />
            {subjectError && (
              <p className="errorText">Email Subject is required filed</p>
            )}
          </div>

          <div className="input-div">
            <label className="label-style">Email Body</label>
            <div className="email-editor-div ">
              <div className="email-temp-textbox">
                <ReactQuill
                  placeholder="Enter your question here"
                  modules={modules}
                  value={email_body}
                  onChange={(e) => setEmailBody(e)}

                  // onChange={(val) => handleChangeQuestion(val, i)}
                />
                {emailBodyError && (
                  <p className="errorText">Email body is required filed</p>
                )}
              </div>
            </div>
            <p>
              <i>Available variable : {props.editdata.available_variables}</i>
            </p>
          </div>
        </Row>
        <Row>
          <Col>
            <div style={{ display: "flex", justifyContent: "end" }}>
              <button
                className="add-save-btn btn btn-md"
                onClick={() => editTemplate()}
                disabled={loading}
              >
                {loading ? "Saving.." : "Save"}
              </button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EmailTemplateEdit;
