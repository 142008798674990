import React, { useEffect, useState } from "react";
import "./login.css";
import { API } from "../../../global";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import TermsAndCondition from "../TermsAndCondition";
import { setThemeData } from "../../../Styles/ThemeChange";

export default function Login() {
  const navigate = useNavigate();
  const { id } = useParams();
  // const { testid } = useParams()
  const [mail, setMail] = useState(null);
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [hideInput, setHideInput] = useState(false);
  const [conditionChecked, setConditionChecked] = useState(false);
  const [optBtnLoading, setOtpBtnLoading] = useState(false);
  const [varifyLoadng, setVarifyLoading] = useState(false);
  // const [uploadspeed, setUploadspeed] = useState(false);
  // const [hidespeed, setHidespeed] = useState(false);
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    getTenant();
    // eslint-disable-next-line
  }, []);

  const getTenant = () => {
    const obj = {
      interview_id: id,
    };
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setThemeData(response?.result?.tenant_theme_settings);
        } else {
          toast.error(response.error.msg);
        }
      })
      .catch(() => {});
  };

  const getOtp = () => {
    setOtpBtnLoading(true);
    const reqOTP = { email: mail, interview_id: id };

    fetch(`${API}/admin/interview/share/request-otp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(reqOTP),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          toast.success("OTP Sent");
          setHideInput(true);
          setOtpBtnLoading(false);
        } else {
          toast.error(val.error.msg);
          setOtpBtnLoading(false);
        }
      });
  };
  ///records/view/otp/verify
  const verifyOTP = () => {
    setVarifyLoading(true);
    const verifyreqOTP = { email: mail, interview_id: id, otp: otp };
    fetch(`${API}/admin/interview/share/verify-otp`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(verifyreqOTP),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          toast.success("OTP Verified");
          localStorage.setItem("record_token", val.result.token);
          setVarifyLoading(false);
          navigate(`/interview/recording/${id}`);
        } else {
          toast.error(val.error.msg);
          setVarifyLoading(false);
        }
      });
  };

  const hideTermsAndCondition = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setConditionChecked(e.target.checked);
  };

  return (
    <>
      <Toaster />
      {!show ? (
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "90vh",
              }}
            >
              <div className="login-card">
                <div className="panel-heading" style={{ textAlign: "center" }}>
                  <h3 className="panel-title">Login with OTP</h3>
                </div>
                <div className="panel-body">
                  <fieldset>
                    <div className="form-group">
                      <label htmlFor="email">E-Mail ID</label>
                      <br />
                      <input
                        className="form-control"
                        placeholder="Enter your E-Mail ID"
                        id="email"
                        name="email"
                        type="email"
                        value={mail}
                        onChange={(e) => setMail(e.target.value)}
                      />
                    </div>
                    {hideInput && (
                      <div className="form-group">
                        <label htmlFor="otp">OTP</label>
                        <br />
                        <input
                          className="form-control"
                          placeholder="Enter your OTP"
                          id="otp"
                          name="otp"
                          type="text"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                          // onBlur={formik.handleBlur}
                          // value={formik.values.otp}
                        />
                      </div>
                    )}
                    {!hideInput && (
                      <div>
                        <input
                          onChange={(e) => handleChange(e)}
                          style={{
                            margin: "5px",
                            height: "1em",
                            width: "1em",
                            verticalAlign: "middle",
                          }}
                          id="checkbox"
                          type="checkbox"
                        />

                        {/* eslint-disable-next-line */}
                        <label>
                          {" "}
                          I agree with these{" "}
                          <a
                            href="##"
                            onClick={() => {
                              setShow(true);
                            }}
                          >
                            Terms and Conditions
                          </a>
                          .
                        </label>
                      </div>
                    )}

                    <div className="button-container">
                      <button
                        className={`btn submit-btn-log ${
                          !conditionChecked || !mail ? "disabled" : "otp-active"
                        }`}
                        disabled={!conditionChecked || !mail}
                        onClick={() => (!hideInput ? getOtp() : verifyOTP())}
                      >
                        {!hideInput
                          ? optBtnLoading
                            ? "Loading.."
                            : "Get OTP"
                          : varifyLoadng
                          ? "Submitting.."
                          : "Submit"}
                      </button>
                      <button className="btn cancel-btn">Cancel</button>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <TermsAndCondition hide={hideTermsAndCondition} />
      )}
    </>
  );
}
