import React, { useEffect, useState } from "react";
import "./Settings.css";
import "./CommonCard/CommonCard.css";
import CommonCard from "./CommonCard/CommonCard";
import { API } from "../../global";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import toast from "react-hot-toast";
import Modal from "react-responsive-modal";
import { useNavigate } from "react-router";

const License = () => {

  const navigate = useNavigate()

  const [planDetails, setPlanDetails] = useState(null);
  const [interviewCount, setInterviewCount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalFraudDetection, setTotalFraudDetection] = useState(0);
  const [totalAiReviews, setTotalAiReviews] = useState(0);
  const [showPopup, setShowPopUp] = useState(false);

  const convertMinutesToHours = (min) => {
    let hours = min / 60; // Convert minutes to hours in decimal format

    if (hours.toFixed(1) === "0.0") {
      return "0";
    } else {
      return hours.toFixed(1); // Display hours in decimal format with one decimal place
    }
  };

  useEffect(() => {
    getRecuiterData();
    fetch(`${API}/admin/tenant/plan`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setPlanDetails(response?.result?.tenantDeatils[0]?.current_plan);
          setInterviewCount(response?.result?.interviewStatusCount);
        }else{
         
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });

      // eslint-disable-next-line
  }, []);

  const getRecuiterData = () => {
    fetch(`${API}/admin/dashboard/interview-data`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ interval: "last-30-days" }),
    })
      .then((res) => res.json())
      .then((res) => {
        // setFilterLoading(false);

        if (res.success === true) {
          setTotalAiReviews(res.result.total_ai_reviews);
          setTotalFraudDetection(res.result.total_fraud_detection_minutes);
        } else {
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      });
  };

  const upgradePlan = () => {
    setShowPopUp(!showPopup);
  };

  return (
    <div>
      {loading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <>
          <div style={{ marginTop: "20px" }}>
            <div className="row m-2">
              <div className="col-4 ">
                <p className="label">Current Plan</p>{" "}
              </div>
              <div className="col-6">
                <button className="basicButton">{planDetails?.name}</button>
              </div>
            </div>
            <div className="row m-2">
              <div className="col-4 label">
                <p className="label">Remaining Interviews</p>{" "}
              </div>
              <div className="col-6">
                <p>
                  <b>
                    {interviewCount ? interviewCount : 0} out of{" "}
                    {planDetails?.total_interviews} Interviews / Month{" "}
                  </b>
                </p>
              </div>
            </div>
            <div className="row m-2">
              <div className="col-4 label">
                <p className="label">AI powered reviews</p>
              </div>
              <div className="col-6">
                <p>
                  <b>{totalAiReviews}</b>
                </p>{" "}
              </div>
            </div>
            <div className="row m-2">
              <div className="col-4 label">
                <p className="label">Fraud Detection Hours</p>{" "}
              </div>
              <div className="col-6">
                <b>{convertMinutesToHours(totalFraudDetection)}</b>{" "}
              </div>
            </div>

            <div className="row m-2">
              <div className="col-4 label">
                {" "}
                <button className="upgradeButton" onClick={upgradePlan}>
                  Upgrade Plan
                </button>
              </div>
            </div>
          </div>
          <hr className="solid"></hr>
          <div className="cardWrap">
            <CommonCard
              styelName={"cardTitle"}
              title={"Our Plans"}
              interview="Monthly interview included"
              aiReview="AI powered reviews"
              fraudDetection="Fraud detection"
              support="Support"
            />
            <CommonCard
              styelName={"plusCardTitle"}
              title={"Plus"}
              interview="300"
              aiReview="Unlimited"
              fraudDetection="Unlimited"
              support="Live Chat"
            />
            <CommonCard
              styelName={"premiumCardTitle"}
              title={"Premium"}
              interview="600"
              aiReview="Unlimited"
              fraudDetection="Unlimited"
              support="Dedicated Account Manager"
            />
          </div>
          <Modal open={showPopup} onClose={upgradePlan} center>
            <h4 className="modal-heading">
              Upgrade Your Plan with Personalized Assistance!
            </h4>
            <p style={{ textAlign: "center", marginTop: "20px" }}>
              We appreciate your thought about upgrading your plan. In order to
              ensure that your plan is the best fit for your needs and to move
              forward with upgrading it, please get in touch with our support
              staff. At every stage, our customer service agents are here to
              help.
              <br /> You can reach us at <b>
                support@talentou.atlassian.net
              </b>{" "}
              to discuss your plan upgrade options and receive personalized
              assistance.
            </p>
            <div className="btn-div">
              <button onClick={upgradePlan} className="model-yes-btn">
                Ok
              </button>
            </div>
          </Modal>
        </>
      )}
    </div>
  );
};

export default License;
