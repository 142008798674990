import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import edit from "../../../../src/assets/edit.svg";
import { API } from "../../../global";

import _ from "lodash";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const GroupSettings = ({ adGroupdata }) => {

  const navigate = useNavigate()

  const [editGroupEnable, setEditGroupEnable] = useState(true);
  const [azureData, setAzureData] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const data = _.cloneDeep(adGroupdata);
    setAzureData(data);

    // eslint-disable-next-line
  }, []);
  const editGroup = () => {
    setEditGroupEnable(!editGroupEnable);
    setAzureData(_.cloneDeep(adGroupdata));
  };

  const saveGroupData = () => {
    const obj = {
      azureData: azureData,
    };
    setLoading(true);
    fetch(`${API}/admin/tenant/auth-setting/update-group`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setEditGroupEnable(!editGroupEnable);
          toast.success("Group Updated Successfully");
        }else{
         
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  
  const handleChageID = (e, index) => {
    azureData[index]["ad_group_id"] = e.target.value;
  };

  return (
    <div>
           <Tooltip
          id="group-setting-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
      <div className="groupMainDiv">
        <div className="row  ">
          <div className="col-sm-2">
            <p className="textLabel">Group</p>
          </div>
          
          <div className="col-sm-6 ">
            
            <div className="groupLabelDiv">
              {editGroupEnable ? (
                <>
                <button style={{border:"none",background:"none"}}  onClick={editGroup}>
                  <img
                    style={{
                      width: "16px",
                      height: "16px",
                      cursor: "pointer",
                    }}
                    src={edit}
                    alt=""
                    data-tooltip-id="group-setting-tooltip"
                              data-tooltip-content="Edit"
                              data-tooltip-place="top"
                  />
                </button>
                 
                </>
              ) : (
                <div className="d-flex gap-3">
                  <button className="auth-save-button" onClick={saveGroupData}>
                    {loading ? "Saving..." : "Save"}
                  </button>
                  <button className="auth-cancel-button" onClick={editGroup}>
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {azureData?.map((data, index) => (
        <div className="row grupDiv">
          <div className="col-sm-2">
            <p className="subTitleText">{data?.description}</p>
          </div>
          <div className="col-sm-6">
            <div className="row display-flex just">
              <div>
                <Tooltip
                  id="gruopId-tooltip"
                  variant="light"
                  className="candidate-tooltip"
                  border="1px solid #7C7B7B"
                />
                <input
                  type="text"
                  style={{
                    height: "40px",
                    width: "400px",
                    borderRadius: "4px",
                  }}
                  id={data?.ad_group_id}
                  disabled={editGroupEnable}
                  onChange={(e) => handleChageID(e, index)}
                  data-tooltip-id="gruopId-tooltip"
                  data-tooltip-content={data?.ad_group_id}
                  data-tooltip-place="top"
                  defaultValue={data.ad_group_id}
                  placeholder="Group ID"
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default GroupSettings;
