import React, { useEffect, useState } from "react";
import TextInput from "../Jobslist/TextInput";
import pluswhite from "../../../src/assets/add-white.svg";
import Autosuggest from "react-autosuggest";
import DurationButton from "../Reusable/DurationButton/DurationButton";
import { API } from "../../global";
import toast from "react-hot-toast";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import { useNavigate, useParams } from "react-router";


const CreateCandidate = (props) => {
  const {
    modalTitle,
    handleAddJobModal,
    getCandidateDetails,
    singleCanDetails,
  } = props;

  const navigate = useNavigate();
  const [suggestions, setSuggestions] = useState([]);
  const [value, setTextValue] = useState("");
  const [skillData, setSkillData] = useState([]);
  const [skillText, setSkillText] = useState("");
  const [experience, setExperience] = useState(0);
  const [candidateId, setCandidateId] = useState("");
  const [contact, setContact] = useState("");
  const [emailId, setEmailId] = useState("");
  const [locationIn, setLocationIn] = useState("");
  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");

  const [skillError, setSkillError] = useState("");
  const [loading, setLoading] = useState(false);
  const [contactError, setContactError] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  let { jobid } = useParams();
 
  useEffect(() => {
    candidateSuggestion();

    if (modalTitle === "Edit") {
      viewCandidate();
      setCandidateId(singleCanDetails);
      setInitialLoading(true);
    }

    //eslint-disable-next-line
  }, []);

  const viewCandidate = () => {
    fetch(`${API}/admin/candidate/view?candidate_id=${singleCanDetails}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setInitialLoading(false);
        if (res.success === true) {

          setTextValue(res?.result?.name);
          setEmailId(res?.result?.email);

          setSkillData(
            res?.result?.skills === " " ||
              res?.result?.skills === null ||
              res?.result?.skills === undefined
              ? []
              : res?.result?.skills
          );
          setExperience(
            res?.result?.experience ? Number(res?.result?.experience) : 0
          );
          setContact(res?.result?.contact_number);
          setLocationIn(res?.result?.location);
        } else {
          setInitialLoading(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${res.error.msg}`);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);
      });
  };

  const candidateSuggestion = (name) => {
    fetch(`${API}/admin/candidate/list-all?name=${name}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success === true) {
          const formattedCandidates = res.result.map((candidate) => {
            return {
              _id: candidate?._id,
              name: candidate?.name,
              skills:
                candidate?.skills === " " ||
                candidate?.skills === null ||
                candidate?.skills === undefined
                  ? []
                  : candidate?.skills,
              contact_number: candidate?.contact_number,
              location: candidate?.location,
              email: candidate?.email,
              experience: candidate?.experience,
            };
          });
          setSuggestions(formattedCandidates);
          return formattedCandidates;
        }
      });
  };

  const onChange = (event, { newValue }) => {
    setTextValue(newValue);
  };

  const inputProps = {
    placeholder: "Enter candidate name",
    value,
    onChange: onChange,
  };

  const removeKeyWord = (index) => {
    // Assuming answerData is a state variable
    setSkillData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleCancel = () => {
    handleAddJobModal();
    setSkillText("");
    setSkillData([]);
    setExperience(0);
    setContact("");
    setLocationIn("");
    setEmailId("");
    setNameError("");
    setEmailError("");
  };

  const handleInputChangeKeyWord = (e) => {
    setSkillText(e.target.value);
  };

  const getSuggestions = async (value) => {
    const candidateData = await candidateSuggestion(value);
    if (value) {
      setNameError("");
    }
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    return inputLength === 0
      ? []
      : candidateData?.filter(
          (lang) => lang.name.toLowerCase().slice(0, inputLength) === inputValue
        );
  };

  const getSuggestionValue = (suggestion) => suggestion.name;

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Use your imagination to render suggestions.

  const renderSuggestionsContainer = ({ containerProps, children }) => {
    return (
      <div
        {...containerProps}
        style={{
          position: "absolute",
          zIndex: 9999, // Adjust the z-index as needed
          backgroundColor: "white",
          border: "1px solid #ccc",
          maxHeight: "200px", // Limit the height to make it scrollable
          overflowY: "auto",
          width: "100%",
          padding: "12px",
        }}
      >
        {children}
      </div>
    );
  };

  const renderSuggestion = (suggestion) => {
    return (
      <ul
        style={{
          listStyleType: "none",
          padding: "0px",
          backgroundColor: "white",
        }}
      >
        <li
          style={{
            borderBottom: "1px solid #ccc",
            cursor: "pointer",
            // backgroundColor: "#e3c9f2",
            listStyleType: "none", // Remove bullet point
            padding: "0px",
          }}
        >
          <p>
            {suggestion.name} : {suggestion.email}
          </p>
        </li>
      </ul>
    );
  };
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };
  const onSuggestionSelected = (event, { suggestion }) => {
    setSkillData(suggestion.skills);
    setContact(suggestion.contact_number);
    setEmailId(suggestion.email);
    setExperience(suggestion.experience);
    setLocationIn(suggestion.location);
    setCandidateId(suggestion._id);
  };

  const handleKeyDown = (e) => {
    if (skillText.trim() !== "") {
      if (e.key === "Enter") {
        setSkillData([...skillData, skillText.trim()]);
        setSkillText("");
        setSkillError("");
      }
    }
  };

  const countChange = (count) => {
    setExperience(count);
  };

  const handleInput = (e) => {
    if (e.target.id === "email") {
      setEmailId(e.target.value);
      setEmailError("");
    }

    if (e.target.id === "contactNumber") {
      setContact(e.target.value);
      setContactError("");
    }

    if (e.target.id === "location") {
      setLocationIn(e.target.value);
    }
  };

  const validation = () => {
    let error = true;
    if (!value) {
      setNameError("Name is a required field");
      error = false;
    }

    if (!emailId) {
      setEmailError("Email is a required field");
      error = false;
    }

    if (skillData.length === 0) {
      setSkillError("Skill is a required field.");
      error = false;
    }

    return error;
  };

  const onSubmit = () => {
    let  currentJobId = localStorage.getItem('currentJob');
    const candidateObj = {
      name: value,
      skills: skillData.join(","),
      experience: experience,
      contact_number: contact,
      location: locationIn,
      email: emailId,
      job_order_id: currentJobId ? currentJobId : jobid,
    };

    if (candidateId) {
      candidateObj.candidate_id = candidateId;
    }

    if (candidateObj.contact_number !== "") {
      // Check if the phone number contains exactly 10 digits
      if (
        !/^(?:\+?91)?[6789]\d{9}$/.test(contact) && // Indian format: +91XXXXXXXXXX or 7/8/9XXXXXXXXX
        !/^\(\d{3}\) \d{3}-\d{4}$/.test(contact) // US format: (XXX) XXX-XXXX
      ) {
        setLoading(false);
        setContactError("Please enter a valid phone number");
        return;
      }
    }

    setLoading(true);
    let path = "";
    let message = "";
    if (modalTitle === "Edit" || candidateId) {
      path = "update";
      candidateObj.candidate_id = candidateId;
      message = "updated";
      //   jobObj.company_name = companyName !== value ? value : "";
    } else {
      path = "create";
      message = "created";
    }
    // eslint-disable-next-line
    if (validation() == false) {
      setLoading(false);
    } else {
      saveData(path, message, candidateObj);
    }
  };

  const saveData = (path, message, candidateObj) => {
    fetch(`${API}/admin/candidate/${path}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(candidateObj),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success === true) {
          toast.success(`Candidate ${message}  successfully`);
          handleCancel();
          handleAddJobModal();
          getCandidateDetails(candidateObj.job_order_id);
        } else {
          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  return (
    <>
      {initialLoading ? (
        <>
          <div className="loader-div-createATS">
            <ProgressBarComponent />
          </div>
        </>
      ) : (
        <div>
          <h5>{modalTitle} Candidate</h5>

          <div className="form-group add-job-modal mt-2">
            <div className="w-100">
              <label for="Company" className="fw-bold">
                Candidate Name
              </label>
              <br />
              {/* <div className="d-flex gap-2"> */}
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                onSuggestionSelected={onSuggestionSelected}
                inputProps={inputProps}
                suggestionsContainerStyle={{
                  listStyleType: "none",
                  padding: 0,
                  margin: 0,
                }}
                renderSuggestionsContainer={renderSuggestionsContainer}
              />

              {/* </div> */}
              <div className="error-msg">{nameError}</div>
            </div>

            <div>
              <label for="Skills" className="fw-bold">
                Skills (Type the skill and press enter)
              </label>
              <div>
                <TextInput
                  handleKeyDown={handleKeyDown}
                  handleInputChangeKeyWord={handleInputChangeKeyWord}
                  removeKeyWord={removeKeyWord}
                  answerKeyword={skillText}
                  answerData={skillData}
                  id="answerKey"
                  placeHolder={"Type the required skills and press enter"}
                  type="talentou-ats"
                />
              </div>
              <div className="error-msg">{skillError}</div>
            </div>

            <div className="d-flex gap-2 flex-column">
              <label for="Contact" className="fw-bold">
                Contact
              </label>

              <div className="d-flex gap-2">
                <div className="w-100">
                  <div className="d-flex gap-2">
                    <div className="d-flex w-100 position-relative">
                      {/* <img
                    src={phone}
                    alt="phone"
                    className="position-absolute"
                    style={{ top: "12px", left: "5px" }}
                    width={12}
                  /> */}
                      <input
                        type="tel"
                        value={contact}
                        className="form-control w-100 input-add-job"
                        style={{ paddingInlineStart: " 10px" }}
                        id="contactNumber"
                        placeholder="Enter phone number"
                        pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                        required
                        onChange={(e) => handleInput(e)}
                      />
                    </div>
                  </div>

                  <div className="error-msg">{contactError}</div>
                </div>{" "}
                <div className="w-100">
                  <div className="d-flex gap-2">
                    <div className="d-flex w-100 position-relative">
                      {/* <img
                    src={mail}
                    alt=""
                    className="position-absolute"
                    style={{ top: "13px", left: "5px" }}
                  /> */}
                      <input
                        type="email"
                        value={emailId}
                        className="form-control w-100 input-add-job"
                        id="email"
                        style={{ paddingInlineStart: " 10px" }}
                        placeholder="Enter email id"
                        onChange={(e) => handleInput(e)}
                      />
                    </div>{" "}
                  </div>
                  <div className="error-msg">{emailError}</div>
                </div>
                <div className="w-100">
                  <div className="d-flex gap-2">
                    <div className="d-flex w-100 position-relative">
                      {/* <img
                    src={location}
                    alt="location"
                    className="position-absolute"
                    style={{ top: "12px", left: "5px" }}
                  /> */}
                      <input
                        type="text"
                        value={locationIn}
                        className="form-control w-100 input-add-job"
                        style={{ paddingInlineStart: " 10px" }}
                        id="location"
                        placeholder="Enter Location"
                        onChange={(e) => handleInput(e)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-100">
              <label for="Company" className="fw-bold">
                Experience
              </label>
              <br />
              <div className="d-flex gap-2">
                <DurationButton
                  countChange={countChange}
                  type="experience"
                  value={experience}
                />
              </div>
            </div>

            <div className="action-div-add-job">
              <button
                className="cancel-button-style"
                onClick={() => {
                  handleAddJobModal();
                  handleCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="btn text-white py-1 add-ats-candidate-button pt-1"
                type="submit"
                onClick={() => onSubmit()}
              >
                {modalTitle === "Edit" ? (
                  <span className="mt-1">{loading ? "Saving..." : "Save"}</span>
                ) : (
                  <>
                    {loading ? (
                      <span className="mt-1">Adding...</span>
                    ) : (
                      <>
                        <img src={pluswhite} alt="plus" width={12} />
                        <span className="ms-2 mt-1">Add Candidate</span>
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCandidate;
