import React, { useState, useEffect } from "react";
import "../Settings.css";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import crossIcon from "../../../../src/assets/closeIcon.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import edit from "../../../../src/assets/edit.svg";
import { Tooltip } from "react-tooltip";

import { API } from "../../../global";
import { useNavigate } from "react-router";
import { IoMdInformationCircleOutline } from "react-icons/io";

const UserManagementForm = (props) => {
  const navigate = useNavigate();

  const [userId, setUserId] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedRoles, setSelectedRole] = useState([]);
  const [rolesError, setRolesError] = useState("");
  const [enablePassword, setEnablePassword] = useState(false);

  const schema = yup.object({
    userName: yup.string().required("Name is a required field"),
    userEmail: yup
      .string()
      .email("Invalid Email")
      .required("Email is a required field"),
    password: enablePassword
      ? yup
          .string()
          .required("Password is a required field.")
          .min(8, "Password must be at least 8 characters.")
          .max(20, "Password must be at most 20 characters.")
          .matches(
            /[A-Z]/,
            "Password must contain at least one uppercase letter."
          )
          .matches(
            /[a-z]/,
            "Password must contain at least one lowercase letter."
          )
          .matches(/\d/, "Password must contain at least one numeric digit.")
          .matches(
            /[@$!%*?&]/,
            "Password must contain at least one special character."
          )
      : yup.string(),
  });

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (props?.selectedData?.length !== 0) {
      let user = props.selectedData;
      setValue("userName", user?.name);
      setValue("userEmail", user?.email);
      setUserId(user?._id);
      setSelectedRole(user?.type?.split(", "));
    }

    if (props.type === "add") {
      setEnablePassword(true);
    }

    // eslint-disable-next-line
  }, [props.selectedData]);

  const chooseRole = (type) => {
    if (selectedRoles && selectedRoles?.includes(type)) {
      let tempList = selectedRoles;
      tempList = tempList.filter((item) => item !== type);
      setSelectedRole(tempList);
    } else {
      setSelectedRole([...selectedRoles, type]);
    }
  };

  const updateData = (data) => {
    setLoading(true);
    let roleId = [];

    // eslint-disable-next-line
    props.roleData.map((data) => {
      // eslint-disable-next-line
      selectedRoles.map((item) => {
        if (item === data.description) {
          roleId.push(data?._id);
        }
      });
    });
    const obj = {
      role_ids: roleId,
      name: data.userName,
      email: data.userEmail,
    };
    if (props.type === "add") {
      obj.password = data.password;
    }
    if (props.type === "edit") {
      if (data.password) {
        obj.password = data.password;
      }
      obj.user_id = userId;
    }

    saveData(obj);
  };

  const getRandomChar = (charset) => {
    const array = new Uint32Array(1);
    window.crypto.getRandomValues(array);
    const randomIndex = array[0] % charset.length;
    return charset[randomIndex];
  };

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const randomArray = new Uint32Array(1);
      window.crypto.getRandomValues(randomArray);
      const j = randomArray[0] % (i + 1);
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const generatePassword = (e, length) => {
    e.preventDefault();

    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()-_+=";

    let password = [];

    // Ensure the password contains at least one of each required character type
    password.push(getRandomChar("ABCDEFGHIJKLMNOPQRSTUVWXYZ")); // Add a random uppercase letter
    password.push(getRandomChar("abcdefghijklmnopqrstuvwxyz")); // Add a random lowercase letter
    password.push(getRandomChar("0123456789")); // Add a random number
    password.push(getRandomChar("!@#$%^&*()-_+=")); // Add a random special character

    // Generate the rest of the password
    for (let i = 4; i < length; i++) {
      password.push(getRandomChar(charset));
    }

    // Shuffle the password characters
    shuffleArray(password);

    // Join the array into a string
    const passwordString = password.join("");

    setValue("password", passwordString);

    clearErrors("password");
  };

  const saveData = (obj) => {
    let endPoint = "";
    let msg = "";
    if (props.type === "add") {
      endPoint = "create";
      msg = "Created";
    } else {
      endPoint = "update";
      msg = "Updated";
    }
    fetch(`${API}/admin/tenant/user/${endPoint}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props.closeMOdal();
          props.resetData();
          toast.success(`User ${msg} Successfully`);
        } else {
          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const cancel = (e) => {
    e.preventDefault();
    props.closeMOdal();
  };

  const onSubmit = (data) => {
    if (selectedRoles.length !== 0) {
      updateData(data);
      setRolesError("");
    } else {
      setRolesError("Please select atleast one role");
    }
  };

  const enableEditPassword = (e) => {
    e.preventDefault();
    setEnablePassword(!enablePassword);
  };

  return (
    <div>
      <Tooltip
        id="user-management-form-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <Container fluid className="bb-primary">
        <Row>
          <Col>
            <p style={{ fontSize: "18px", fontWeight: "bold" }}>
              {props?.type === "edit" ? "Edit User" : "Add user"}
            </p>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="container">
              <div className="row">
                {" "}
                <div className="role-selectable-div p-0 m-0">
                  {props.roleData.map((data) => (
                    // <div
                    //   className={
                    //     selectedRoles?.includes(data?.description?.trim())
                    //       ? "col role-selected-div"
                    //       : "col role-select-div"
                    //   }
                    // >

                    <button
                      onClick={() => chooseRole(data.description)}
                      className={
                        selectedRoles?.includes(data?.description?.trim())
                          ? "col role-selected-div"
                          : "col role-select-div"
                      }
                    >
                      <p
                        className={
                          selectedRoles?.includes(data?.description?.trim())
                            ? "selected-role-text"
                            : "role-text"
                        }
                      >
                        {data.description}
                      </p>
                    </button>

                    // </div>
                  ))}
                </div>
                <p className="error-text mt-2">{rolesError}</p>
              </div>
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <div style={{ fontSize: "18px", fontWeight: "bold" }}>
              User Details
            </div>
          </Col>
        </Row>
        <form>
          <Row>
            <Col>
              <div className="input-div-edit mt-2">
                <label className="label-style">Name</label>
                <input
                  className="input-style"
                  placeholder="Enter Name"
                  type="text"
                  id="name"
                  {...register("userName")}
                />
                <p className="error-text">{errors.userName?.message}</p>
              </div>
              <div className="input-div-edit">
                <label className="label-style">Work Email</label>
                <input
                  className="input-style"
                  placeholder="Enter Email"
                  type="text"
                  {...register("userEmail")}
                />
                <p className="error-text">{errors.userEmail?.message}</p>
              </div>
              <div className="input-div-edit">
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <label className="label-style">
                    Password{" "}
                    <IoMdInformationCircleOutline
                      fontSize="17px"
                      data-tooltip-id="user-management-form-tooltip"
                      data-tooltip-content="Password must be at least eight characters long and include a number, an uppercase letter, and a special character."
                      data-tooltip-place="top"
                    />
                  </label>
                  {props.type === "edit" && (
                    <>
                      <button
                        style={{
                          marginRight: "6px",
                          border: "none",
                          background: "none",
                        }}
                        onClick={(e) => enableEditPassword(e)}
                      >
                        <img
                          style={{
                            width: "16px",
                            height: "16px",
                            cursor: "pointer",
                          }}
                          src={enablePassword ? crossIcon : edit}
                          alt=""
                          data-tooltip-id="user-management-form-tooltip"
                          data-tooltip-content={
                            enablePassword ? "Close" : "Edit"
                          }
                          data-tooltip-place="top"
                        />
                      </button>
                    </>
                  )}
                </div>
                {enablePassword && (
                  <>
                    <Row>
                      <Col className="d-flex">
                        <input
                          className="input-style"
                          style={{ width: "100%" }}
                          placeholder="Enter Password"
                          type="text"
                          autoComplete="new-password"
                          {...register("password")}
                        />
                        <button
                          className="gen-password"
                          onClick={(e) => generatePassword(e, 10)}
                        >
                          Generate Password
                        </button>
                      </Col>
                    </Row>
                    <p className="error-text">{errors.password?.message}</p>
                  </>
                )}
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <div>
                  <button className="cancel-button" onClick={(e) => cancel(e)}>
                    Cancel
                  </button>
                  <button
                    disabled={loading}
                    className="save-button"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {loading ? "Saving..." : "Save"}
                  </button>
                </div>
              </div>
            </Col>
          </Row>
        </form>
      </Container>
    </div>
  );
};

export default UserManagementForm;
