import React, { useEffect, useState } from "react";
import "./ViewRecordings.css";
import location from "../../assets/location.svg";
import date from "../../assets/D&T_1.svg";
import profile from "../../assets/profile.svg";
import job from "../../assets/job.svg";
import interviewReview from "../../assets/interviewReview.svg";

import moment from "moment";
import ShareRecording from "./ShareRecording";
import { Link } from "react-router-dom";

const CandidateDetails = ({ recordingDetails, type }) => {
  //newAPI
  const [candidateDetails, setCandidateDetails] = useState(null);
  const [jobDetails, setJobDetails] = useState(null);
  const [startDate, setStartDate] = useState("");

  useEffect(() => {
    getCandidateDetails();
    // eslint-disable-next-line
  }, []);

  const getCandidateDetails = () => {
    setCandidateDetails(recordingDetails?.candidate_id);
    setJobDetails(recordingDetails?.job_details);
    const startInterviewData = recordingDetails?.interview_start_date
      ? recordingDetails?.interview_start_date
      : recordingDetails?.updated_at;

    setStartDate(startInterviewData);
  };


  return (
    <div className="candidate-info-container">
      {type !== "view" && (
        <>
          <div
            className="py-2 px-2"
            style={{
              backgroundColor: "#E1FFE8",
              borderColor: "#AEECAE",
              borderRadius: "4px",
            }}
          >
            <div className="interview-review-status">
              <img src={interviewReview} alt="interviewReview" />
              <b>Interview Review:</b>
              {recordingDetails?.aligned === undefined
                ? "In Progress"
                : "Completed"}{" "}
            </div>
          </div>
        </>
      )}

      <div className="Candidate-info mt-2">
        <img src={profile} style={{ marginRight: 10 }} alt="profile" />
        <span className="candidate-details-label">Candidate Name:</span>{" "}
        {candidateDetails?.name}
        <br />
        <img src={job} style={{ marginRight: 10 }} alt="job" />
        <span className="candidate-details-label">Job Applied:</span>{" "}
        {type !== "view" ? (
          <Link to={`/interview/candidatelist/${jobDetails?._id}`}>
            {" "}
            {jobDetails?.job_role}
          </Link>
        ) : (
          jobDetails?.job_role
        )}
        <br />
        <img
          src={location}
          style={{ marginRight: 10, height: "16px", width: "14px" }}
          alt="job"
        />
        <span className="candidate-details-label">Location:</span>{" "}
        {candidateDetails?.location ? candidateDetails?.location : "--"}
        <br />
        <img src={date} style={{ marginRight: 7 }} alt="date_time" />
        <span className="candidate-details-label">Date & Time:</span>{" "}
        {moment(startDate).format("MMMM Do YYYY, h:mm a")}
        <br />
        {/* <Sharelink intid={intid} editDisable={editDisable} />
      <hr style={{ border: "1px solid #888888" }} /> */}
        {type !== "view" ? <ShareRecording /> : ""}
        <hr style={{ border: "1px solid #888888" }} />
      </div>
    </div>
  );
};

export default CandidateDetails;
