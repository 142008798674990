import React, { useEffect, useState } from "react";
import Nav from "../Reusable/Navbar/Nav";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import "./ViewRecordings.css";
import { Col, Row } from "react-bootstrap";
import CandidateDetails from "./CandidateDetails";
import { API, blobUrl } from "../../global";
import toast from "react-hot-toast";
import QuestionAnswerDetails from "./QuestionAnswerDetails";
import SelectVideoPage from "./SelectVideoPage";
import { UpdateAlign } from "./UpdateAligned";
import UpdateOverAllRating from "./UpdateOverAllRating";
import UpdateFeedBack from "./UpdateFeedBack";
import "../../components/Footer/Footer.css";
import VideoPreview from "./VideoPreview";
import * as echarts from "echarts";
import CandidateAuditGraph from "./CandidateAuditGraph";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router";

const ViewRecordingsPage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [editDisable, setEditDisable] = useState(true);
  //newAPI
  const [recordingDetails, setRecordingDetails] = useState(null);
  const [recordList, setRecordList] = useState([]);
  const [videoPlayBtn, setVideoPlayBtn] = useState(false);
  const [currentquestionId, setCurrentQuestionId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlay, setVideoPlay] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [candidateAttentionScore, setCandidateAttentionScore] = useState(0);
  const [aiCandidateRating, setAiCandidateRating] = useState(0);
  const [candidateAttentionTime, setCandidateAttentionTime] = useState("");
  const [candidateDistractedTime, setCandidateDistractedTime] = useState("");
  const [candidateScoreGraph, setCandidateScoreGraph] = useState(false);
  const [canAttentionChartData, setCanAttentionChartData] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [candidateAnswer, setCandidateAnswer] = useState("");
  const [videoEncoded, setVideoEncoded] = useState(false);
  // const [candidateAnswer,setCandidateAnswer] = useState("")

  useEffect(() => {
    const roleId = localStorage.getItem("current_role_key");
    if (roleId === "talentou_tech_lead") {
      setEditDisable(false);
    }
    getRecordingeDetails();
    // eslint-disable-next-line
  }, []);

  const getRecordingeDetails = () => {
    setLoading(true);
    fetch(
      `${API}/admin/interview/view?interview_id=${localStorage.getItem(
        "interview-id"
      )}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);
          setRecordingDetails(res.result);
          setTotalQuestions(res.result.interview_records.length);
          res.result.interview_records.length > 0
            ? setRecordList(res.result.interview_records)
            : setRecordList([]);
        } else {
          setLoading(false);
          toast.error(res.error.msg);
        }
      });
  };

  function renderItem(params, api) {
    var categoryIndex = api.value(0);
    var start = api.coord([api.value(1), categoryIndex]);
    var end = api.coord([api.value(2), categoryIndex]);
    //var height = api.size([0, 1])[1] * 0.6;
    var height = 40;
    //console.log(canAttentionChartRef);
    //let echartsInstance = canAttentionChartRef.current.getEchartsInstance();
    let rectShape = echarts.graphic.clipRectByRect(
      {
        x: start[0],
        y: start[1] - height / 2,
        width: end[0] - start[0],
        height: height,
      },
      {
        x: params.coordSys.x,
        y: params.coordSys.y,
        width: params.coordSys.width,
        height: params.coordSys.height,
      }
    );
    return (
      rectShape && {
        type: "rect",
        transition: ["shape"],
        shape: rectShape,
        style: api.style(),
      }
    );
  }

  function htmlToPlainText(html) {
    var tempElement = document.createElement("div");
    tempElement.innerHTML = html;
    return tempElement.textContent || tempElement.innerText || "";
  }

  const playVideo = (questionAnswer, i) => {
    setCurrentQuestionId(questionAnswer.question_id);
    setTranscript(questionAnswer.audio_text);

    const answerText = htmlToPlainText(questionAnswer.candidate_answer);

    setCandidateAnswer(answerText);
    setVideoPlay(true);
    setAiCandidateRating(questionAnswer?.ai_rating);

    let totalInterviewDurationSeconds =
      questionAnswer?.total_interview_duration_seconds;
    let totalDistractionSeconds = 0;

    const record = recordList[i];

    const interviewID = recordingDetails?.interview_id;
    // const InterviewIDToLowercase=interviewID.toLowercase();
    // console.log(InterviewIDToLowercase,"recordingDetails.interview_id")

    // Connect to Azure Blob Storage
    let extention = "";
    if (questionAnswer?.video_encoded === true) {
      extention = "mp4";
    } else {
      extention = "webm";
    }

    setVideoEncoded(questionAnswer.video_encoded);
    if (questionAnswer.storage_version === "2") {
      setVideoUrl(
        `${blobUrl}/${interviewID}/${interviewID}/${questionAnswer.question_id}.${extention}?${recordingDetails.sas_token}`
      );
    } else {
      setVideoUrl(
        `${blobUrl}/${recordingDetails.tenant_id}/${interviewID}/${questionAnswer.question_id}.${extention}?${recordingDetails.sas_token}`
      );
    }

    //have to change in according to the question_id
    if (record?.candidate_action_log.length > 0) {
      totalDistractionSeconds = record?.candidate_action_log.reduce(
        (totalDistraction, obj) =>
          parseInt(obj.total_duration_second, 10) + totalDistraction,
        0
      );

      let chartOption;
      let chartData = [];
      let startTime = 0;
      let categories = ["browser", "face"];
      const categoryColor = { face: "#e0bc78", browser: "#bd6d6c" };
      record?.candidate_action_log.forEach(function (log, index) {
        // Generate mock data
        chartData.push({
          name: log.action,
          value: [log.type, log.start_second, log.end_second],
          itemStyle: {
            normal: {
              color: categoryColor[log.type],
            },
          },
        });
      });

      chartOption = {
        tooltip: {
          formatter: function (params) {
            //eturn params.marker + params.name + ': ' + params.value[3] + ' ms';
            return params.marker + params.name;
          },
        },
        /*
        dataZoom: [
          {
            type: 'slider',
            filterMode: 'weakFilter',
            showDataShadow: false,
            top: 400,
            labelFormatter: ''
          },
          {
            type: 'inside',
            filterMode: 'weakFilter'
          }
        ],*/
        grid: {
          height: 200,
          show: true,
        },
        xAxis: {
          min: 0,
          max: totalInterviewDurationSeconds
            ? Math.round(totalInterviewDurationSeconds)
            : 180,
          scale: true,
          axisLabel: {
            formatter: function (val) {
              return Math.max(0, val - startTime) + " Sec";
            },
          },
        },
        yAxis: {
          data: categories,
          show: false,
        },
        series: [
          {
            type: "custom",
            renderItem: renderItem,
            itemStyle: {
              opacity: 1,
            },
            encode: {
              x: [1, 2],
              y: 0,
            },
            data: chartData,
          },
        ],
      };
      //console.log(chartOption);
      setCanAttentionChartData(chartOption);
    } else {
      setCanAttentionChartData([]);
    }
    if (
      record?.candidate_action_log.length > 0 ||
      totalInterviewDurationSeconds > 10
    ) {
      setCandidateDistractedTime(Math.abs(Math.round(totalDistractionSeconds)));
      setCandidateAttentionTime(
        Math.abs(
          Math.round(totalInterviewDurationSeconds - totalDistractionSeconds)
        )
      );
      setCandidateAttentionScore(
        Math.abs(
          Math.round(
            ((totalInterviewDurationSeconds - totalDistractionSeconds) /
              totalInterviewDurationSeconds) *
              100
          )
        )
      );
    } else {
      setCandidateDistractedTime("-");
      setCandidateAttentionTime("-");
      setCandidateAttentionScore("-");
      //toast.error('Not enough data ')
    }
  };
  console.log(recordingDetails, "rec");

  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  return (
    <div className="view-recordings-container">
      <Nav handleTour={handleTour} />

      <>
        <div className="recording-container">
          <div className="sidemenu-section">
            <SideMenu menuIndex={2} />
          </div>
          {loading ? (
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          ) : (
            <div className="w-100">
              <div className="container-fluid">
                <Row
                  className="view-recording-main-container"
                  style={{ height: "91%", marginBottom: "10px" }}
                >
                  <Col lg={4} className="candidate-info rounded">
                    <div className=" rounded px-4 py-2">
                      <CandidateDetails recordingDetails={recordingDetails} />
                      <div className="ques-list">
                        {recordingDetails?.interview_records?.map(
                          (questionAnswer, i) => (
                            <QuestionAnswerDetails
                              questionAnswer={questionAnswer}
                              i={i}
                              editDisable={editDisable}
                              setVideoPlayBtn={setVideoPlayBtn}
                              playVideo={playVideo}
                              currentquestionId={currentquestionId}
                              setCurrentQuestionId={setCurrentQuestionId}
                              totalQuestions={totalQuestions}
                            />
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} className="recording-main-container">
                    <Row>
                      <Col className="video-preview-container mt-2">
                        {videoPlayBtn ? (
                          <>
                            <CandidateAuditGraph
                              candidateScoreGraph={candidateScoreGraph}
                              setCandidateScoreGraph={setCandidateScoreGraph}
                              candidateAttentionTime={candidateAttentionTime}
                              candidateDistractedTime={candidateDistractedTime}
                              candidateAttentionScore={candidateAttentionScore}
                              canAttentionChartData={canAttentionChartData}
                              setVideoPlay={setVideoPlay}
                            />
                            <VideoPreview
                              candidateAttentionScore={candidateAttentionScore}
                              setCandidateScoreGraph={setCandidateScoreGraph}
                              transcript={transcript}
                              answer={candidateAnswer}
                              videoUrl={videoUrl}
                              videoPlay={videoPlay}
                              setVideoPlay={setVideoPlay}
                              videoEncoded={videoEncoded}
                              aiCandidateRating={aiCandidateRating}
                            />
                          </>
                        ) : (
                          <div className="recording-info">
                            <SelectVideoPage />
                          </div>
                        )}
                      </Col>
                    </Row>

                    <Row>
                      <Col md={3}>
                        <div className="feedback-info mt-2">
                          <UpdateOverAllRating
                            recordingDetails={recordingDetails}
                            editDisable={editDisable}
                            setLoading={setLoading}
                          />
                        </div>
                      </Col>
                      <Col md={1} className="p-0">
                        <div className="feedback-info mt-2">
                          <UpdateAlign
                            recordingDetails={recordingDetails}
                            editDisable={editDisable}
                            setLoading={setLoading}
                          />
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="feedback-info mt-2">
                          <UpdateFeedBack
                            recordingDetails={recordingDetails}
                            editDisable={editDisable}
                            setLoading={setLoading}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Row className="footer-container p-0 m-0"  style={{ height: "5%"}}>
                <footer className="text-center footer-copyrights rounded-2">
                  <p style={{ padding: "10px 0 10px", margin: "0" }}>
                    <small>
                      Copyrights © 2023 Talentou. All Rights Reserved. v2.0.1
                    </small>
                  </p>
                </footer>
              </Row> */}
              </div>
              <Footer hideChatbot={true} />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default ViewRecordingsPage;
