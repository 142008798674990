import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API } from "../../global";
import { useNavigate } from "react-router";

const UpdateOverAllRating = ({ recordingDetails, editDisable, setLoading }) => {
 
  const navigate = useNavigate()

  const [overallRating, setOverallRating] = useState("");
  const [interviewScheduleId, setInterviewScheduleId] = useState(null);

  useEffect(() => {
    getOverallRating();
    // eslint-disable-next-line
  }, []);

  const getOverallRating = () => {
    setOverallRating(recordingDetails?.overall_rating);
    const getScheduleID = recordingDetails?._id;

    setInterviewScheduleId(getScheduleID);
  };

  //update overall rating
  const updateOverallRating = (overall, interviewScheduleId) => {
    setLoading(true);
    if (editDisable) {
      toast.error("Sorry, you are not allowed to rate this interview");
    } else {
      setOverallRating(overall);
      const updatedOverallRating = {
        interview_schedule_id: interviewScheduleId,
        overall_rating: overall,
      };
      fetch(`${API}/admin/interview/review/update-overall-rating`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(updatedOverallRating),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            toast.success("Overall Rating Updated");
            setOverallRating(overall);
          } else {
           
            if (val.error.code ===  421) {
              navigate("/error");
            }else{
              toast.error(val.error.msg);
            }
          }
        });
    }
    setLoading(false);
  };

  return (
    <div className="p-2 d-flex flex-column gap-3 justify-content-between">
      <div className="d-flex justify-content-between px-2">
        <div className="overall-rating-label">Overall Rating</div>
        <div className="rating-num">{overallRating}</div>
      </div>
      <div className="pt-1">
        {[...Array(5)].map((star, index) => {
          index += 1;
          return (
            <button
              type="button"
              // disabled={editDisable}
              className="star-button"
              key={index}
              disabled={editDisable}
              onClick={() => updateOverallRating(index, interviewScheduleId)}
            >
              <span
                className={
                  index <= overallRating
                    ? "fa fa-star checked star-btn"
                    : "fa fa-star star-btn"
                }
              />
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default UpdateOverAllRating;
