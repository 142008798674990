import React from "react";

import { API } from "../../../global";
import { toast } from "react-hot-toast";

import warning from "../../../assets/warning.svg";
import { useEffect } from "react";
import { useState } from "react";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router";

const DeleteUser = (props) => {

  const navigate = useNavigate()

  const [userId, setUserId] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.selectedData) {
      setUserId(props?.selectedData?._id);
    }
  }, [props]);

  const deleteData = () => {
    setLoading(true);

    fetch(`${API}/admin/tenant/user/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ user_id: userId }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if(response.success){
          toast.success("User Deleted Successfully");
          props.closeMOdal();
          props.resetData();
        }else{
         
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
        

      
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const cancel = () => {
    props.closeMOdal();
  };

  return (
    <>
      {loading ? (
        <ProgressBarComponent />
      ) : (
        <Row>
          <Col>
            <div>
              <img
                style={{ height: "141px", width: "141px", margin: "25px" }}
                src={warning}
                alt=""
              />
            </div>
          </Col>
          <Col>
            <div style={{ margin: "20px", textAlign: "center" }}>
              <p style={{ fontSize: "35px" }}>Are You Sure?</p>
              <p style={{ fontSize: "14px" }}>
                {" "}
                Do you really want to delete this record? This process cannot be
                undone
              </p>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  gap: "10px",
                }}
              >
                <button onClick={deleteData} className="delete-button">
                  Delete
                </button>
                <button onClick={cancel} className="cancel-button-delete">
                  Cancel
                </button>
              </div>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default DeleteUser;
