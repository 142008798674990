import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { API } from "../../global";
import save from "../../assets/save-icon2.png";
import { useNavigate } from "react-router";

const UpdateFeedBack = ({ recordingDetails, editDisable, setLoading }) => {

  const navigate = useNavigate()
  const [feedback, setFeedback] = useState("");
  const [interviewScheduleId, setInterviewScheduleId] = useState(null);

  useEffect(() => {
    getFeedBack();
    // eslint-disable-next-line
  }, []);

  const getFeedBack = () => {
    setFeedback(recordingDetails?.feedback);
    const getScheduleID = recordingDetails?._id;

    setInterviewScheduleId(getScheduleID);
  };

  const updateFeedback = (interviewScheduleId) => {
    setLoading(true)
    if (editDisable) {
      toast.error("Sorry you are not allowed to rate this interview");
    } else {
      const updatedFeedback = {
        interview_schedule_id: interviewScheduleId,
        feedback: feedback,
      };
      fetch(`${API}/admin/interview/review/update-feedback`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(updatedFeedback),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            toast.success("Feedback Updated");

          } else {

            
            if (val.error.code ===  421) {
              navigate("/error");
            }else{
              toast.error(val.error.msg);
            }
          }
        });
    }
    setLoading(false)
  };
  return (
    <div className="feedback-inner-container">
      <div className="feedback-form">
        <form>
          <textarea
            placeholder="Type your feedback here"
            value={feedback}
            onChange={(e) => setFeedback(e.target.value)}
            style={{ background: "#FFFFFF", color: "#838383", width: "100%" }}
            disabled={editDisable}
          />
        </form>
      </div>
      <div className="py-1">
        <button
          type="button"
          className="btn submit-btn"
          onClick={() => updateFeedback(interviewScheduleId)}
        >
          <p style={{ margin: 0 }}>
            <img src={save} alt="save-icon" className="save-icon" />
          </p>
          <p style={{ color: "#fff", margin: 0, fontSize: 14 }}>Save</p>
        </button>
      </div>
    </div>
  );
};

export default UpdateFeedBack;
