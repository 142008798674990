import React from "react";
import uploadImage from "../../../assets/upload-file.svg";
import download from "../../../assets/file-download.svg";
import "../Settings.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const ATSCard = ({
  title,
  icon,
  description,
  inputRef,
  handleUploadClick,
  handleList,
  loading,
  fileRef,
  fileName,
}) => {
  return (
    <div>
      <div
        className="card"
        style={{ width: "100%", margin: "5px", padding: "10px" }}
      >
        <div className="card-body">
          <div className="candidate-image-div">
            <img alt="" style={{ margin: "6px" }} src={icon} />
          </div>
          <div style={{ marginTop: "5px" }}>
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    fontSize: "20px",
                    color: "#333333",
                    fontWeight: "bold",
                    margin: "0px",
                  }}
                >
                  {title}
                </p>
              </Col>

              <Col xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <button className="upload-button-style">
                  {/* eslint-disable-next-line */}
                  <a
                    href="#"
                    onClick={handleUploadClick}
                    className="template-href"
                  >
                    {loading ? (
                      "Processing..."
                    ) : (
                      <>
                        <img
                          src={uploadImage}
                          style={{ width: "20px", height: "20px" }}
                          alt=""
                        />
                        <span>Upload Spreadsheet</span>
                      </>
                    )}
                  </a>
                </button>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <p
                  style={{
                    fontSize: "12px",
                    color: "A1A1A1",
                    fontWeight: "normal",
                    margin: "0px",
                  }}
                >
                  {description}
                </p>
              </Col>
              <Col xs={6} style={{ display: "flex", justifyContent: "end" }}>
                <input
                  type="file"
                  ref={inputRef}
                  style={{ display: "none" }}
                  onChange={handleList}
                />
                <button className="download-button">
                  <a
                    href={fileRef}
                    download={fileName}
                    target="_blank"
                    rel="noreferrer"
                    className="template-href"
                  >
                    <img
                      src={download}
                      style={{ width: "20px", height: "20px" }}
                      alt=""
                    />
                    <span>Download Template</span>
                  </a>
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ATSCard;
