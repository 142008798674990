import React, { useEffect, useState } from "react";
import Nav from "../Reusable/Navbar/Nav";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import "./ViewRecordings.css";
import { Col, Row } from "react-bootstrap";
import CandidateDetails from "./CandidateDetails";
import { API, blobUrl } from "../../global";
import toast from "react-hot-toast";
import QuestionAnswerDetails from "./QuestionAnswerDetails";
import "../../components/Footer/Footer.css";
import VideoPreview from "./VideoPreview";
import Footer from "../Footer/Footer";
import { useNavigate, useParams } from "react-router";
import video from "../../assets/Video-Icon.png";

const SharedInterview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [editDisable, setEditDisable] = useState(true);
  //newAPI
  const [recordingDetails, setRecordingDetails] = useState(null);
  const [recordList, setRecordList] = useState([]);
  const [videoPlayBtn, setVideoPlayBtn] = useState(false);
  const [currentquestionId, setCurrentQuestionId] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [videoPlay, setVideoPlay] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [videoEncoded, setVideoEncoded] = useState(false);

  const [transcript, setTranscript] = useState("");
  const [candidateAnswer, setCandidateAnswer] = useState("");
  // const [candidateAnswer,setCandidateAnswer] = useState("")

  useEffect(() => {
    const roleId = localStorage.getItem("current_role_key");
    if (roleId === "talentou_tech_lead") {
      setEditDisable(false);
    }
    getRecordingeDetails();
    // eslint-disable-next-line
  }, []);

  const getRecordingeDetails = () => {
    setLoading(true);

    fetch(`${API}/admin/interview/share/view-recordings?interview_id=${id}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("record_token")}`,
      },
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          setLoading(false);

          setRecordingDetails(res.result);
          setTotalQuestions(res.result?.interview_records.length);
          res.result?.interview_records.length > 0
            ? setRecordList(res.result?.interview_records)
            : setRecordList([]);
        } else {
          setLoading(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
        }
      });
  };

  const playVideo = (questionAnswer, i) => {
    setCurrentQuestionId(questionAnswer.question_id);
    setTranscript(questionAnswer.audio_text);
    setCandidateAnswer(questionAnswer.candidate_answer);
    setVideoPlay(true);
    let totalInterviewDurationSeconds =
      questionAnswer?.total_interview_duration_seconds;

    const record = recordList[i];

    const interviewID = recordingDetails?.interview_id;
    // const InterviewIDToLowercase=interviewID.toLowercase();
    // console.log(InterviewIDToLowercase,"recordingDetails.interview_id")

    // Connect to Azure Blob Storage
    let extention = "";
    if (questionAnswer?.video_encoded === true) {
      extention = "mp4";
    } else {
      extention = "webm";
    }

    setVideoEncoded(questionAnswer.video_encoded);
    if (questionAnswer.storage_version === "2") {
      setVideoUrl(
        `${blobUrl}/${interviewID}/${interviewID}/${questionAnswer.question_id}.${extention}?${recordingDetails.sas_token}`
      );
    } else {
      setVideoUrl(
        `${blobUrl}/${recordingDetails.tenant_id}/${interviewID}/${questionAnswer.question_id}.${extention}?${recordingDetails.sas_token}`
      );
    }

    //have to change in according to the question_id
    if (record?.candidate_action_log.length > 0) {
      let chartData = [];
      const categoryColor = { face: "#e0bc78", browser: "#bd6d6c" };
      record?.candidate_action_log.forEach(function (log, index) {
        // Generate mock data
        chartData.push({
          name: log.action,
          value: [log.type, log.start_second, log.end_second],
          itemStyle: {
            normal: {
              color: categoryColor[log.type],
            },
          },
        });
      });

      //console.log(chartOption);
    }
    if (
      record?.candidate_action_log.length > 0 ||
      totalInterviewDurationSeconds > 10
    ) {
    }
  };

  return (
    <div className="view-recordings-container">
      <Nav type={"view"} />

      <>
        <div className="recording-container">
          {loading ? (
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          ) : (
            <div className="w-100">
              <div className="container-fluid">
                <Row style={{ height: "100%", marginBottom: "10px" }}>
                  <Col lg={4} className="candidate-info rounded">
                    <div className=" rounded px-4 py-2">
                      <CandidateDetails
                        recordingDetails={recordingDetails}
                        type={"view"}
                      />
                      <div className="ques-list">
                        {recordingDetails?.interview_records?.map(
                          (questionAnswer, i) => (
                            <QuestionAnswerDetails
                              questionAnswer={questionAnswer}
                              i={i}
                              editDisable={editDisable}
                              setVideoPlayBtn={setVideoPlayBtn}
                              playVideo={playVideo}
                              currentquestionId={currentquestionId}
                              setCurrentQuestionId={setCurrentQuestionId}
                              totalQuestions={totalQuestions}
                              type="view"
                            />
                          )
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col lg={8} className="recording-main-container">
                    <Row>
                      <Col style={{ height: "90vh", marginTop: "10px" }}>
                        {videoPlayBtn ? (
                          <>
                            <VideoPreview
                              transcript={transcript}
                              answer={candidateAnswer}
                              videoUrl={videoUrl}
                              videoPlay={videoPlay}
                              setVideoPlay={setVideoPlay}
                              type="view"
                              videoEncoded={videoEncoded}
                            />
                          </>
                        ) : (
                          <div className="recording-info">
                            <div className="py-3 px-4 h-75 d-flex ">
                              <div className="video-preview">
                                <img
                                  src={video}
                                  className="video-icon"
                                  alt="Video-Icon"
                                  style={{ width: "260px", height: "220px" }}
                                />
                                <div
                                  style={{
                                    color: "#600097",
                                    fontSize: 20,
                                    fontWeight: 600,
                                    cursor: "default",
                                  }}
                                >
                                  Select a video to view it here
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {/* <Row className="footer-container p-0 m-0"  style={{ height: "5%"}}>
                <footer className="text-center footer-copyrights rounded-2">
                  <p style={{ padding: "10px 0 10px", margin: "0" }}>
                    <small>
                      Copyrights © 2023 Talentou. All Rights Reserved. v2.0.1
                    </small>
                  </p>
                </footer>
              </Row> */}
              </div>
              <Footer hideChatbot={true} />
            </div>
          )}
        </div>
      </>
    </div>
  );
};

export default SharedInterview;
