import React, { useEffect, useMemo, useState } from "react";
import "./QuestionList.css";
import edit from "../../../src/assets/edit.svg";
import deleteImg from "../../../src/assets/delete.svg";
import plus from "../../../src/assets/plus.svg";
import DataTable from "react-data-table-component";
import { toast } from "react-hot-toast";
import { API } from "../../global";
import { useNavigate } from "react-router";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import SearchInput from "../Reusable/SearchInput";
import LayoutMain from "../Layout/LayoutMain";

export default function QuestionList() {
  const navigate = useNavigate();

  const [questionlist, setQuestionList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [DeleteId, setDeleteId] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [filterText, setFilterText] = useState("");
  const [filterData, setFilterData] = useState("");

  const rowsPerPage = 13;

  useEffect(() => {
    getQuestions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filterData === "") {
      getQuestions();
      setFilterText("");
    }

    //eslint-disable-next-line
  }, [filterData]);

  const deleteQuestionnaire = async (id) => {
    fetch(`${API}/admin/questionnaire/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ questionnaire_id: id }),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          toast.success("Deleted Successfully");
          handleCloseModal();
          getQuestions();
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      })
      .catch((err) => console.error("API Failed:", err));
  };

  const getQuestions = () => {
    setLoading(true);
    fetch(`${API}/admin/questionnaire/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          setQuestionList(val?.result?.questionnaires);
          setLoading(false);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
          setLoading(false);
          // navigate('/login');
        }
      });
  };

  const editQuestion = (id) => {
    navigate(`/question?mode=edit&&id=${id}`);
  };

  const deleteQuestionnaireDelete = (id) => {
    handleOpenModal(id);
  };
  // const getVerification = (id, type) => {
  //   fetch(`${API}/admin/question/questionnaire/verify/${id}`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((val) => {
  //       if (val.success === true) {
  //         if (type === "delete") {
  //           handleOpenModal(id);
  //         } else {
  //           localStorage.setItem("ques-id", id);
  //           navigate(`/editquestion/${id}`);
  //         }
  //       } else {
  //         toast.error("Interview Inprogress");
  //       }
  //     });
  // };

  questionlist.forEach((ques, i) => {
    ques.serial = i + 1;
  });

  const handleOpenModal = (del) => {
    setShowModal(true);
    setDeleteId(del);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const columnName = (row) => {
    return (
      <>
        <Tooltip
          id="column-tooltip"
          border="1px solid #7C7B7B"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="column-tooltip"
          data-tooltip-content={`${row}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row}
        </div>
      </>
    );
  };

  const actionTable = (act) => {
    return (
      <>
        <Tooltip
          id="my-tooltip"
          variant="light"
          border="1px solid #7C7B7B"
          className="candidate-tooltip"
        />
        <div className="d-flex gap-2">
          <button
            style={{ border: "none", background: "none" }}
            onClick={(e) => editQuestion(act._id)}
          >
            <img
              src={edit}
              alt="edit icon"
              style={{ cursor: "pointer" }}
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>

          <button
            style={{ border: "none", background: "none" }}
            onClick={() => deleteQuestionnaireDelete(act._id)}
          >
            <img
              src={deleteImg}
              style={{ cursor: "pointer" }}
              alt="edit icon"
              data-tooltip-id="my-tooltip"
              data-tooltip-content="Delete"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      name: "Job Order",
      sortable: true,
      selector: (row, i) => row?.job_order_id?.job_role,
      // width: '100px',
      cell: (row) => columnName(row?.job_order_id?.job_role),
      center: true,
    },
    {
      name: "Questionnaire Name",
      sortable: true,
      selector: (val) => val.name,
      cell: (val) => columnName(val.name),
      center: true,
    },
    {
      name: "No of Questions",
      sortable: true,
      selector: (val) => val.total_questions,
      center: true,
    },
    {
      name: "Total Duration",
      sortable: true,
      selector: (val) => `${val.total_duration / 60} min`,
      center: true,
    },
    {
      name: "Actions",
      cell: (act) => actionTable(act),
      center: true,
    },
  ];

  const filteredItems = questionlist.filter(
    (item) =>
      JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
      -1
  );

  const handleSearch = () => {
    setFilterText(filterData);
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <SearchInput
        onFilter={(e) => setFilterData(e.target.value)}
        filterText={filterData}
        onSearch={handleSearch}
        enableOnClick={true}
      />
    );

    //eslint-disable-next-line
  }, [filterData]);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
  };

  return (
    <div>
      <LayoutMain index={3}>
        <div className="container-fluid text-center d-flex  m-0 p-0">
          {/*Question List  */}
          {loading ? (
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          ) : (
            <div className="queslist-details-section">
              <div
                className="ques-table"
                style={{
                  background: "#ffffff",
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "10px",
                }}
              >
                {/*Queston list Header */}
                <div className="d-flex justify-content-between align-items-end ">
                  <div>
                    <div className="d-none">Questionnaires List</div>
                  </div>
                  <div className="list-heading-label">Questionnaires List</div>

                  <div className="d-flex justify-content-end gap-2">
                    <div>{subHeaderComponent}</div>
                    <button
                      className="btn text-white py-1 add-ques-btn"
                      onClick={() => {
                        localStorage.removeItem("Questionnaire_ID");
                        navigate("/question?mode=add");
                      }}
                    >
                      <img
                        src={plus}
                        className="mr-1"
                        alt="plus"
                        style={{ width: "23px", color: "#fff" }}
                      />
                      Add Questionnaire
                    </button>
                  </div>
                </div>

                {/*Question Table */}
                <div className="table-wrap">
                  {questionlist.length < 9 ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      customStyles={customStyles}
                    />
                  ) : (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      customStyles={customStyles}
                      pagination
                      paginationPerPage={rowsPerPage}
                      paginationTotalRows={questionlist.length}
                      paginationDefaultPage={currentPage}
                      onChangePage={(page) => setCurrentPage(page)}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </LayoutMain>

      <Modal show={showModal} onHide={handleCloseModal} className="nav-model">
        <Modal.Header className="d-flex justify-content-center">
          <Modal.Title>Are You Sure ?</Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          Are you sure you want to delete this item?
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" onClick={() => handleCloseModal()}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => deleteQuestionnaire(DeleteId)}
          >
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
