import React from "react";
import closeIcon from "../../assets/closeIcon.svg";
import fav from "../../assets/favIcon.svg";
import ReactECharts from "echarts-for-react";
// import * as echarts from "echarts";

const CandidateAuditGraph = ({
  type,
  candidateScoreGraph,
  setCandidateScoreGraph,
  candidateAttentionScore,
  candidateDistractedTime,
  candidateAttentionTime,
  canAttentionChartData,
  setVideoPlay,
}) => {
  const handleCloseAudit = () => {
    setCandidateScoreGraph(false);
    setVideoPlay(true);
  };
  return (
    <div
      className={`${candidateScoreGraph ? "z-2" : "d-none"} ${
        type !== "view" ? "audit-log-container" : "audit-view-log-container"
      } `}
      style={{ position: "absolute" }}
    >
      <div className="d-flex justify-content-end me-4 mt-3 back-nav">
        <button
          style={{ border: "none", background: "none" }}
          onClick={() => handleCloseAudit()}
        >
          <img src={closeIcon} alt="close" />
        </button>
      </div>

      <div className="audit-main-conatiner">
        <div className="audit-log">
          <div className="audit-log-header">
            <div className="score-heart">
              <img src={fav} alt="fav" width={90} height={90} />
              <div className="heartP audit-log-header">
                {candidateAttentionScore}
              </div>
            </div>
            <div className="text-center score_text text-black attention_score">
              Candidate Attention Score
            </div>
          </div>
          <div className="audit-log-header">
            <div className="audit-text">{candidateAttentionTime}</div>
            <small className="fw-bold text-black-50">Sec</small>
            <div className="score_text text-black">Candidate Attention</div>
          </div>
          <div className="audit-log-header">
            <div className="audit-text">{candidateDistractedTime}</div>
            <small className="fw-bold text-black-50">Sec</small>
            <div className="text-black score_text">Candidate Distraction</div>
          </div>
        </div>
        <div>
          {!Array.isArray(canAttentionChartData) ? (
            <div className=" chart-legend-container">
              <div className="legend-container">
                <div className="legend-inner-container">
                  <button className="legend-box-3">Distracted</button>
                  <button className="legend-box-1">Switched Tab</button>
                </div>
              </div>
              <div className="chart-container">
                <ReactECharts
                  notMerge={true}
                  option={canAttentionChartData}
                  className="echarts-timeline"
                />
              </div>
            </div>
          ) : (
            <div
              className="align-items-center justify-content-center d-flex"
              style={{ marginTop: "0px" }}
            >
              <span className="align-middle">
                {candidateAttentionScore && candidateAttentionScore === 100
                  ? "Candidate attended this question without distraction"
                  : "Sorry, candidate attended interview for less than 10 sec, no enough data to arrive candidate attention score."}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CandidateAuditGraph;
