import React, { useRef, useState } from "react";
import Layout from "../Layout/Layout";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import validator from "email-validator";
import { useNavigate } from "react-router";
// import { getTheme } from "../../Styles/ThemeChange";
import { API } from "../../global";
import toast from "react-hot-toast";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const loginRef = useRef();

  const [loading,setLoading]= useState(false)
  //  getTheme()
  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is a required field")
        .test(
          "email-valid",
          "Please, use standard email format. (for e.g., username@example.com)",
          (value) => validator.validate(value)
        ),
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger button click event
      loginRef.current.click();
    }
  };

  const onSubmit = (data) => {
    setLoading(true);
   
    const obj={
      email : data.email
    }

    fetch(`${API}/admin/auth/forget-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          navigate('/success?type=forgotPassword')
        } else {
        
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
         
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  }


  const cancel =()=>{
    navigate('/SignIn')
  }


  return (
    <div>
      <Layout>
        <div className="logo-div">
          <div className="login-body">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div className="login-card-email">
                    <div
                      className="panel-heading"
                      style={{ textAlign: "center" }}
                    >
                      <p className="signin-label mt-4">Forgot Password</p>
                      <p className="forgot-password-sub">
                        Enter your email and we'll send you a link to reset your
                        password.
                      </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="basic-form-div">
                        <label className="label-style mb-1">E-Mail ID</label>
                        <input
                          className="form-control-login"
                          placeholder="Enter your E-Mail ID"
                          {...register("email")}
                          onKeyDown={handleKeyPress}
                        />
                        {errors?.email?.message ? (
                          <div
                            style={{
                              color: "red",
                              marginTop: "5px",
                              fontSize: "12px",
                            }}
                          >
                            {errors.email.message}
                          </div>
                        ) : null}

                        <div className="forgot-password-button-div">
                          <button
                            type="submit"
                            ref={loginRef}
                            className="forgot-submit-btn"

                          >
                            {loading ? 'Loading..' : 'Submit'}
                          </button>
                          <button onClick={cancel} className="forgot-cancel-btn">
                            Cancel
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ForgotPassword;
