import React, { useRef, useEffect } from "react";

import "../Settings.css";
import "../../../components/Reusable/SideMenu/SideMenu.css";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { SketchPicker } from "react-color";
// import "react-color-palette/css";
import image from "../../../assets/imgaeAlt.svg";
import uploadIcon from "../../../assets/uploadIcon.svg";

// import ignitho from "../../../assets/Ignitho - White Logo - Transparent BG.png";
import { useState } from "react";

import { API } from "../../../global";
import { toast } from "react-hot-toast";
// import { useNavigate } from "react-router";

import { getThemeColor } from "../../Functions/commonFunctions";
import { lightenColor } from "../../../Styles/colorGenerater";

import ThemePreview from "./ThemePreview";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import { useNavigate } from "react-router";

const ThemeSettings = (props) => {
  const navigate = useNavigate();

  const [primaryColor, setPrimaryColor] = useState("");
  const [shadeOne, setShadeOne] = useState("");
  const [shadeTwo, setShadeTwo] = useState("");
  const [shadeThree, setShadeThree] = useState("");
  const [shadeFour, setShadeFour] = useState("");
  const [openPrimaryColor, setOpenPrimaryColor] = useState(false);
  const [primaryBtnColor, setPrimaryButtonClr] = useState("");
  const [openSecondaryBtn, setOpenSecondaryBtn] = useState(false);
  const [secondaryBtnColor, setSecondaryBtnColor] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [themeId, setThemeId] = useState("");
  const [logoError, setLogoError] = useState(false);
  const [imageKey, setImageKey] = useState("");

  useEffect(() => {
    fetchData();
    createDefaultData();

    // eslint-disable-next-line
  }, []);

  const createDefaultData = () => {
    setPrimaryColor(getThemeColor()?.primaryColor);
    setShadeOne(getThemeColor()?.primaryShade1);
    setShadeTwo(getThemeColor()?.primaryShade2);
    setShadeThree(getThemeColor()?.primaryShade3);
    setShadeFour(getThemeColor()?.primaryShade4);
    setPrimaryButtonClr(getThemeColor()?.primaryBtn);
    setSecondaryBtnColor(getThemeColor().secondaryBtn);
  };

  const fetchData = () => {
    fetch(`${API}/admin/tenant/theme-details/get`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setInitialLoading(false);
        if (response.success) {
          setThemeId(response?.result?.themeSettings[0]?._id);
          const uniqueKey = new Date().getTime();

          if (response?.result?.themeSettings[0]?.logo_url) {
            const imageUrl = `${response?.result?.themeSettings[0]?.logo_url}?${uniqueKey}`;
            setImageKey(imageUrl);
          }
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);
      });
  };

  const tableStyle = {
    borderRadius: "10px",
    width: "600px",
    textAlign: "center",
    outline: "auto",
    outlineColor: shadeTwo,
    backgroundColor: shadeFour,
  };

  const handleChangeComplete = (color) => {
    setPrimaryColor(color.hex);
    setShadeOne(lightenColor(color.hex, 25));
    setShadeTwo(lightenColor(color.hex, 38));
    setShadeThree(lightenColor(color.hex, 45));
    setShadeFour(lightenColor(color.hex, 56));
  };

  const handleChangeColor = (color) => {
    setPrimaryButtonClr(color.hex);
    setOpenPrimaryColor(false);
  };

  const handleChangeColorSec = (color) => {
    setSecondaryBtnColor(color.hex);
    setOpenSecondaryBtn(false);
  };

  const changeColor = (type) => {
    if (type === "primary") {
      setOpenPrimaryColor(true);
    } else {
      setOpenSecondaryBtn(true);
    }
  };

  const handleClose = () => {
    setOpenPrimaryColor(false);
  };

  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    // Trigger the click event on the file input
    fileInputRef.current.click();
  };

  const handleFileChange = (e) => {
    // Handle the selected file
    const file = e.target.files[0];
    const fileSize = file.size / 1024; // convert bytes to KB

    if (fileSize <= 60) {
      if (file && file.type.startsWith("image/")) {
        setLogoError(false);
        const reader = new FileReader();
        reader.onload = (event) => {
          setImageKey(event.target.result);
        };
        reader.readAsDataURL(file);
      }
    } else {
      setLogoError(true);
    }
  };

  const saveData = () => {
    setLoading(true);
    const obj = {
      btn_primary: primaryBtnColor,
      btn_secondary: secondaryBtnColor,
      theme_primary: primaryColor,
      theme_color1: shadeOne,
      theme_color2: shadeTwo,
      theme_color3: shadeThree,
      theme_color4: shadeFour,
      logo: imageKey,
      theme_id: themeId,
    };

    save(obj);
  };

  const save = (obj) => {
    fetch(`${API}/admin/tenant/theme-setting/update`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          toast.success("Updated Successfully");
          // handleLogout()
          fetchData();
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const handleKeyDownPrimary = (e) => {
    if (e.key === "Enter") {
      changeColor("primary");
    }
  };

  const handleKeyDownSecondary = (e) => {
    if (e.key === "Enter") {
      changeColor("secondary");
    }
  };

  const revertChanges = () => {
    createDefaultData();
    fetchData();
  };

  return (
    <div>
      {initialLoading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <Container fluid className="theme-main-div">
          <Row>
            <Col sm={6}>
              <Row>
                <div style={{ margin: "10px" }}>
                  <p className="textLabel">Logo</p>
                </div>
                <Col sm={8}>
                  <div style={{ margin: "10px" }}>
                    {imageKey ? (
                      <div>
                        <img
                          className="imageAlt"
                          src={imageKey}
                          alt=""
                          style={{
                            width: "400px",
                            height: "200px",
                            margin: "0px",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                    ) : (
                      <div className="uploadLogoDiv">
                        <div>
                          <img className="imageAlt" src={image} alt="" />
                        </div>
                      </div>
                    )}
                  </div>
                  {logoError && (
                    <p
                      style={{
                        color: "red",
                        fontSize: "14px",
                        marginLeft: "10px",
                      }}
                    >
                      Logo size cannot exceed 60Kb
                    </p>
                  )}
                </Col>
                <Col sm={4}>
                  <div>
                    <div className="button-div">
                      <button
                        onClick={handleButtonClick}
                        className="upload-button"
                      >
                        <span>
                          <img alt="" src={uploadIcon} />
                        </span>
                        Upload Logo
                      </button>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={handleFileChange}
                      />
                    </div>

                    <div className="text-div">
                      <p>
                        Must be JPEG, PNG, SVG, or GIF and cannot exceed 60Kb.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <div>
                    <div style={{ margin: "10px", display: "flex" }}>
                      <SketchPicker
                        onChangeComplete={handleChangeComplete}
                        color={primaryColor}
                      />
                      <div
                        style={{
                          display: "block",
                          marginLeft: "30px",
                          marginTop: "90px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <p
                            style={{
                              marginLeft: "30px",
                              fontSize: "14px",
                              fontWeight: "bold",
                            }}
                          >
                            Theme Primary Hex Code
                          </p>
                          <div className="hex-code-div ">
                            <p>{primaryColor}</p>
                          </div>
                        </div>
                        <div className="code-column-div">
                          <div
                            className="color-code-sub-div"
                            style={{ backgroundColor: primaryColor }}
                          >
                            <pre style={{ lineHeight: 10 }}> </pre>
                          </div>
                          <div
                            className="color-code-sub-div"
                            style={{ backgroundColor: shadeOne }}
                          >
                            <pre style={{ lineHeight: 10 }}> </pre>
                          </div>
                          <div
                            className="color-code-sub-div"
                            style={{ backgroundColor: shadeTwo }}
                          >
                            <pre style={{ lineHeight: 10 }}> </pre>
                          </div>
                          <div
                            className="color-code-sub-div"
                            style={{ backgroundColor: shadeThree }}
                          >
                            <pre style={{ lineHeight: 10 }}> </pre>
                          </div>

                          <div
                            className="color-code-sub-div"
                            style={{ backgroundColor: shadeFour }}
                          >
                            <pre style={{ lineHeight: 10 }}> </pre>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style={{ margin: "10px", marginTop: "40px" }}>
                      <Row>
                        <Col>
                          <p className="textLabel">Button Color</p>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={3}>
                          <p>Primary</p>
                          <div className="color-code-div">
                            <div
                              style={{
                                width: "24px",
                                height: "24px",
                                backgroundColor: primaryBtnColor,
                                borderRadius: "4px",
                                margin: "3px",
                              }}
                              onClick={() => changeColor("primary")}
                              onKeyDown={(e) => handleKeyDownPrimary(e)}
                            ></div>

                            <p
                              style={{
                                margin: "5px",
                                paddingRight: "11px",
                                color: "#A1A1A1",
                              }}
                            >
                              {primaryBtnColor}
                            </p>
                          </div>
                          {openPrimaryColor && (
                            <SketchPicker
                              color={primaryColor}
                              onClick={handleClose}
                              onChange={(color) => handleChangeColor(color)}
                            />
                          )}
                        </Col>
                        <Col xs={3}>
                          <p>Secondary</p>
                          <div className="color-code-div">
                            <div
                              style={{
                                width: "24px",
                                height: "24px",
                                backgroundColor: secondaryBtnColor,
                                borderRadius: "4px",
                                margin: "3px",
                              }}
                              onClick={() => changeColor("secondary")}
                              onKeyDown={(e) => handleKeyDownSecondary(e)}
                            ></div>
                            <p
                              style={{
                                margin: "5px",
                                paddingRight: "11px",
                                color: "#A1A1A1",
                              }}
                            >
                              {secondaryBtnColor}
                            </p>
                          </div>
                          {openSecondaryBtn && (
                            <SketchPicker
                              color={primaryColor}
                              onClick={handleClose}
                              onChange={(color) => handleChangeColorSec(color)}
                            />
                          )}
                        </Col>

                        <Col xs={6}>
                          <div style={{ display: "flex", marginTop: "40px" }}>
                            <button
                              onClick={() => revertChanges()}
                              className="custom-button-primary"
                            >
                              Revert
                            </button>
                            <button
                              onClick={saveData}
                              className="custom-button-secondary"
                            >
                              {loading ? "Saving...." : "Save"}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col sm={6} className="column-style">
              <ThemePreview
                primaryColor={primaryColor}
                primaryShade4={shadeFour}
                slectedLogo={imageKey}
                shadeOne={shadeOne}
                tableStyle={tableStyle}
                primaryBtnColor={primaryBtnColor}
                secondaryBtnColor={secondaryBtnColor}
                shadeTwo={shadeTwo}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};

export default ThemeSettings;
