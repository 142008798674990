import React, { useState } from "react";
import DataTable from "react-data-table-component";
import "./DataTable.css";
import plus from "../../../assets/plus.svg";

const DataTableComponent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
    head: {
      style: {
        fontSize: "14px",
      },
    },
    title: {
      // Override the style for the title
      backgroundColor: "transparent", // Your desired background-color
    },
  };

  const addUser = () => {
    props.addUser();
  };
  const rowsPerPage = 10;
  return (
    <div className="row">
      <div style={{ display: "flex", margin: "10px", padding: "0px" }}>
        <div className="col-6 p-0">
          <p className="custom-datatable-title">{props.title}</p>
        </div>
        {props.action && (
          <div className="col-6 tableTitleButtonDiv">
            <button
              className="btn text-white py-1 add-ques-btn"
              onClick={addUser}
            >
              <img
                src={plus}
                className="mr-1"
                alt="plus"
                style={{ width: "23px", color: "#fff" }}
              />
              {props.titlebtn}
            </button>
            {/* <button onClick={addUser} className="titleButton"></button> */}
          </div>
        )}
      </div>
      <DataTable
        columns={props.columns}
        data={props.data}
        customStyles={customStyles}
        defaultSortFieldId={2}
        pagination={props.data.length >= 10 ? true : false}
        paginationPerPage={rowsPerPage}
        paginationTotalRows={props.data.length}
        paginationDefaultPage={currentPage}
        onChangePage={(page) => setCurrentPage(page)}
      />
    </div>
  );
};

export default DataTableComponent;
