import React, { useState } from "react";
import "./CompanyList.css";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
import { API } from "../../global";
import toast from "react-hot-toast";
import ImportPopup from "../Reusable/ImportPopup/ImportPopup";

const ImportCompany = (props) => {
  const {
    fileInputRef,
    importfile,
    setImportFile,
    importCompanyFile,
    setImportCompanyFile,
    handleImportCompanyModal,
    handleClose,
    CompanyList,
  } = props;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [bgFile, setBgFile] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const importObj = {
    title: "Company",
    fileError: fileError,
    loading: loading,
    bgFile: bgFile,
    btnDisable: btnDisable,
    fileInputRef: fileInputRef,
    responseMsg: responseMsg,
    FileName: "CompanyList-Template",
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    // simulateFileUpload();
  };

  const checkDuplicateCompanyName = (companies) => {
    const companyNames = companies.map((company) => company.company_name);
    const duplicates = companyNames.filter(
      (name, index, self) => self.indexOf(name) !== index
    );

    if (duplicates.length > 0) {
      setBgFile(true);
      setResponseMsg("Duplicate company name found");
    } else {
      handlePostCompanylist(companies);
    }
  };

  const handleFileChange = (e) => {
    setResponseMsg("");
    setBgFile(false);
    setBtnDisable(false);

    const file = e.target.files[0];
    const fileName = file.name
      .split(".")[0]
      .replace(/[0-9()[\]{}]/g, "")
      .trim();

    const allowedTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]; // Allowed file types
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      setFileError("");
      setImportFile(true);
      setImportCompanyFile(file);
      if (fileName === "CompanyList-Template") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;

          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const json = XLSX.utils.sheet_to_json(worksheet);

          if (json.length !== 0) {
            const template = [
              "Company_Name",
              "Key_Technologies",
              "Website_URL",
              "Billing_Contact",
              "Address",
              "ContactNumber",
            ];

            const column = Object.keys(json[0]);

            const dataCount = column.length;

            const isMatch = column.every(
              (columnName, index) => columnName === template[index]
            );

            if (isMatch && dataCount === 6) {
              // Function to check if the required fields are missing and return the missing fields
              const getMissingFields = ({ Company_Name }) => {
                const missingFields = [];
                if (!Company_Name) missingFields.push("Company Name");

                return missingFields;
              };

              // Transform and validate the companies into an array of objects
              (() => {
                const allMissingFields = new Set(); // Use a Set to collect unique missing fields

                const transformedCompanies = json.reduce((acc, company) => {
                  const missingFields = getMissingFields(company);

                  if (missingFields.length === 0) {
                    const transformedCompany = {
                      company_name: company.Company_Name,
                      key_technologies: company.Key_Technologies,
                      website_url: company.Website_URL,
                      billing_contact: company.Billing_Contact,
                      address: company.Address,
                      contact_number: company.ContactNumber,
                    };

                    acc.push(transformedCompany); // Push the valid company object into the array
                  } else {
                    // Add missing fields to the Set
                    missingFields.forEach((field) =>
                      allMissingFields.add(field)
                    );
                  }

                  return acc;
                }, []);

                // Check if there are any valid transformed companies
                if (transformedCompanies.length > 0) {
                  checkDuplicateCompanyName(transformedCompanies)
                 
                }

                // Show a consolidated error message if there are missing fields
                if (allMissingFields.size > 0) {
                  const missingFieldsArray = Array.from(allMissingFields); // Convert Set to array
                  setResponseMsg(
                    setResponseMsg(
                      `The required ${
                        missingFieldsArray.length > 1 ? "fields" : "field"
                      } field "${missingFieldsArray.join(", ")} "${
                        missingFieldsArray.length > 1 ? "are" : "is"
                      }  missing; please complete it and re-upload.`
                    )
                  );
                }
              })();
            } else {
              toast.error("Template column name is mismatched");
              setImportFile(false);
            }
          } else {
            setResponseMsg("Uploaded file has no data");
          }
        };
        if (e.target.files && e.target.files.length > 0) {
          reader.readAsArrayBuffer(e.target.files[0]);
        } else {
          toast.error("Please upload the file");
        }
      } else {
        setImportFile(false);
        toast.error("Please ensure the file name matches the template.");
      }
    } else {
      setImportFile(false);

      setFileError("Please select a valid file (CSV or XLSX) under 10MB.");
    }
  };

  const handlePostCompanylist = (newCompany) => {
    setLoading(true);
    setBtnDisable(true);
    fetch(`${API}/admin/company/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },

      body: JSON.stringify({ companies: newCompany }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setBtnDisable(false);

        if (res.success) {
          // fileInputRef.current.value = "";
          // handleImportJobModal();

          setBgFile(true);
          setResponseMsg("File uploaded successfully");
        } else {
          // toast.error("File format is mismatched");
          setBgFile(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            setResponseMsg(res.error.msg);
          }
          //   setJobListLoading(false);
        }
      });
  };

  return (
    <>
      <ImportPopup
        handleFileChange={handleFileChange}
        handleBrowseClick={handleBrowseClick}
        handleImportModal={handleImportCompanyModal}
        handleClose={handleClose}
        importFile={importCompanyFile}
        importfile={importfile}
        importObj={importObj}
        ImportList={CompanyList}
      />
    </>
  );
};

export default ImportCompany;
