import React, { useState } from "react";
import minus from "../../../assets/minusDuration.svg";
import add from "../../../assets/addduration.svg";
// import upArrow from "../../../assets/upArrow.svg";
// import downArrow from "../../../assets/downArray.svg";
import deleteIcon from "../../../assets/delete.svg";
import editIcon from "../../../assets/edit-purple.svg";
import "./AddQuestion.css";
import SwitchComponent from "../../Reusable/SwitchComponent";
import { API } from "../../../global";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useNavigate } from "react-router";

// Function to decode HTML entities
const decodeHtmlEntities = (html) => {
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

const AddNewQuestionList = ({
  setShowModal,
  i,
  question,
  answer,
  textinput,
  duration,
  setModalTitle,
  id,
  getQuestionnaire,
}) => {

  const navigate = useNavigate()
  const [isHovered, setIsHovered] = useState(false);
  const [isOpenQn, setIsOpenQn] = useState(false);
  const [isOpenAns, setIsOpenAns] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteLoading,setDeleteLoading] = useState(false)

  const ansArray = answer.split(",");
  const quesDuration = duration / 60;

  const handleHover = () => {
    setIsHovered(true);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  // Conditionally decode HTML entities and truncate question if necessary
  const truncatedQuestion =
    !isOpenQn && question.length > 250
      ? `${question.substring(0, 250)}`
      : question;
  const decodedQuestion = decodeHtmlEntities(truncatedQuestion);

  const handleEditQuestion = () => {
    setShowModal(true);
    setModalTitle("Edit");

  const questionData={
    question:question,
    answer:JSON.stringify(ansArray),
    textinput:textinput,
    duration:quesDuration,
    questionId:id
  }
// Convert the object to a string using JSON.stringify()
const questionDataString = JSON.stringify(questionData);

// Save the string representation in localStorage
localStorage.setItem("questionDetails", questionDataString);
    // localStorage.setItem("answer", JSON.stringify(ansArray));
    // localStorage.setItem("textinput", textinput);
    // localStorage.setItem("duration", quesDuration);
    // localStorage.setItem("questionId", id);
  };

  const handleCloseModal = () => {
    setDeleteModal(false);
  };

  const handleDelete = () => {
    setDeleteLoading(true)
    fetch(`${API}/admin/questionnaire/question/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ question_id: id }),
    })
      .then((res) => res.json())
      .then((val) => {
        if(val.success){
          toast.success("Deleted Successfully");
          setDeleteLoading(false)
          getQuestionnaire();
          handleCloseModal();
        }else{
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg)
          }
        }
       
      })
      .catch((err) => setDeleteLoading(false)
    );
  };

  return (
    <div
      key={i}
      className="addques-list"
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
    >
      <div className="ques-number">
        <h5 className="table-label">Q{i + 1}</h5>
        <div className="arrow-container">
          {/* <button
            onClick={() => {
              setIsOpenAns(false);
              setIsOpenQn(false);
              setReadMoreQn(false);
              setReadMoreAns(false);
            }}
          >
            <img src={upArrow} alt="" className="px-1 py-2" />
          </button>
          <hr />
          <button
            onClick={() => {
              setIsOpenAns(true);
              setIsOpenQn(true);
              setReadMoreQn(true);
              setReadMoreAns(true);
            }}
          >
            <img src={downArrow} alt="" className="px-1 py-2" />
          </button> */}
        </div>
      </div>
      <div className="ques-ans mx-2">
        <div className="question-content px-3 pb-3">
          <span dangerouslySetInnerHTML={{ __html: decodedQuestion }} />{" "}
          {question.length > 250 && (
            <button onClick={() => setIsOpenQn(!isOpenQn)}>
              {isOpenQn ? "Read less" : "...Read more"}
            </button>
          )}
        </div>
        <div className="question-content px-3">
          {/* {isOpenAns && readMoreAns
            ? answer
            : `${answer.substring(0, 100)}...`}{" "}
          {!isOpenAns && (
            <button
              onClick={() => {
                setIsOpenAns(true);
                setReadMoreAns(true);
              }}
            >
              Read more
            </button>
          )} */}
          <ul className="key-input-ul">
            {!isOpenAns && ansArray.length > 7
              ? ansArray.slice(0, 5).map((item, index) => (
                  <li className="key-input-li" key={index}>
                    {item}
                  </li>
                ))
              : ansArray.map((item, index) => (
                  <li className="key-input-li" key={index}>
                    {item}
                  </li>
                ))}
            {ansArray.length > 7 && (
              <button onClick={() => setIsOpenAns(!isOpenAns)}>
                {isOpenAns ? "Read less" : "...Read more"}
              </button>
            )}
          </ul>
        </div>
      </div>
      <div className="ques-duration">
        <div className={isHovered ? "d-flex justify-content-end" : "invisible"}>
        <OverlayTrigger
            key="edit"
            placement="top"
            overlay={<Tooltip border="1px solid #7C7B7B" id={`tooltip-edit-${i}`}  className="light-tooltip">Edit</Tooltip>}
          >
            <button onClick={() => handleEditQuestion()}>
              <img src={editIcon} alt="" />
            </button>
          </OverlayTrigger>
    
          <OverlayTrigger
            key="delete"
            placement="top"
            overlay={
              <Tooltip border="1px solid #7C7B7B" id={`tooltip-delete-${i}`} className="light-tooltip">
                Delete
              </Tooltip>
            }
          >
            <button onClick={() => setDeleteModal(true)}>
              <img src={deleteIcon} alt="" />
            </button>
          </OverlayTrigger>
      
        </div>
        <div className="ques-details">
          <div className="d-flex align-items-center me-2">
            <div className="fs-6 fw-bold me-2">Text input</div>
            <div>
              <SwitchComponent
                disabled={true}
                className="toggle-btn"
                type="addQuestion"
                value={textinput}
                switchText={true}
              />
            </div>
          </div>
          <div className="time-duration-add-ques toggle-btn rounded">
            <div>
              <img src={minus} alt="" className="px-2" />
            </div>
            <div className="add-ques-time-count px-2 h-100 pt-1">
              {quesDuration} <span>min</span>{" "}
            </div>
            <div>
              <img src={add} alt="" className="px-2" />
            </div>
          </div>
        </div>
      </div>
      {/*Delete sample Modal */}
      <div>
        {deleteModal && (
          <Modal
            show={setDeleteModal}
            onHide={handleCloseModal}
            className="nav-model"
          >
            <Modal.Header className="d-flex justify-content-center">
              <Modal.Title>Are You Sure ?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="text-center">
              Are you sure you want to delete this question?
            </Modal.Body>
            <Modal.Footer className="d-flex justify-content-center">
              <Button variant="secondary" onClick={() => handleCloseModal()}>
                Close
              </Button>
              <Button variant="danger" onClick={() => handleDelete()}>
               {deleteLoading ? "Loading.." :"Delete" } 
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    </div>
  );
};

export default AddNewQuestionList;
