import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import plus from "../../../assets/plus.svg";
import "../Tenant/tenant.css";

import { useNavigate } from "react-router";

import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import { useLocation } from "react-router-dom";

import { API } from "../../../global";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import toast from "react-hot-toast";
import LayoutMain from "../../Layout/LayoutMain";

const AddPlans = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("Create");

  const onBackClick = () => {
    navigate("/plans");
  };
  // Access the search property of the location object, which contains the query parameters
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the 'id' parameter from the query string
  //   const id = searchParams.get("id");
  const mode = searchParams.get("mode");
  const id = searchParams.get("id");

  useEffect(() => {
    if (mode === "edit") {
      setTitle("Edit");
    }

    if (mode === "view") {
      setTitle("View");
    }

    if (mode !== "add") {
      fetchPlanDetails();
    }

    // eslint-disable-next-line
  }, []);

  const schema = yup
    .object({
      planName: yup.string().required("Plan Name is a required field"),
      planType: yup.string().required("Plan Name is a required field"),
      monthlyInterview: yup.string()
      .required("No. of Interviews is a required field"),
      description: yup.string().required("Description is a required field"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchPlanDetails = () => {
    fetch(`${API}/super-admin/plan/view?plan_id=${id}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setValue("planName", response.result.name);
          setValue("description", response.result.description);
          setValue("monthlyInterview", response.result.total_interviews);
          setValue("planType", response.result.plan_type);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {});
  };
  const onSubmit = (data) => {
    const obj = {
      name: data.planName,
      description: data.description,
      total_interviews: Number(data.monthlyInterview),
      plan_type: data.planType,
    };

    setLoading(true);

    let endPoint = "";
    let msg = "";
    if (mode === "edit") {
      endPoint = "update";
      msg = "updated";
      obj.plan_id = id;
    } else {
      endPoint = "create";
      msg = "created";
    }
    saveData(obj, endPoint, msg);
  };

  const saveData = (obj, endPoint, msg) => {
    fetch(`${API}/super-admin/plan/${endPoint}`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          toast.success(`Plan ${msg} successfully`);
          navigate(-1);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const handleCancel = () => {
    navigate("/plans");
  };

  return (
    <>
      <LayoutMain index={6}>
      <div className="w-100 h-100">
          <div
            className="table-class"
            style={{
              background: "#ffffff",
              margin: "20px",
              padding: "20px",
              borderRadius: "10px",
              height: "100vh",
            }}
          >
            <div>
              <Row>
                <Col>
                  <div className="d-flex justify-content-between w-100">
                    <button className="icon-div" onClick={onBackClick}>
                      <img
                        src={arrowImage}
                        style={{ cursor: "pointer" }}
                        alt="back"
                        className="backImage"
                      />
                    </button>
                    <div className="create-tenant-label">{title} Plan</div>
                    <div></div>
                  </div>
                </Col>
              </Row>
              <div
                style={{
                  border: "1px solid #F0D4FF",
                  borderRadius: "5px",
                  marginTop: "20px",
                  height: "85vh",
                }}
              >
                <form
                  onSubmit={handleSubmit(onSubmit)}
                  style={{ height: "90%" }}
                >
                  <div
                    style={{ margin: "30px", height: "100%" }}
                    className=" d-flex flex-column justify-content-between"
                  >
                    <Row>
                      <Col>
                        <div className="d-grid">
                          <label className="input-label">Plan Name</label>
                          <input
                            className="input-style"
                            placeholder="Enter plan name"
                            {...register("planName")}
                            disabled={mode === "view"}
                          />
                          <p className="error-text">
                            {errors.planName?.message}
                          </p>
                        </div>
                        <div className="d-grid">
                          <label className="input-label">
                          No. of Interviews
                          </label>
                          <input
                            {...register("monthlyInterview")}
                            className="input-style"
                            placeholder="Enter number of interviews"
                            disabled={mode === "view"}
                            type="number"
                          />
                          <p className="error-text">
                            {errors.monthlyInterview?.message}
                          </p>
                        </div>
                      </Col>
                      <Col>
                        <div className="d-grid">
                          <label className="input-label ">Type</label>
                          <select
                            {...register("planType")}
                            className="input-style select-style"
                            disabled={mode === "view"}
                          >
                            <option value="none" selected disabled hidden>
                              Select
                            </option>
                            <option value="daily">Daily</option>
                            <option value="monthly">Monthly</option>
                          </select>
                          {/* <input
                            {...register("fraudDetection")}
                            className="input-style"
                            placeholder="Enter minutes  of fraud detection"
                            disabled={mode === "view"}
                          /> */}
                          <p className="error-text">
                            {errors.fraudDetection?.message}
                          </p>
                        </div>
                        <div className="d-grid">
                          <label className="input-label">Description</label>
                          <input
                            {...register("description")}
                            className="input-style"
                            placeholder="Enter Description"
                            disabled={mode === "view"}
                          />
                          <p className="error-text">
                            {errors.description?.message}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    {mode !== "view" && (
                      <Row>
                        <Col>
                          <div className="action-div-plan">
                            {mode === "edit" ? (
                              <button className="save-button-style">
                                Save
                              </button>
                            ) : (
                              <button
                                className="btn text-white py-1 add-ques-button"
                                type="submit"
                              >
                                <img
                                  src={plus}
                                  className="mr-1"
                                  alt="plus"
                                  style={{ width: "23px", color: "#fff" }}
                                />
                                {loading ? "Creating..." : "Create Plan"}
                              </button>
                            )}

                            <button
                              className="cancel-button-style"
                              onClick={() => handleCancel()}
                            >
                              Cancel
                            </button>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        
        </div>
      </LayoutMain>
       
    </>
  );
};

export default AddPlans;
