


// document.documentElement.style.setProperty('--primary-color', '#600097');
// document.documentElement.style.setProperty('--primary-shade1', '#E0CCFF');
// document.documentElement.style.setProperty('--primary-shade2', '#f0d4ff');
// document.documentElement.style.setProperty('--primary-shade3', '#F8EBFF');
// document.documentElement.style.setProperty('--primary-btn', '#00C22D');
// document.documentElement.style.setProperty('--secondary-btn', '#00C22D');
// document.documentElement.style.setProperty('--primary-shade4', '#FDFAFF');
let themeData = localStorage.getItem('themeColors')
themeData = JSON.parse(themeData)
document.documentElement.style.setProperty('--primary-color', themeData?.primaryColor);
document.documentElement.style.setProperty('--primary-shade1',themeData?.primaryShade1);
document.documentElement.style.setProperty('--primary-shade2', themeData?.primaryShade2);
document.documentElement.style.setProperty('--primary-shade3', themeData?.primaryShade3);
document.documentElement.style.setProperty('--primary-shade4', themeData?.primaryShade4);
document.documentElement.style.setProperty('--primary-btn', themeData?.primaryBtn);
document.documentElement.style.setProperty('--secondary-btn', themeData?.secondaryBtn);


// export const get






