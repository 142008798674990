import React, { useState } from "react";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { API } from "../../global";
import { useNavigate } from "react-router";
import ImportPopup from "../Reusable/ImportPopup/ImportPopup";

const ImportJob = ({
  fileInputRef,
  importfile,
  setImportFile,
  importJobFile,
  setImportJobFile,
  handleImportJobModal,
  handleClose,
  JobOrderList,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [bgFile, setBgFile] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const importObj = {
    title: "Job",
    fileError: fileError,
    loading: loading,
    bgFile: bgFile,
    btnDisable: btnDisable,
    fileInputRef: fileInputRef,
    responseMsg: responseMsg,
    FileName: "Job-Order-Template",
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    // simulateFileUpload();
  };

  const handleFileChange = (e) => {
    setResponseMsg("");
    setBgFile(false);
    setBtnDisable(false);

    const file = e.target.files[0];
    const fileName = file.name
      .split(".")[0]
      .replace(/[0-9()[\]{}]/g, "")
      .trim();

    console.log(fileName, "fileName");
    const allowedTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]; // Allowed file types
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      setFileError("");
      setImportFile(true);
      setImportJobFile(file);

      if (fileName === "Job-Order-Template") {
        const reader = new FileReader();
        reader.onload = (event) => {
          console.log(event.target.result, "event");
          const data = event.target.result;

          const workbook = XLSX.read(data, { type: "array" });

          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];

          const json = XLSX.utils.sheet_to_json(worksheet, {
            defval: "",
          });

          if (json.length !== 0) {
            const template = [
              "Joborder_Id",
              "Company",
              "Jobrole",
              "Skills",
              "Location",
            ];

            const column = Object.keys(json[0]);

            const dataCount = column.length;

            const isMatch = column.every(
              (columnName, index) => columnName === template[index]
            );

            if (isMatch && dataCount === 5) {
              // Function to check if the required fields are missing and return the missing fields
              const getMissingFields = ({
                Joborder_Id,
                Company,
                Jobrole,
                Skills,
              }) => {
                const missingFields = [];
                if (!Joborder_Id) missingFields.push("Joborder_Id");
                if (!Company) missingFields.push("Company");
                if (!Jobrole) missingFields.push("Jobrole");
                if (!Skills) missingFields.push("Skills");

                return missingFields;
              };

              // Transform and validate the job orders into an array of objects
              (() => {
                const allMissingFields = new Set(); // Use a Set to collect unique missing fields

                const transformedJobOrders = json.reduce((acc, jobOrder) => {
                  const missingFields = getMissingFields(jobOrder);

                  if (missingFields.length === 0) {
                    const transformedJobOrder = {
                      ats_joborder_id: jobOrder.Joborder_Id,
                      company_name: jobOrder.Company,
                      job_role: jobOrder.Jobrole,
                      skills: jobOrder.Skills,
                      location: jobOrder.Location,
                    };

                    acc.push(transformedJobOrder); // Push the valid job order object into the array
                  } else {
                    // Add missing fields to the Set
                    missingFields.forEach((field) =>
                      allMissingFields.add(field)
                    );
                  }

                  return acc;
                }, []);

                // Check if there are any valid transformed job orders
                if (transformedJobOrders.length > 0) {
                  // Handle the array of transformed job orders
                  handlePostJoblist(transformedJobOrders);
                }

                // Show a consolidated error message if there are missing fields
                if (allMissingFields.size > 0) {
                  const missingFieldsArray = Array.from(allMissingFields); // Convert Set to array
                  setResponseMsg(
                    `The required ${
                      missingFieldsArray.length > 1 ? "fields" : "field"
                    } field "${missingFieldsArray.join(", ")} "${
                      missingFieldsArray.length > 1 ? "are" : "is"
                    }  missing; please complete it and re-upload.`
                  );
                }
              })();
            } else {
              toast.error("Template column name is mismatched");
              setImportFile(false);
            }
          } else {
            setResponseMsg("Uploaded file has no data");
          }
        };
        if (e.target.files && e.target.files.length > 0) {
          reader.readAsArrayBuffer(e.target.files[0]);
        } else {
          toast.error("Please upload the file");
        }
      } else {
        setImportFile(false);
        toast.error("Please ensure the file name matches the template.");
      }
    } else {
      setImportFile(false);

      setFileError("Please select a valid file (CSV or XLSX) under 10MB.");
    }
  };

  const handlePostJoblist = (newjoborder) => {
    setLoading(true);
    setBtnDisable(true);
    fetch(`${API}/admin/job-order/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },

      body: JSON.stringify({ job_orders: newjoborder }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setBtnDisable(false);

        if (res.success) {
          // fileInputRef.current.value = "";
          // handleImportJobModal();

          setBgFile(true);
          setResponseMsg("File uploaded successfully");
        } else {
          // toast.error("File format is mismatched");
          setBgFile(false);

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            setResponseMsg(res.error.msg);
          }
          //   setJobListLoading(false);
        }
      });
  };

  return (
    <div>
      <ImportPopup
        handleFileChange={handleFileChange}
        handleBrowseClick={handleBrowseClick}
        handleImportModal={handleImportJobModal}
        handleClose={handleClose}
        importFile={importJobFile}
        importfile={importfile}
        importObj={importObj}
        ImportList={JobOrderList}
      />
    </div>
  );
};

export default ImportJob;
