import React, { useRef, useState, useEffect } from "react";
import "./BasicAuth.css";
import { loginRequest } from "../../authConfig";
import { useMsal, useAccount } from "@azure/msal-react";
import talentouLogo from "./../../assets/Talentou Logo-login.svg";
import microsoft from "../../assets/login-microsoft-login.svg";
import { TailSpin } from "react-loader-spinner";
import { API, captchaSiteKey } from "../../global";
import { useNavigate } from "react-router-dom";

import toast from "react-hot-toast";
import { InputGroup, Form, Button } from "react-bootstrap";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import ReCAPTCHA from "react-google-recaptcha";

const BasicAuth = ({ singleSignon, id }) => {
  const navigate = useNavigate();
  const loginRef = useRef();
  const recaptchaRef = useRef();

  const [email, setEmail] = useState("");
  const [emailErrorMsg, setEmailErrmsg] = useState("Email is required");
  const [password, setPassword] = useState("");
  const [emailError, setEmialError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [buttonName, setButtonName] = useState("Processing...");
  const [disableEmail, setDesableEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    setEmail(localStorage.getItem("emailData"));
    if (localStorage.getItem("emailData")) {
      setDesableEmail(true);
    }
  }, []);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger button click event
      loginRef.current.click();
    }
  };

  const handleChange = (e) => {
    if (e.target.id === "email") {
      setEmail(e.target.value);
      setEmialError(false);
    }

    if (e.target.id === "password") {
      setPassword(e.target.value);
      setPasswordError(false);
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/);
  };

  const validate = () => {
    let error = false;
    if (!email && !password) {
      error = true;
      setPasswordError(true);
      setEmialError(true);
    } else if (!email) {
      error = true;
      setPasswordError(false);
      setEmialError(true);
    } else if (!password) {
      error = true;
      setEmialError(false);
      setPasswordError(true);
    }

    if (email && !validateEmail(email)) {
      setEmialError(true);
      error = true;
      setEmailErrmsg("Email Id is not valid");
    }

    return error;
  };

  const handleLogin = async () => {
    if (!validate()) {
      setLoading(true);
      setPasswordError(false);
      setEmialError(false);
      // const token = await recaptchaRef.current.executeAsync();
      fetch(`${API}/admin/auth/login/email`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: email.trim().toLowerCase(),
          password: password,
          tenant_id: id,
          // token:token
        }),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            setDesableEmail(false);
            localStorage.setItem(
              "show_guided_tour",
              val.result.show_guided_tour
            );
            localStorage.setItem("auth_token", val.result.token);
            localStorage.setItem("user-name", val.result.name);
            localStorage.setItem(
              "show_guided_tour",
              val.result.show_guided_tour
            );
            localStorage.setItem(
              "user_role_keys",
              JSON.stringify(val.result.user_role_keys)
            );
            localStorage.setItem(
              "current_role_key",
              val.result.current_role_key
            );
            setLoading(false);

            if (localStorage.getItem("sharedLink")) {
              navigate(localStorage.getItem("sharedLink"));
            } else {
              if (val.result.current_role_key === "talentou_super_admin") {
                navigate("/superAdmin-dashboard");
              } else {
                navigate("/admin-dasboard");
              }
            }
          } else {
            setLoading(false);
            setDesableEmail(false);
            toast.error(val.error.msg);
          }
        });
    }
  };

  useEffect(() => {
    if (!account) {
      setLoadingButton(false);
      setButtonName("Sign in with Microsoft");
    }
  }, [account]);

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            activeDirectryLogin(response);
          }
        });
    }
  });

  const singleSignOn = async (instance) => {
    setLoadingButton(true);
    instance.loginRedirect(loginRequest);
  };

  // active-directry-login

  const activeDirectryLogin = (res) => {
    const accessTocken = res.accessToken;
    const obj = {
      name: res.account.name,
      ad_id: res.uniqueId,
      active_directory_group_id: res.idTokenClaims.groups,
    };
    fetch(`${API}/admin/auth/login/active-directory`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${accessTocken}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          toast.success(val.info.msg);

          localStorage.setItem("auth_token", val.result.token);
          localStorage.setItem("user-name", val.result.name);
          localStorage.setItem(
            "user_role_keys",
            JSON.stringify(val.result.user_role_keys)
          );
          // localStorage.setItem("");
          localStorage.setItem("current_role_key", val.result.current_role_key);

          if (localStorage.getItem("sharedLink")) {
            navigate(localStorage.getItem("sharedLink"));
          } else {
            if (val.result.current_role_key === "talentou_super_admin") {
              navigate("/superAdmin-dashboard");
            } else {
              navigate("/admin-dasboard");
            }
          }
        } else {
          toast.error(val.error.msg);
        }
      })
      .catch((error) => {
        toast.error("Error occurred please try again");
      });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const forgotPassword = () => {
    navigate("/forgot-password");
  };

  return (
    <div className="login-body">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <div
              className={
                singleSignOn ? "basic-auth-login-card" : "auth-login-card"
              }
            >
              <div className="panel-heading" style={{ textAlign: "center" }}>
                <img
                  src={talentouLogo}
                  className="login-logo"
                  alt="Ignitho Logo"
                />
                {!account && (
                  <p className="signin-label">Sign in to continue</p>
                )}
              </div>
              {account ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <TailSpin
                    visible={true}
                    height="80"
                    width="80"
                    color="#600097"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                  />
                </div>
              ) : (
                <>
                  <div className="basic-form-div">
                    <InputGroup className="mt-3">
                      <Form.Control
                        type="text"
                        placeholder="E-Mail ID"
                        value={email}
                        id="email"
                        onChange={handleChange}
                        onKeyDown={handleKeyPress}
                        className="form-control-login"
                        readOnly={disableEmail}
                      />
                    </InputGroup>
                    {emailError ? (
                      <div style={{ color: "red" }}>{emailErrorMsg}</div>
                    ) : null}

                    <InputGroup className="mt-3">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Password"
                        value={password}
                        id="password"
                        onChange={handleChange}
                        className="form-control-login"
                        onKeyDown={handleKeyPress}
                      />
                      <Button
                        variant="outline-secondary"
                        style={{ border: "1px solid #E9E9E9" }}
                        onClick={() => togglePasswordVisibility()}
                      >
                        {!showPassword ? <BsEyeSlash /> : <BsEye />}
                      </Button>
                    </InputGroup>
                    {passwordError ? (
                      <div style={{ color: "red" }}>Password is required</div>
                    ) : null}

                    <div className="forgot-div">
                      <button
                        style={{ background: "none", border: "none" }}
                        onClick={forgotPassword}
                      >
                        {" "}
                        <p className="forgot-psw-label">Forgot Password?</p>
                      </button>
                    </div>

                    <div className="button-container-login">
                      {loading ? (
                        <button ref={loginRef} className="login-btn">
                          Loading...
                        </button>
                      ) : (
                        <button
                          ref={loginRef}
                          onClick={handleLogin}
                          className="login-btn"
                        >
                          Sign in
                        </button>
                      )}
                    </div>
                  </div>
                  {singleSignon && (
                    <div className="single-signon-div">
                      <p>- OR -</p>
                      <div className="button-container-login">
                        {loadingButton ? (
                          <button className="single-signon-btn">
                            Loading...
                          </button>
                        ) : (
                          <button
                            onClick={() => singleSignOn(instance)}
                            disabled={loadingButton}
                            className="single-signon-btn"
                          >
                            <img
                              src={microsoft}
                              className="btn-logo"
                              alt="azure"
                            ></img>
                            {buttonName}
                          </button>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div>
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          // onChange={handleVerify}/
          size="invisible"
          ref={recaptchaRef}
        />
      </div>
    </div>
  );
};

export default BasicAuth;
