import React, { useEffect, useState } from "react";

import Button from "react-bootstrap/Button";
import "./Jobslist.css";
import TextInput from "./TextInput";
import { API } from "../../global";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const RequiredSkillsCard = ({
  handleCloseModal,
  jobrole,
  selectedJob,
  jobId,
  skillData,
  fetchJob,
}) => {
  const navigate = useNavigate();
  const [answerKeyword, setAnswerKeyWord] = useState("");
  const [requiredSkills, setRequiredSkill] = useState([]);
  const [goodtoHaveKeyword, setGoodtoHaveKeyword] = useState("");
  const [goodtoHaveSkillData, setGoodtoHaveSkillData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [requiredSkillError, setRequiredSkillError] = useState("");

  // Function to decode HTML entities
  const decodeHtmlEntities = (html) => {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  };

  useEffect(() => {
    if (skillData) {
      const reqSkills = skillData?.requiredSkill
        ? skillData?.requiredSkill?.split(",")
        : null;
      const optionalSkills = skillData?.optionalSkill
        ? skillData?.optionalSkill?.split(",")
        : null;

      const reqskills = decodeHtmlEntities(reqSkills).split(",");
      const opSkills = decodeHtmlEntities(optionalSkills).split(",");

      setRequiredSkill(reqskills ? reqskills : []);
      setGoodtoHaveSkillData(opSkills ? opSkills : []);
    }
  }, [skillData]);

  const handleKeydownGood = (e) => {
    if (goodtoHaveKeyword.trim() !== "") {
      if (e.key === "Enter") {
        setGoodtoHaveSkillData([...goodtoHaveSkillData, goodtoHaveKeyword]);

        setGoodtoHaveKeyword("");
      }
    }
  };

  const handleKeyDown = (e) => {
    if (answerKeyword.trim() !== "") {
      if (e.key === "Enter") {
        setRequiredSkill([...requiredSkills, answerKeyword]);
        setAnswerKeyWord("");
        setRequiredSkillError("");
      }
    }
  };

  const handleInputChangeKeyWordGood = (e) => {
    setGoodtoHaveKeyword(e.target.value);
  };

  const handleInputChangeKeyWord = (e) => {
    setAnswerKeyWord(e.target.value);
  };

  const removeKeyWordGood = (index) => {
    // Assuming answerData is a state variable
    setGoodtoHaveSkillData((prevData) =>
      prevData.filter((_, i) => i !== index)
    );
  };

  const removeKeyWord = (index) => {
    // Assuming answerData is a state variable
    setRequiredSkill((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleSaveModal = () => {
    if (requiredSkills.length !== 0) {
      setLoading(true);
      const obj = {
        job_order_id: jobId,
        required_skills:
          requiredSkills.length !== 0 ? requiredSkills.join(", ") : null,
        optional_skills:
          goodtoHaveSkillData.length !== 0
            ? goodtoHaveSkillData.join(", ")
            : null,
      };
      saveData(obj);
      setRequiredSkillError("");
    } else {
      setRequiredSkillError("Required skill should not be empty.");
    }
  };

  const saveData = (obj) => {
    fetch(`${API}/admin/job-order/update-skills`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          handleCloseModal();
          setLoading(false);
          toast.success("Skills saved Successfully");
          fetchJob();
        } else {
          setLoading(false);
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(val.error.msg);
          }
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
        setLoading(false);
      });
  };

  return (
    <div className="modal-edit-background">
      <div className="skill-card-title pb-3">{jobrole}</div>
      <div>
        <div>
          <div className="skills-required-title">
            {" "}
            Required skills<span style={{ color: "red" }}>*</span>
          </div>

          <TextInput
            handleKeyDown={handleKeyDown}
            handleInputChangeKeyWord={handleInputChangeKeyWord}
            removeKeyWord={removeKeyWord}
            answerKeyword={answerKeyword}
            answerData={requiredSkills}
            id="answerKey"
            row={5}
            placeHolder={"Type the required skills and press enter"}
          />
          <p style={{ color: "red", fontSize: "12px" }}>{requiredSkillError}</p>

          <div className="skills-required-title mt-3"> Good to have skills</div>

          <TextInput
            handleKeyDown={handleKeydownGood}
            handleInputChangeKeyWord={handleInputChangeKeyWordGood}
            removeKeyWord={removeKeyWordGood}
            answerKeyword={goodtoHaveKeyword}
            answerData={goodtoHaveSkillData}
            id="goodtohaveSkillswerKey"
            row={3}
            placeHolder={"Type the skills and press enter"}
          />
        </div>
        <div></div>
      </div>
      <div className="d-flex justify-content-end gap-2 mt-2">
        <Button variant="secondary" onClick={handleCloseModal}>
          Cancel
        </Button>
        <Button variant="success" onClick={handleSaveModal}>
          {loading ? "Saving.." : "Save"}
        </Button>
      </div>
    </div>
  );
};

export default RequiredSkillsCard;
