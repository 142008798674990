import React, { useEffect, useState } from "react";
import { API, commonUrl } from "../global";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import SignIn from "../components/AzureLogin/SignIn";
import BasicAuth from "./BasicAuth/basic-auth";
import { setThemeData } from "../Styles/ThemeChange";
import { useDispatch } from "react-redux";
import {setTenantData} from '../Redux/Actions/tenantDetailsActions';

const LandingPage = () => {

  const dispatch = useDispatch();

  const [basicAuth, setBasciAuth] = useState(false);
  const [singleSignon, setSingleSignOn] = useState(false);
  const [tenantLogin, setTenantLogin] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
   // Access the search property of the location object, which contains the query parameters
   const searchParams = new URLSearchParams(location.search);
   const view = searchParams.get("view");

   // Decode the object
   const decodedObj = JSON.parse(decodeURIComponent(view));


  useEffect(() => {
    if (window.location.origin === commonUrl) {
      postRequest()
    }else{
      getRequest()
    }

    // eslint-disable-next-line
  }, []);
  const postRequest = ()=>{
    let obj={}


    if(localStorage.getItem('emailData')){
      obj.email = localStorage.getItem('emailData')
    }

    if(decodedObj?.id){
      obj.tenant_id =decodedObj?.id
    }
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body:JSON.stringify(obj)
    })
      .then((res) => res.json())
      .then((response) => {
        console.log("response",response)
        if (response.success) {
          setAuthData(response)
          dispatch(setTenantData(response))
        } else {
          toast.error(response.error.msg);
        }
      })
      .catch(() => {});
  }

  const getRequest =()=>{
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "GET"
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setAuthData(response)
          dispatch(setTenantData(response))
        } else {
          toast.error(response.error.msg);
        }
      })
      .catch(() => {});
  }

  const setAuthData=(response)=>{
    
    const res = response.result.tenant_auth_settings;
    const atsSettings = response.result.tenant_ats_settings;
    localStorage.setItem("clientId",res.azure_ad_credentials.azure_application_id)
    localStorage.setItem('azureId',res.azure_ad_credentials.azure_tenant_id)
    localStorage.setItem('atsData',JSON.stringify(atsSettings))
    setBasciAuth(res.basic_auth_enabled);
    setTenantId(res.tenant_id);
    setSingleSignOn(res.azure_ad_auth_enabled);
    setThemeData(response?.result?.tenant_theme_settings)
    handleLoginAsTenant();
  }
  

  const handleLoginAsTenant = () => {
   

    if (!view) {
      setTenantLogin(false);
    } else {
      setTenantLogin(true);
      fetch(`${API}/super-admin/tenant/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${decodedObj?.auth_token}`,
        },
        body: JSON.stringify({ tenant_id: decodedObj?.id }),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            localStorage.setItem("show_guided_tour", val.result.show_guided_tour);
            localStorage.setItem("auth_token", val.result.token);
            localStorage.setItem("user-name", val.result.name);
            localStorage.setItem(
              "user_role_keys",
              JSON.stringify(val.result.user_role_keys)
            );
            localStorage.setItem(
              "current_role_key",
              val.result.current_role_key
            );
            navigate("/admin-dasboard");
          }else{
            toast.error(val.error.msg);
          }
        });
    }
  };

  return (
    <div>
      {!tenantLogin && (
        <>
          {basicAuth && <BasicAuth singleSignon={singleSignon} id={tenantId} />}
          {singleSignon && !basicAuth && <SignIn />}
        </>
      )}
    </div>
  );
};

export default LandingPage;
