import React, { useEffect, useState } from "react";
import Joyride from "react-joyride";
import { API } from "../../global";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const GuidedTour = ({ steps, setRun, run,closeGuid }) => {

  const navigate = useNavigate()

  const [tourCompleted, setTourCompleted] = useState(false);

  useEffect(() => {
    const showGuidedTour = localStorage.getItem("show_guided_tour");
    if (showGuidedTour === "false") {
      setRun(false);
    } else if (showGuidedTour === "true") {
      setRun(true);
    }
    //eslint-disable-next-line
  });

  const handleJoyrideCallback = (data) => {
    const { status, action } = data;


    if (status === "finished" || action === "close" || action === "skip") {
      handleRunGuidedTour();
    }
  };

  const handleRunGuidedTour = () => {
    const obj = {
      show_guided_tour: false,
    };
    closeGuid(false)
    fetch(`${API}/admin/profile/settings/show-guided-tour`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {

          setRun(false);

          localStorage.setItem("show_guided_tour", false);

          setTourCompleted(true);
        }else{
          
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg)
          }
        }
      });
  };

  const customStyles = {
    // Add other styles as required
    options: {
      disableOverlayClose: true, // Prevent closing the tour by clicking on the overlay
      // Add other options as needed
    },
    // Customize tooltip styles
    tooltip: {
      backgroundColor: "#ffffff", // Tooltip background color
      color: "#333", // Tooltip text color
    },
    // Customize button styles
    buttonNext: {
      backgroundColor: "#600097", // Button background color
      color: "#ffffff", // Button text color
      border: "none",
      borderRadius: "5px",
    },
    // Customize the skip button styles
    buttonSkip: {
      backgroundColor: "#600097", // Button background color
      color: "#ffffff", // Button text color
      border: "none",
      borderRadius: "5px",
    },
    // Customize the back button styles
    buttonBack: {
      backgroundColor: "#600097", // Button background color
      color: "#ffffff", // Button text color
      border: "none",
      borderRadius: "5px",
    },
    // Customize overlay styles
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.3)", // Overlay background color with opacity
    },
  };

  return (
    <>
      {tourCompleted ? null : (
        <Joyride
          steps={steps}
          run={run}
          continuous
          scrollToFirstStep
          showProgress
          showSkipButton
          callback={handleJoyrideCallback}
          locale={{
            back: "Back",
            close: "Close",
            last: "Finish",
            next: "Next",
            skip: "Skip",
          }}
          styles={customStyles} // Apply custom styles
        />
      )}
    </>
  );
};

export default GuidedTour;
