import React, { useEffect } from "react";
import { API } from "../../../global";
import { toast } from "react-hot-toast";
import { useState } from "react";
import { useNavigate } from "react-router";

const ATSSettingsCard = ({
  title,
  setShow,
  setIsOpen,
  isOpen,
  imagebgcolor,
  handleClose,
  colorImg,
  handleBackdropClose,
  newConfig,
  ats_client_secret,
  ats_client_id,
}) => {

  const navigate = useNavigate()

  const [atsType, setAtsType] = useState("API");
  const [atsClient_id, setAtsClient_id] = useState("");
  const [atsClient_secret, setAtsClient_Secret] = useState("");

  useEffect(() => {
    if (newConfig) {
      setAtsType("API"); // You may need to replace "API" with the actual value
      setAtsClient_id(ats_client_id || "");
      setAtsClient_Secret(ats_client_secret || "");
    }
  }, [newConfig, ats_client_id, ats_client_secret]);

  const createATSConfiguration = async () => {
    const newATSconfig = {
      ats_name: title,
      ats_type: atsType,
      client_id: atsClient_id,
      client_secret: atsClient_secret,
      tenant_id: localStorage.getItem("tenant-id"),
    };
    // console.log(newATSconfig, "new");

    if (
      title === "" ||
      atsType === "" ||
      atsClient_id === "" ||
      atsClient_secret === ""
    ) {
      toast.error("please fill the required fields");
      setShow(true);
    } else {
      try {
        await fetch(`${API}/admin/tenant/settings/ats/create_atsconfig`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            authorization: `Bearer ${localStorage.getItem("auth_token")}`,
          },
          body: JSON.stringify(newATSconfig),
        })
          .then((res) => res.json())
          .then((val) => {
            if (val.success === true) {
              toast.success("Data saved successfully");
              setShow(false);
              setIsOpen(false);
            } else {
              
              if (val.error.code ===  421) {
                navigate("/error");
              }else{
                toast.error(val.error.msg);
              }
              setShow(false);
              setIsOpen(false);
            }
          });
      } catch (error) {
        console.error("Error creating ATS configuration:", error);
        toast.error("An error occurred while saving the data");
        setShow(false);
        setIsOpen(false);
      }
    }
  };

  const editATSConfiguration = async () => {
    const newATSconfig = {
      ats_name: title,
      ats_type: atsType,
      client_id: atsClient_id,
      client_secret: atsClient_secret,
      tenant_id: localStorage.getItem("tenant-id"),
    };
    if (
      title === "" ||
      atsType === "" ||
      atsClient_id === "" ||
      atsClient_secret === ""
    ) {
      toast.error("please fill the required fields");
      setShow(true);
    } else {
      try {
        await fetch(
          `${API}/admin/tenant/setting/ats/${localStorage.getItem(
            "ats-settings-id"
          )}`,
          {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
              authorization: `Bearer ${localStorage.getItem("auth_token")}`,
            },
            body: JSON.stringify(newATSconfig),
          }
        )
          .then((res) => res.json())
          .then((val) => {
            if (val.success === true) {
              toast.success("Data edited successfully");
              setShow(false);
              setIsOpen(false);
            } else {
              toast.error("Error in saving the data");
              setShow(false);
              setIsOpen(false);
            }
          });
      } catch (error) {
        console.error("Error creating ATS configuration:", error);
        toast.error("An error occurred while saving the data");
        setShow(false);
        setIsOpen(false);
      }
    }
  };

  return (
    <div
      className={`settings-container ${isOpen ? "open" : ""}`}
      method="dialog"
    >
      <div className="d-flex gap-2">
        <div style={{ background: imagebgcolor }} className="icon_img">
          <img src={colorImg} alt="" />
        </div>
        <h5 className="py-2">{title}</h5>
      </div>

      <table className="settngs-card-table">
        <tbody>
          <tr>
            <td>
              <label className="setting-card-label  m-2">Name</label>
            </td>
            <td>
              <input
                type="text"
                placeholder="Enter the source name"
                value={title}
                disabled
                className="settings-card-input  m-2"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label className="setting-card-label m-2">Type </label>
            </td>
            <td>
              <input
                type="text"
                placeholder="Enter the source type"
                value={atsType}
                onChange={(e) => setAtsType(e.target.value)}
                disabled
                className="settings-card-input  m-2"
              />
            </td>
          </tr>
          <tr>
            <td>
              <label className="setting-card-label  m-2">Client-Id</label>
            </td>
            <td>
              <input
                type="text"
                placeholder="Enter the client-id"
                className="settings-card-input  m-2"
                onChange={(e) => setAtsClient_id(e.target.value)}
                value={atsClient_id}
              />
            </td>
          </tr>
          <tr>
            <td>
              <label className="setting-card-label  m-2">Client-Secret</label>
            </td>
            <td>
              <input
                type="password"
                placeholder="Enter the client-secret"
                className="settings-card-input  m-2"
                onChange={(e) => setAtsClient_Secret(e.target.value)}
                value={atsClient_secret}
              />
            </td>
          </tr>
        </tbody>
      </table>

      <div className="settings-button-container">
        <button
          className="setting-card-cancel"
          onClick={() => {
            handleClose();
            handleBackdropClose();
          }}
        >
          Cancel
        </button>
        {newConfig ? (
          <button
            button
            className="setting-card-save"
            onClick={() => {
              handleClose();
              editATSConfiguration();
            }}
          >
            Edit
          </button>
        ) : (
          <button
            className="setting-card-save"
            onClick={() => {
              handleClose();
              createATSConfiguration();
            }}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
};

export default ATSSettingsCard;
