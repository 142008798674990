import React from "react";
import Nav from "../Reusable/Navbar/Nav";
import back from "../../../src/assets/back_arrow.svg";
import resume from '../../../src/assets/resume.svg';
import upload from "../../../src/assets/upload.svg";
import work from "../../../src/assets/WorkExperience.svg";
import downArr from '../../assets/arrow.svg';
import upArr from '../../assets/upArr.svg';
import plus from '../../assets/plus.svg';
import eye from '../../assets/eye.svg';
import cap from '../../assets/Cap.svg';
import setting from '../../assets/wrench-line.svg';
import file from '../../assets/file-earmark-text.svg';
import star from '../../assets/star_solid.svg';
import search from '../../assets/zoom-out-white.svg';
import download from '../../assets/res-download.svg';
import { useNavigate } from "react-router";
import './ResumeParser.css';

const ResumerParser = () => {
  const navigate = useNavigate();
  return (
    <div>
      <Nav />
      {/* content   */}
      <div className="resume-main p-4 m-4 rounded-5"
      >
        {/* heading */}
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <button style={{border:"none",background:"none"}}
             onClick={() => navigate(-1)}
            >
            <img
              src={back}
              style={{ cursor: "pointer" }}
             
              alt=""
              className="backImg"
            />
            </button>
           
          </div>
          <div
            className="text-center"
            style={{
              color: "#600097",
              fontSize: "20px",
              fontWeight: "600",
            }}
          >
            Resume Parser
          </div>
          <div style={{ visibility: "hidden" }}>Resumer</div>
        </div>

        {/* main-container */}
        {/* <div className="resume-container px-2 py-3">
          
          <div className="resume-container-left">
          <div>
            <div className="resume-upload">
             <div className="resume-upload-container"></div>
             <div className="resume-drag-drop"></div>
            </div>

          </div>
          </div>
          <div className="resume-container-right">
           <div>2</div>
          </div>
         
        </div> */}

        <div className="container-fluid mt-4">
          <div className="row">
            <div className="col-md-6 p-0 resume-head overflow-scroll pe-3">
              <div className="resume-upload-container border border-3 rounded d-flex py-4 gap-3 mb-4 shadow">
                {/* upload-container */}
           
                <div className="d-flex px-4 py-3 align-items-center gap-3 col-sm border border-3 rounded ms-4">
                <img
              src={resume}
              style={{ cursor: "pointer" }}            
              alt="resumeImg"
              className="img-fluid py-3 resumeImg"
            />       
            <div><b>No resume uploaded</b></div>  
                </div>
                     

                {/* drag-drop-container */}
                <div className="resume-drag col-sm border-3 rounded px-3 me-4 text-center align-items-center d-flex justify-content-center">
                  <div className="d-flex flex-column justify-content-center">
                    <div className="bg-light text-dark arrowImg py-2 mx-auto">
                  <img
              src={upload}
              style={{ cursor: "pointer" }}           
              alt="uploadImg"
              className="img-fluid uploadImg"
                      />   
                      </div>
              <p6><b>Drag and drop files or browse files</b></p6>
              <small className="text-secondary fw-normal">.doc ,.docx,PDF - Max file size 10MB</small>
                  </div>
              
                </div>
              </div>

              {/*profile details */}

              <div className="profile-secondary border border-3 rounded py-3 px-4 shadow mb-4">
                <div className="mb-3">
  <label for="formGroupInput" className="form-label fw-bold">Name</label>
  <input type="text" className="form-control form-control-lg border-3" placeholder="Enter your name"/>
</div>
<div className="mb-3">
  <label for="formGroupExampleInput2" className="form-label fw-bold">Objective</label>
  <input type="text" className="form-control form-control-lg border-3" id="formGroupInput2"/>
                </div>
                
                <div className="row">
                  <div className="col-8 mb-3">
                    <label for="resumeEmail" className="form-label fw-bold">Email</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumeEmail" placeholder="Enter your email address"/>
                  </div>
                  
                  <div className="col-4 mb-3">
                    <label for="resumePhone" className="form-label fw-bold">Phone</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumePhone" placeholder="Enter mobile number"></input>
                  </div>

                   <div className="col-8 mb-3">
                    <label for="resumeEmail" className="form-label fw-bold">Website</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumeEmail"/>                   
                  </div>

                  <div className="col-4 mb-3">
                    <label for="resumePhone" className="form-label fw-bold">Location</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumePhone"/>
                  </div>
                  
                </div>
              </div>


                 {/*Work experience sec */}

              <div className="profile-secondary border border-3 rounded py-3 px-4 shadow mb-4">
                
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img src={work} alt="" className="WorkImg img-fluid mb-3" />
                      <h6 className="text-nowrap fs-6 fw-bold">WORK EXPERIENCE</h6>
                    </div>


                    <div className="d-flex gap-4">
                      <div><img src={downArr} alt="" /></div>
                      <div><img src={eye} alt="" /></div>
                    </div>
                  </div>


                  <div className="mb-3 mt-2">
                    <label for="JobName" className="form-label fw-bold">Company Name</label>
  <input type="text" className="form-control form-control-lg border-3" id="JobName"/>                 
                  </div>

                   <div className="col-8 mb-3">
                    <label for="resumeJob" className="form-label fw-bold">Job Title</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumeJob"/>                   
                  </div>

                  <div className="col-4 mb-3">
                    <label for="resumeDate" className="form-label fw-bold">Date</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumeDate"/>
                  </div>

                  <div className="mb-3">
                    <label for="resumeDes" className="form-label fw-bold">Date</label>
                    <input type="text" className="form-control form-control-lg border-3" id="resumeDes"/>
                  </div>

                  <div className="WorkButton text-end gap-4">
                    <button className="rounded text-nowrap px-4 py-1 fs-6"><img src={plus} alt="plus" className="img-fluid workImage"/>Add Job</button>
                  </div>
                  
                </div>
              </div>
              
                 {/*Education sec */}

              <div className="border border-3 rounded py-3 px-4 shadow mb-3">
                
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img src={cap} alt="" className="WorkImg img-fluid mb-3" />
                      <h6 className="text-nowrap fs-6 fw-bold">EDUCATION</h6>
                    </div>


                    <div className="d-flex gap-4">
                      <div><img src={upArr} alt="upArr" /></div>
                      <div><img src={downArr} alt="downArr" /></div>
                      <div><img src={eye} alt="" /></div>
                    </div>
                  </div>



                   <div className="col-8 mb-3 mt-2">
                    <label for="resumeSchool" className="form-label fw-bold">School</label>
                    <input type="text" className="form-control form-control-lg border-3" id="resumeSchool"/>                   
                  </div>

                  <div className="col-4 mb-3 mt-2">
                    <label for="schoolDate" className="form-label fw-bold">Date</label>
                    <input type="type" className="form-control form-control-lg border-3" id="schoolDate"/>
                  </div>

                 <div className="col-8 mb-3">
                    <label for="resumeDeg" className="form-label fw-bold">Degree & Major</label>
                    <input type="text" className="form-control form-control-lg border-3" id="resumeDeg"/>                   
                  </div>

                  <div className="col-4 mb-3">
                    <label for="resumeGPA" className="form-label fw-bold">GPA</label>
                    <input type="email" className="form-control form-control-lg border-3" id="resumeGPA"/>
                  </div>
         
                   <div className="mb-3 mt-2">
                    <label for="addInfo" className="form-label fw-bold">Additional Information (Optional)</label>
                    <input type="text" className="form-control form-control-lg border-3" id="addInfo" />                 
                  </div>

                  <div className="EduButton text-end gap-4">
                    <button className="rounded text-nowrap px-4 py-1 fs-6"><img src={plus} alt="plus" className="img-fluid eduImage"/>Add School</button>
                  </div>
                  
                </div>
              </div>


              {/*Custom Sec */}

               <div className="profile-secondary border border-3 rounded py-3 px-4 shadow mb-4">
                
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img src={setting} alt="" className="WorkImg img-fluid mb-3" />
                      <h6 className="text-nowrap fs-6 fw-bold">CUSTOM SECTION</h6>
                    </div>


                    <div className="d-flex gap-4">
                      <div><img src={upArr} alt="upArr" /></div>
                      <div><img src={downArr} alt="" /></div>
                      <div><img src={eye} alt="" /></div>
                    </div>
                  </div>


                  <div className="mb-3 mt-2">
                    <label for="Textbox" className="form-label fw-bold">Custom Textbox</label>
  <input type="text" className="form-control form-control-lg border-3" id="Textbox"/>                 
                  </div>
                </div>
                </div>
              
              {/*Project Sec */}

               <div className="border border-3 rounded py-3 px-4 shadow mb-4 mt-4">
                
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img src={file} alt="" className="WorkImg img-fluid mb-3" />
                      <h6 className="text-nowrap fs-6 fw-bold">PROJECT</h6>
                    </div>


                    <div className="d-flex gap-4">
                      <div><img src={upArr} alt="upArr" /></div>
                      <div><img src={downArr} alt="" /></div>
                      <div><img src={eye} alt="" /></div>
                    </div>
                  </div>

                  <div className="col-8 mb-3 mt-2">
                    <label for="proName" className="form-label fw-bold">Project Name</label>
                    <input type="text" className="form-control form-control-lg border-3" id="proName"/>                   
                  </div>

                  <div className="col-4 mb-3 mt-2">
                    <label for="proDate" className="form-label fw-bold">Date</label>
                    <input type="type" className="form-control form-control-lg border-3" id="proDate"/>
                  </div>

                  <div className="mb-3">
                    <label for="proDesc" className="form-label fw-bold">Description</label>
  <input type="text" className="form-control form-control-lg border-3" id="proDesc"/>                 
                  </div>

                   <div className="ProButton text-end gap-4">
                    <button className="rounded text-nowrap px-4 py-1 fs-6"><img src={plus} alt="plus" className="img-fluid eduImage"/>Add Project</button>
                  </div>

                </div>
              </div>

              {/*Skills */}

                             <div className="border border-3 rounded py-3 px-4 mb-4 mt-4">
                
                <div className="row">
                  <div className="d-flex justify-content-between">
                    <div className="d-flex gap-2">
                      <img src={setting} alt="" className="WorkImg img-fluid mb-3" />
                      <h6 className="text-nowrap fs-6 fw-bold">SKILLS</h6>
                    </div>


                    <div className="d-flex gap-4">
                      <div><img src={upArr} alt="upArr" /></div>
                      <div><img src={eye} alt="" /></div>
                    </div>
                  </div>

                  <div className="mb-3 mt-2">
                    <label for="proName" className="form-label fw-bold">Skills List</label>
                    <input type="text" className="form-control form-control-lg border-3" id="proName" />                 
                    <hr className="hr-line" />
                    
                    <div className="mt-2">
                      <label for="feature" className="form-label fw-bold">Featured Skills (Optional)</label>              
                    </div>

                    <p2 className='text-p'>Featured skills is optional to highlight top skills, with more circles mean higher proficiency.</p2>
                    

                    <div className="row mt-3 feature">
                      <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder='Featured skill 1' id="feature1" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>

                      
                      <div className="col">
                        <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder="Featured Skill 2" id="feature2" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="row mt-3 feature">
                      <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder="Featured Skill 3" id="feature3" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>

                      
                      <div className="col">
                        <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder="Featured Skill 4" id="feature4" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>
                      </div>
                    </div>

                    <div className="row mt-3 feature">
                      <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder="Featured Skill 5" id="feature1" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>

                      
                      <div className="col">
                        <div className="col d-flex gap-3">
                        <input type="text" className="form-control form-control-lg border-3" placeholder="Featured Skill 6" id="feature1" /> 
                        <div className="stars d-flex gap-2">
                          <img src={star} alt="" className="img-fluid"/>
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                          <img src={star} alt="" />
                        </div>
                      </div>
                      </div>
                    </div> 

                  </div>
                 </div>
                </div>
              
            </div>

            {/*Right column */}
            <div className="col-md-6 rightResume">
              
              <div className="resume-sub shadow">
                <div className="resume-sub-bg"></div>

                <div className="list mt-2">
                  <ul className="a">
                    <li className="fw-bold">WORK EXPERIENCE</li>
                    <li className="fw-bold">EDUCATION</li>
                    <li className="fw-bold">CUSTOM</li>
                    <li className="fw-bold">PROJECT</li>
                    <li className="fw-bold">SKILLS</li>
                  </ul>
                </div>

              </div>          

              <div className="resume-sub-head">
                <div className="ms-2 d-flex align-items-center mt-5 justify-content-between">
                  <div className="w-100">
                <img src={search} alt="search" className="resume-search img-fluid" />
                
                    <input type="range" className="form-range ms-3 w-50 align-middle" id="customRange"></input> <span className="fs-5 fw-bold">75 %</span>
                    </div>

                  <div className="d-flex gap-3">
                    <button className="btn sve-btn px-4">Save</button>
                    <button className="btn down-btn"><img src={download} alt="download" className="me-2"/>Download Resume</button>
                  </div>
                </div>

                
               </div>


            </div>
          </div>
        </div>
      </div>

     
    </div>
  );
};

export default ResumerParser;
