import React from 'react'
import { Row,Col, Container } from 'react-bootstrap'
import landingLogo from "../../assets/Talentou Logo-landing.svg";

const Layout = ({children}) => {
  return (
    <div>
       <Container fluid>
        <Row>
          <Col className="right-column" sm={12} md={8} order={1}>
           {children}
          </Col>
          <Col className="left-column" sm={12} md={4} order={2}>
            <div className="logo-div">
              <div className="d-grid">
                <img src={landingLogo} alt="logo" />
                <p className="logo-heading">AI Led Talent Scouting</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Layout
