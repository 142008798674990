import React, { useEffect, useState } from "react";
import "./Jobslist.css";
import TextInput from "./TextInput";
// import { MdLocationOn } from "react-icons/md";
import pluswhite from "../../../src/assets/add-white.svg";
import Select from "react-select";

import { API } from "../../global";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import { textEditorHeader } from "../Functions/Constant";
import ReactQuill from "react-quill";

const CreateJob = (props) => {
  const navigate = useNavigate();
  const modules = textEditorHeader();
  const { modalTitle, handleAddJobModal, jobOrderId, getJobs } = props;
  const [skillData, setSkillData] = useState([]);
  const [skillText, setSkillText] = useState("");
  const [value, setTextValue] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [companyData, setCompanyData] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [loading, setLoading] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [jobOrderName, setJobOrderName] = useState("");
  const [jobError, setJobError] = useState("");
  const [skillError, setSkillError] = useState("");
  const [location, setLocation] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("active");
  const [addedCompany,setAddedCompany] = useState({})

  useEffect(() => {
    fetchCompanyList();
    if (modalTitle.toLowerCase() === "edit") {
      viewJobDetails();
      setInitialLoading(true);
    }
    // eslint-disable-next-line
  }, []);

  const viewJobDetails = () => {
    fetch(`${API}/admin/job-order/view?job_order_id=${jobOrderId}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        setInitialLoading(false);

        if (val.success === true) {
          let companyName = val?.result?.company_id?.company_name;
          companyName = companyName ? companyName : "";
          const obj={
            value :  val?.result?.company_id?._id,
            label : companyName
          }
          setAddedCompany(obj)
          setTextValue(companyName);
          setCompanyId(val?.result?.company_id?._id);
          setJobOrderName(val?.result?.job_role);
          setLocation(val?.result?.location);
          setDescription(val?.result?.description);
          setStatus(val?.result?.status);
          setSkillData(val?.result?.required_skills.split(","));
        } else {
          // setInitialLoading(false);

          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);
      });
  };

  const fetchCompanyList = () => {
    fetch(`${API}/admin/job-order/list-companies`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          let company = [];
          // eslint-disable-next-line
          val.result.map((data) => {
            const obj = {
              value: data._id,
              label: data.company_name,
            };
            company.push(obj);
          });
          setCompanyData(company);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      });
  };

  const handleKeyDown = (e) => {
    if (skillText.trim() !== "") {
      if (e.key === "Enter") {
        setSkillData([...skillData, skillText.trim()]);
        setSkillText("");
        setSkillError("");
      }
    }
  };



 

  const handleInputChangeKeyWord = (e) => {
    setSkillText(e.target.value);
  };

  const removeKeyWord = (index) => {
    // Assuming answerData is a state variable
    setSkillData((prevData) => prevData.filter((_, i) => i !== index));
  };

  const handleCancel = () => {
    setSkillText("");
    setSkillData([]);
  };

 

  const validation = () => {
    let error = true;
    if (!companyId) {
      setCompanyError("Company name is a required field.");
      error = false;
    }

    if (!jobOrderName) {
      setJobError("Job role  is a required field.");
      error = false;
    }

    if (skillData.length === 0) {
      setSkillError("Skill is a required field.");
      error = false;
    }

    return error;
  };

  const onSubmit = (data) => {
    const jobObj = {
      job_role: jobOrderName,
      required_skills: skillData.join(","),
      location: location,
      description: description,
      status: status,
      company_id: companyId,
    };

    // if (companyId) {
    //   jobObj.company_id = companyId;
    // } else {
    //   jobObj.company_name = value;
    // }
    setLoading(true);
    let path = "";
    let message = "";
    if (modalTitle.toLowerCase() === "edit") {
      path = "update";
      jobObj.job_order_id = jobOrderId;
      message = "updated";
      //   jobObj.company_name = companyName !== value ? value : "";
    } else {
      path = "create";
      message = "created";
    }
    console.log("jobObj", jobObj);
    console.log("validation()", validation());
    if (!validation()) {
      setLoading(false);
    } else {
      saveData(path, message, jobObj);
    }
  };

  const saveData = (path, message, jobObj) => {
    fetch(`${API}/admin/job-order/${path}`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(jobObj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          toast.success(`Job ${message} successfully`);
          handleCancel();
          handleAddJobModal();
          getJobs();
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const handleChange = (e) => {
    if (e.target.id === "jobOrder") {
      setJobOrderName(e.target.value);
      setJobError("");
    }

    if (e.target.id === "location") {
      setLocation(e.target.value);
    }

    if (e.target.id === "status") {
      setStatus(e.target.value);
    }
  };

  const handleChangeDescription = (val) => {
    setDescription(val);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      backgroundColor: '#f7f7f7',
      borderColor:"#e9e9e9",
      minHeight: '40px',
      height: '40px',
      boxShadow: 'none',
    }),
    valueContainer: (provided) => ({
      ...provided,
      height: '40px',
      padding: '0 6px'
    }),
    input: (provided) => ({
      ...provided,
      margin: '0px',
      padding: '0px',
      border: 'none',
      boxShadow: 'none',
      outline: 'none',
    }),
    indicatorSeparator: () => ({
      display: 'none',
    }),
    indicatorsContainer: (provided) => ({
      ...provided,
      height: '40px',
    }),
  };

  const handleCompany =(data)=>{
    setCompanyId(data.value)
  }

  console.log("comanyId",companyId)
  console.log("value",value)

  return (
    <>
      {initialLoading ? (
        <>
          <div className="loader-div-createATS">
            <ProgressBarComponent />
          </div>
        </>
      ) : (
        <div>
          <h5>{modalTitle} Job</h5>
          {/* <form> */}
          <div className="add-job-modal">
            <div className="d-flex justify-content-between w-100 gap-3">
              <div className="w-100">
                <label for="Company" className="fw-bold">
                  Company Name
                </label>
                <br />
                <Select
                  options={companyData}
                  isClearable={true}
                  isSearchable={true}
                  styles={customStyles}
                  onChange={handleCompany}
                  defaultValue={addedCompany}
                />
               
                <p style={{ color: "red" }}>{companyError}</p>
              </div>

              <div className="w-100">
                <label for="JobOrder" className="fw-bold">
                  Job Role
                </label>
                <br />
                <div className="d-flex gap-2">
                  <input
                    type="text"
                    className="form-control w-100  input-add-job job-order-create"
                    id="jobOrder"
                    placeholder="Enter job role"
                    onChange={(e) => handleChange(e)}
                    value={jobOrderName}
                  />
                </div>
                <p style={{ color: "red" }}>{jobError}</p>
              </div>
            </div>

            <div>
              <label for="Skills" className="fw-bold">
                Required Skills (Type the skill and press enter)
              </label>
              <div>
                <TextInput
                  handleKeyDown={handleKeyDown}
                  handleInputChangeKeyWord={handleInputChangeKeyWord}
                  removeKeyWord={removeKeyWord}
                  answerKeyword={skillText}
                  answerData={skillData}
                  id="answerKey"
                  placeHolder={"Type the required skills and press enter"}
                  type="talentou-ats"
                />
              </div>
              <p style={{ color: "red" }}>{skillError}</p>
            </div>

            <div>
              <label for="Skills" className="fw-bold">
                Description
              </label>
              <div className="d-flex gap-2 description-editor">
                <ReactQuill
                  placeholder="Enter your message"
                  className="descriptionText"
                  modules={modules}
                  onChange={(val) => handleChangeDescription(val)}
                  value={description}
                />
              </div>
            </div>

            <div className="d-flex justify-content-between w-100 gap-3">
              <div className="w-100">
                <label for="Location" className="fw-bold">
                  Location
                </label>
                <br />
                <div className="d-flex gap-2">
                  <div className="d-flex w-100 position-relative">
                    {/* <MdLocationOn
                      size={20}
                      color="#690094"
                      className="position-absolute m-2 "
                    /> */}
                    <input
                      type="text"
                      className="form-control w-100 input-add-job ml-3"
                      style={{ paddingInlineStart: " 10px" }}
                      id="location"
                      placeholder="Enter job location"
                      onChange={(e) => handleChange(e)}
                      value={location}
                    />
                  </div>
                </div>
              </div>

              <div className="w-100">
                <label for="Location" className="fw-bold">
                  Status
                </label>
                <br />
                <div className="d-flex gap-2">
                  <select
                    id="status"
                    className="form-control w-100 input-add-job ml-3 status-background"
                    onChange={(e) => handleChange(e)}
                    defaultValue={status}
                    disabled={modalTitle.toLowerCase() !== "edit"}
                  >
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="action-div-add-job">
              <button
                className="cancel-button-style"
                onClick={() => {
                  handleAddJobModal();
                  handleCancel();
                }}
              >
                Cancel
              </button>
              <button
                className="btn text-white py-1 add-ats-job-button pt-1"
                type="submit"
                onClick={onSubmit}
              >
                {modalTitle === "Edit" ? (
                  <span className="mt-1">
                    {loading ? "Loading.." : "Update"}
                  </span>
                ) : (
                  <>
                    {loading ? (
                      "Loading.."
                    ) : (
                      <>
                        <img src={pluswhite} alt="plus" width={12} />
                        <span className="ms-2 mt-1">Add Job</span>
                      </>
                    )}
                  </>
                )}
              </button>
            </div>
          </div>
          {/* </form> */}
        </div>
      )}
    </>
  );
};

export default CreateJob;
