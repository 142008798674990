import React, { useRef, useState } from "react";
import "./CompanyList.css";
import { Dropdown } from "react-bootstrap";
import Modal from "react-responsive-modal";
import download from "../../../src/assets/file-download-purple.svg";
import downloadwhite from "../../../src/assets/file-download.svg";
import plus from "../../../src/assets/add-purple.svg";
import pluswhite from "../../../src/assets/add-white.svg";
import { Tooltip } from "react-tooltip";
import CreateCompany from "./CreateCompany";
import ImportCompany from "./ImportCompany";
import CompanyList from "./CompanyList-Template.xlsx";
import { CSVLink } from "react-csv";
// import { CSVLink } from "react-csv";

const CompanyListATS = ({
  handleAddCompanyModal,
  showAddCompany,
  modalTitle,
  setModalTitle,
  setShowAddCompany,
  companyId,
  getCompanyList,
  csvData,
  csvHeaders,
}) => {
  const [isHoveredAdd, setIsHoveredAdd] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [showImportCompany, setShowImportCompany] = useState(false);
  const fileInputRef = useRef(null);
  const [importCompanyFile, setImportCompanyFile] = useState(null);
  const [importfile, setImportFile] = useState(false);

  const handleClose = () => {
    setImportCompanyFile("");
    setImportFile(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the value of the file input element
    }

    // fileInputRef.current.click();
  };

  const handleImportCompanyModal = () => {
    setShowImportCompany(false);
    handleClose();
    getCompanyList();
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleHoverAdd = () => {
    setIsHoveredAdd(true);
  };

  const handleMouseLeaveAdd = () => {
    setIsHoveredAdd(false);
  };

  const customStylesAddCompany = {
    modal: {
      maxWidth: "630px", // Set your custom width here
    },
    closeIcon: {
      display: "none", // Hide the close icon
    },
  };

  return (
    <>
      <Tooltip
        id="compy-talentou-ats-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <div className="companylist-icons" style={{ width: "16%" }}>
        <div className="companylist-icon-left">
          <div
            className="company-csv-download "
            onMouseEnter={handleHoverAdd}
            onMouseLeave={handleMouseLeaveAdd}
          >
            <div className="d-flex align-item-center justify-content-center">
              <Dropdown>
                <Dropdown.Toggle
                  className="d-flex align-items-center gap-2 p-1 m-0"
                  style={{ background: "none", border: "none" }}
                  // variant="success"
                  // id="dropdown-basic"
                  data-tooltip-id="compy-talentou-ats-tooltip"
                  data-tooltip-content="Add Company"
                  data-tooltip-place="top"
                >
                  <img
                    src={isHoveredAdd ? pluswhite : plus}
                    // className="py-1"
                    alt="plus"
                    width={12}
                  />
                  {/* <div style={{ fontSize: "14px" }}>Add Job</div> */}
                </Dropdown.Toggle>

                <Dropdown.Menu
                  style={{
                    width: "125px",
                    backgroundColor: "#F8EBFF",
                    marginTop: "6px",
                    marginLeft: "-10px",
                    padding: "0px",
                  }}
                >
                  <Dropdown.Item
                    style={{ fontSize: "13px" }}
                    onClick={() => {
                      setShowAddCompany(true);
                      setModalTitle("Add");
                    }}
                  >
                    Add Company
                  </Dropdown.Item>
                  <Dropdown.Item
                    style={{ fontSize: "13px" }}
                    onClick={() => setShowImportCompany(true)}
                  >
                    Import Company
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <div
            className="company-csv-download"
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={"companylist.csv"}
              style={{
                color: isHovered ? "white" : "#46006E",
                textDecoration: "none",
                paddingInline: "4px",
              }}
              data-tooltip-id="compy-talentou-ats-tooltip"
              data-tooltip-content="Download CSV"
              data-tooltip-place="top"
            >
              <img
                src={isHovered ? downloadwhite : download}
                alt=""
                width={14}
              />
            </CSVLink>
          </div>
        </div>
      </div>
      <Modal
        styles={customStylesAddCompany}
        open={showAddCompany}
        onClose={() => handleAddCompanyModal()}
        center
      >
        <CreateCompany
          modalTitle={modalTitle}
          companyId={companyId}
          handleAddCompanyModal={handleAddCompanyModal}
          getCompanyList={getCompanyList}
        />
      </Modal>

      <Modal
        styles={customStylesAddCompany}
        open={showImportCompany}
        onClose={() => handleImportCompanyModal()}
        center
      >
        <ImportCompany
          fileInputRef={fileInputRef}
          importfile={importfile}
          setImportFile={setImportFile}
          importCompanyFile={importCompanyFile}
          setImportCompanyFile={setImportCompanyFile}
          handleImportCompanyModal={handleImportCompanyModal}
          handleClose={handleClose}
          getCompanyList={getCompanyList}
          CompanyList={CompanyList}
        />
      </Modal>
    </>
  );
};

export default CompanyListATS;
