import React, { useState } from "react";
import play_small from "../../assets/videoPlayGreen.svg";
import toast from "react-hot-toast";
import "./ViewRecordings.css";
import { API } from "../../global";
import { Buffer } from 'buffer';
import { useNavigate } from "react-router";

const QuestionAnswerDetails = ({ type, questionAnswer, i, editDisable, setVideoPlayBtn, playVideo, currentquestionId, totalQuestions }) => {
  const navigate = useNavigate()

  const [showAnswer, setShowAnswer] = useState(false);
  const [starRating, setStarRating] = useState(questionAnswer.rating);



  //update each question rating
  const updateRating = (rate, record) => {
    if (editDisable) {
      toast.error("Sorry you are not allowed to rate this interview");
    } else {
      const updatedRating = {
        interview_schedule_id: questionAnswer?.interview_schedule_id,
        interview_record_id: record._id,
        rating: rate,
      };
      fetch(`${API}/admin/interview/review/update-question-rating`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(updatedRating),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            toast.success("Rating updated Successfully");
          } else {
           
            if (val.error.code ===  421) {
              navigate("/error");
              }else{
                toast.error(val.error.msg);
              }
          }
        });
    }
  };

  const changeRating = (index, record) => {
    if (editDisable) {
      toast.error("Sorry you are not allowed to rate this interview");
    } else {
      updateRating(index, record);
      setStarRating(index);
    }
  };


  // Your HTML-encoded string


  // Function to decode HTML entities
  const decodeHtmlEntities = (html) => {
    const textarea = document.createElement('textarea');
    textarea.innerHTML = html;
    return textarea.value;
  };

  // Decoding the HTML entities


  const isBase64 = (text) => {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
    if (base64regex.test(text)) {
      return Buffer.from(text, 'base64').toString('utf8');
    } else {
      return text;
    }
  }


  return (
    <>
      <div
        className={`${currentquestionId === questionAnswer.question_id
          ? "ques-active"
          : "ques-container"
          }`}
        key={i}
      >
        <div className="ques">
          <div className="question-header-container">
            <div className="questions-label">
              {`Question ${i + 1} of ${totalQuestions}`}
            </div>
            <div className="d-flex gap-2">
              {currentquestionId === questionAnswer.question_id ? (
                <span
                  className="badge bg-primary"
                  style={{ paddingTop: "0.4rem" }}
                >
                  Now Playing
                </span>
              ) : (
                <button
                  style={{ background: "none", border: "none" }}
                  onClick={() => {
                    setVideoPlayBtn(true);
                    playVideo(questionAnswer, i);
                  }}
                >
                  <img
                    src={play_small}
                    alt="job"
                    style={{ cursor: "pointer", width: "40px" }}
                  />
                </button>
              )}
              {type !== "view" && (
                <button
                  className="ans-text"
                  type="button"
                  onClick={() => {
                    setShowAnswer(!showAnswer);
                  }}
                >
                  Ans
                </button>
              )}
            </div>
          </div>
          <hr className="question-border" />
          {/* <div style={{ fontSize: "14px" }}>{questionAnswer.question}</div> */}
          <div
            style={{ fontSize: "14px" }}
            dangerouslySetInnerHTML={{
              __html: decodeHtmlEntities(questionAnswer.question),
            }}
          />
          <div
            className={`${showAnswer ? "" : "hide-answer"
              } mt-3 view-recording-answer`}
          >
            <b>Ans:</b> <i>{isBase64(questionAnswer.answer_key)}</i>
          </div>
        </div>
        {type !== "view" && (
          <>
            {(localStorage.getItem("current_role_key") ===
              "talentou_tech_lead" ||
              starRating >= 1) && (
                <div
                  className={`${starRating >= 1 ? "rate-card" : "rate-card-grey"
                    } rate-card-text`}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "0 0 10px 10px",
                    padding: "5px 20px",
                  }}
                >
                  <div
                    className={`${starRating >= 1 ? "rate-card-text" : "rate-card-text-grey"
                      } rate-card-text`}
                  >
                    Rating
                  </div>
                  <div>
                    {[...Array(5)].map((star, index) => {
                      index += 1;
                      return (
                        <button
                          type="button"
                          className="star-button-qn"
                          // disabled={editDisable}
                          key={index}
                          onClick={() => changeRating(index, questionAnswer)}
                          disabled={editDisable}
                        >
                          <span
                            className={
                              index <= starRating
                                ? "fa fa-star checked"
                                : "fa fa-star"
                            }
                          />
                        </button>
                      );
                    })}
                  </div>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default QuestionAnswerDetails;
