import React, { useState } from "react";
import { useEffect } from "react";

import Switch from 'react-switch';

const SwitchComponent = (props) => {

const [checked,setChecked] = useState(false);

useEffect(()=>{
   if(props.value !== undefined){
    setChecked(props.value)
   }

},[props])

const handleChange =(check)=>{
    setChecked(check)
    props.switchButton(check,props.type)
}

// console.log(props,"props123")


  return (
    <div>
      <Switch
        onChange={(e) => handleChange(e)}
        checked={checked}
        disabled={props?.disabled}
        uncheckedIcon={
          <div
            style={{
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
              margin:"3px"
            }}
          >
            {props?.switchText ?'No':'OFF'}
          </div>
        }
        checkedIcon={
          <div
            style={{
              color: "#fff",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              textAlign: "center",
              fontWeight: "500",
              padding:"2px"
            }}
          >
             {props?.switchText ?'Yes':'ON'}
          </div>
        }
        width={70}
      />
    </div>
  );
};

export default SwitchComponent;
