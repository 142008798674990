import React, { useState } from "react";
import "./DurationButton.css";

import minusDuration from "../../../assets/minusDuration.svg";
import plusDuration from "../../../assets/plusDuration.svg";
import { useEffect } from "react";

const DurationButton = (props) => {
  const [count, setCount] = useState(0);
  const [buttonTitle, setButtonTitle] = useState("");

  useEffect(() => {
    if (props.type === "linkExpiry") {
      setButtonTitle("Select Day(s)");
      if (props.value) {
        setCount(props.value);
      }
    }

    if (props.type === "duration") {
      setButtonTitle("Select Minute(s)");
      if (props.value) {
        setCount(props.value);
      }
    }

    if(props.type === 'experience'){
      setButtonTitle("0");
      if (props.value) {
        setCount(props.value);
      }
    }

    if (props.type === "waitDuration") {
    
      if (props.value) {
        setCount(props.value);
      }
    }

    if (props.type === "resendOtpLimit") {
      setButtonTitle("0");
      if (props.value) {
        setCount(props.value);
      }
    }
  }, [props]);


  const countChange = (type) => {
    if (type === "decrement") {
      if (count > 0) {
        setCount(count - 1);
        props.countChange(count - 1, props.type);
      }
    }

    if (type === "increment") {
      setCount(count + 1);
      props.countChange(count + 1, props.type);
    }
  };


  return (
    <div>
      <div className="buttonDiv d-flex align-items-baseline border">
        <button
          className="actionButton justify-content-start"
          onClick={() => countChange("decrement")}
        >
          <img src={minusDuration} alt="" />
        </button>

        <div className="durationDiv">
          <p>{count === 0 ? buttonTitle : count}</p>
        </div>
        <button
          className="actionButton justify-content-end"
          onClick={() => countChange("increment")}
        >
          <img src={plusDuration} alt="" />
        </button>
      </div>
    </div>
  );
};

export default DurationButton;
