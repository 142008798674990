import React, { useState, useEffect } from "react";
import "../InitialPage/InitialPage.css";
import { Button, Form, InputGroup } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API } from "../../global";
import {  useNavigate, useParams } from "react-router";
import toast from "react-hot-toast";
import { useRef } from "react";
import { jwtDecode } from "jwt-decode";
import Layout from "../Layout/Layout";
import { TailSpin } from "react-loader-spinner";
import warning from "../../assets/Warning-icons.jpg";
import PasswordChecklist from "react-password-checklist";
import { BsEye, BsEyeSlash } from "react-icons/bs";

// import Joyride from "react-joyride";

const SetPassword = () => {
  const { uuid, token } = useParams();
  const currentUrl = window.location.href;

  // Parse the URL
  const url = new URL(currentUrl);

  // Get the pathname which contains the "reset-password" text
  const pathname = url.pathname;

  // Extract the "reset-password" text
  const typeText = pathname.split("/")[1];
  const [loading, setLoading] = useState(false);
  const [subHeading, setSubHeading] = useState("");
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [showRetypePassword, setShowRetypePassword] = useState(false);
  const [checklist, setCheckList] = useState(false);
  // const [steps, setSteps] = useState([]);
  // const [run, setRun] = useState(true);

  const schema = yup
    .object({
      password: yup
        .string()
        .min(8, "Password is too short - should be 8 chars minimum.")
        .required("Password is required"),
      reTypePassword: yup
        .string()
        .oneOf([yup.ref("password"), null], "Passwords must match"),
    })
    .required();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (typeText !== "reset-password") {
      fetch(`${API}/admin/auth/verify`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ verify_account_key: uuid }),
      })
        .then((res) => res.json())
        .then((response) => {
          if (response.success) {
            setSubHeading(
              `Your email verification is confirmed. To proceed, kindly set your password.`
            );
            localStorage.setItem(
              "forget_password_key",
              response.result.forget_password_key
            );
            localStorage.setItem("setPasswordToken", response.result.token);
          } else {
            const passwordKey = localStorage.getItem("forget_password_key");
            if (!passwordKey) {
              setShowError(true);
              setSubHeading(
                `The verification link you provided seems to be incorrect.`
              );
            } else {
              setSubHeading(
                `Your email verification is confirmed.\nTo proceed, kindly set your password.`
              );
            }
            if (response.error.code === 421) {
              navigate("/error");
            }
          }
          setLoader(false);
        })
        .catch(() => {});
    } else {
      setLoader(false);
    }

    // eslint-disable-next-line
  }, []);

  const password = useRef({});
  password.current = watch("password", "");

  const onSubmit = (data) => {
    const tokenData = typeText !== "reset-password" ? localStorage.getItem("setPasswordToken") :token;
    setLoading(true);
    fetch(`${API}/admin/auth/reset-password`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${tokenData}`,
      },
      body: JSON.stringify({
        forget_password_key: typeText !== "reset-password" ? localStorage.getItem("forget_password_key") :uuid,
        password: data.password,
      }),
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          localStorage.setItem("auth_token", response.result.token);
          localStorage.setItem("user-name", response.result.name);
          localStorage.setItem(
            "user_role_keys",
            JSON.stringify(response.result.user_role_keys)
          );
          localStorage.setItem(
            "current_role_key",
            response.result.current_role_key
          );
          // setLoading(false);

          // if (localStorage.getItem("current_role_key") === "talentou_admin") {
          //   setSteps(stepsAdmin);
          // }

          if (localStorage.getItem("sharedLink")) {
            navigate(localStorage.getItem("sharedLink"));
          } else {
            // if (val.result.current_role_key === "talentou_super_admin") {
            //   navigate("/superAdmin-dashboard");
            // } else {
            getTenantDataWithEmail(response.result.token);

            // }
          }
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };
  const getTenantDataWithEmail = (token) => {
    const decoded = jwtDecode(token);
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ tenant_id: decoded.tenant_id }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          toast.success("You are logged in successfully.");

          navigate("/admin-dasboard");
          // localStorage.setItem("run_steps", true);
          localStorage.removeItem("forget_password_key");
          localStorage.removeItem("setPasswordToken");
          setThemeData(response?.result?.tenant_theme_settings);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const setThemeData = (theme) => {
    const themeColor = {
      primaryColor: theme.theme_primary,
      primaryShade1: theme.theme_color1,
      primaryShade2: theme.theme_color2,
      primaryShade3: theme.theme_color3,
      primaryShade4: theme.theme_color4,
      primaryBtn: theme.btn_primary,
      secondaryBtn: theme.btn_secondary,
    };

    localStorage.setItem("themeColors", JSON.stringify(themeColor));
    localStorage.setItem("logoUrl", theme.logo_url ? theme.logo_url : "");

    document.documentElement.style.setProperty(
      "--primary-color",
      theme.theme_primary
    );
    document.documentElement.style.setProperty(
      "--primary-shade1",
      theme.theme_color2
    );
    document.documentElement.style.setProperty(
      "--primary-shade2",
      theme.theme_color2
    );
    document.documentElement.style.setProperty(
      "--primary-shade3",
      theme.theme_color3
    );
    document.documentElement.style.setProperty(
      "--primary-shade4",
      theme.theme_color4
    );
    document.documentElement.style.setProperty(
      "--primary-btn",
      theme.btn_primary
    );
    document.documentElement.style.setProperty(
      "--secondary-btn",
      theme.btn_secondary
    );
  };
  const togglePasswordVisibility = (type) => {
    if (type === "password") {
      setShowPassword(!showPassword);
    } else {
      setShowRetypePassword(!showRetypePassword);
    }
  };
  const passwordChecklistFun = (e) => {
    setCheckList(e);
  };

  return (
    <>
      <Layout>
        <div className="logo-div">
          <div className="login-body">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  {loader ? (
                    <div style={{ margin: "50px" }}>
                      <TailSpin
                        visible={true}
                        height="100"
                        width="100"
                        color="#600097"
                        ariaLabel="tail-spin-loading"
                        radius="1"
                        wrapperStyle={{}}
                        wrapperClass=""
                      />
                    </div>
                  ) : (
                    <div className="reset-card-email">
                      <div
                        className="panel-heading"
                        style={{ textAlign: "center" }}
                      >
                        {showError ? (
                          <>
                            <img
                              src={warning}
                              alt="Error"
                              style={{ width: "40%" }}
                            />
                            <div style={{ margin: "20px" }}>
                              <p className="main-heading-success">
                                {"Sorry!!"}
                              </p>
                              <p className="sub-heading">{subHeading}</p>
                            </div>
                            {/* <button
                              onClick={backToLogin}
                              className="login-btn m-2"
                            >
                              Back to Login
                            </button> */}
                          </>
                        ) : (
                          <>
                            {typeText !== "reset-password" ?(
                              <div className="content">
                                <svg
                                  width="100"
                                  height="100"
                                  viewBox="0 0 200 200"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <circle
                                    fill="none"
                                    stroke="#68E534"
                                    stroke-width="5"
                                    cx="100"
                                    cy="100"
                                    r="90"
                                    strokeLinecap="round"
                                    transform="rotate(-90 100 100)"
                                    class="circle"
                                  />
                                  <polyline
                                    fill="none"
                                    stroke="#68E534"
                                    points="44,114 86.5,142 152,69"
                                    strokeWidth="5"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    class="tick"
                                  />
                                </svg>
                              </div>
                            ):(
                              <div></div>
                            )}
                          </>
                        )}
                        {!showError && (
                          <>
                            <div style={{ margin: "20px" }}>
                              <p className="sub-heading">{subHeading}</p>
                              {/* <p className="sub-heading">{subHeading}</p> */}
                            </div>
                            <p className="signin-label mt-4">
                              Set your password
                            </p>
                          </>
                        )}
                      </div>
                      {!showError && (
                        <>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="reset-div">
                              <InputGroup className="m-2">
                                <Form.Control
                                  type={showPassword ? "text" : "password"}
                                  placeholder="Enter password"
                                  defaultValue={watch("password")}
                                  {...register("password")}
                                />
                                <Button
                                  variant="outline-secondary"
                                  style={{ border: "1px solid #E9E9E9" }}
                                  onClick={() =>
                                    togglePasswordVisibility("password")
                                  }
                                >
                                  {!showPassword ? <BsEyeSlash /> : <BsEye />}
                                </Button>
                              </InputGroup>
                              {errors?.password?.message ? (
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {errors.password.message}
                                </div>
                              ) : null}

                              <InputGroup className="m-2">
                                <Form.Control
                                  type={
                                    showRetypePassword ? "text" : "password"
                                  }
                                  placeholder="Re-enter password"
                                  defaultValue={watch("reTypePassword")}
                                  {...register("reTypePassword")}
                                />
                                <Button
                                  variant="outline-secondary"
                                  style={{ border: "1px solid #E9E9E9" }}
                                  onClick={() =>
                                    togglePasswordVisibility("reTypePassword")
                                  }
                                >
                                  {!showRetypePassword ? (
                                    <BsEyeSlash />
                                  ) : (
                                    <BsEye />
                                  )}
                                </Button>
                              </InputGroup>

                              {errors?.reTypePassword?.message ? (
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    marginLeft: "6px",
                                  }}
                                >
                                  {errors.reTypePassword.message}
                                </div>
                              ) : null}

                              {watch("password") && (
                                <div className="m-2">
                                  <PasswordChecklist
                                    rules={[
                                      "minLength",
                                      "specialChar",
                                      "number",
                                      "capital",
                                      "match",
                                    ]}
                                    minLength={8}
                                    value={watch("password")}
                                    valueAgain={watch("reTypePassword")}
                                    onChange={(e) => passwordChecklistFun(e)}
                                    messages={{
                                      minLength:
                                        "The password must contain at least 8 characters.",
                                      specialChar:
                                        "The password must contain a special character.                                      ",
                                      number:
                                        "The password must contain a number.",
                                      capital:
                                        "The password must contain a capital letter.",
                                      match: "Passwords should be matched.",
                                    }}
                                  />
                                </div>
                              )}
                              <div className="button-container-login mt-2">
                                {loading ? (
                                  <button className="reset-btn m-2">
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className={
                                      !checklist
                                        ? "reset-btn-disabled m-2"
                                        : "reset-btn m-2"
                                    }
                                    disabled={!checklist}
                                  >
                                    {loading ? "Loading.." : "Set Password"}
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SetPassword;
