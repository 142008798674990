import React, { useEffect, useState, useRef } from "react";
import "../InitialPage/InitialPage.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API, captchaSiteKey } from "../../global";
import { useNavigate } from "react-router";
import toast from "react-hot-toast";
import Modal from "react-responsive-modal";
import Layout from "../Layout/Layout";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import validator from "email-validator";
const SignUp = () => {
  const recaptchaRef = useRef();

  const [loading, setLoading] = useState(false);
  const [disableEmail, setDesableEmail] = useState(false);
  const [open, setOpen] = useState(false);
  const [eulaChecked, setEulaChecked] = useState(false);
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is a required field.")
        .test(
          "email-valid",
          "Please, use standard email format. (for e.g., username@example.com)",
          (value) => validator.validate(value)
        ),
      name: yup
        .string()
        .required("Full Name is a required field.")
        .matches(/^[A-Za-z\s]+$/, "Only alphabets are allowed"),
      companyName: yup.string().required("Company Name is a required field"),
    })
    .required();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const emailValue = localStorage.getItem("emailData");
    if (emailValue) {
      setValue("email", emailValue);
      setDesableEmail(true);
    }

    // eslint-disable-next-line
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);
    const token = await recaptchaRef.current.executeAsync();
    localStorage.removeItem("emailData");
    const obj = {
      company_name: data.companyName,
      name: data.name.trim(),
      email: data.email,
      is_eula_accepted: eulaChecked,
      token: token,
    };
    fetch(`${API}/admin/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          navigate("/success?type=register");
          setLoading(false);
        } else {
          setLoading(false);
          toast.error(response.error.msg, {
            duration: 9000,
            // Display toast for 3 seconds
            style: {
              maxWidth: "370px", // Custom maximum width
              textAlign: "justify",
            },
          });
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const openModel = () => {
    setOpen(true);
  };

  const onChecked = (e) => {
    setEulaChecked(e.target.checked);
  };

  return (
    <>
      <Layout>
        <div className="logo-div">
          <div className="login-body">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  <div className="login-card-email">
                    <div
                      className="panel-heading"
                      style={{ textAlign: "center" }}
                    >
                      <p className="signin-label mt-4">Start Using Talentou</p>
                    </div>
                    <>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="basic-form-div">
                          <input
                            className="form-control-login m-2"
                            placeholder="Full Name"
                            {...register("name")}
                            // onKeyDown={handleKeyPress}
                          />
                          {errors?.name?.message ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "7px",
                                fontSize: "12px",
                              }}
                            >
                              {errors.name.message}
                            </div>
                          ) : null}

                          <input
                            className={
                              disableEmail
                                ? "form-control-login-disabled m-2"
                                : "form-control-login m-2"
                            }
                            placeholder="Email ID"
                            {...register("email")}
                            // onKeyDown={handleKeyPress}
                            readOnly={disableEmail}
                          />

                          {errors?.email?.message ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "7px",
                                fontSize: "12px",
                              }}
                            >
                              {errors.email.message}
                            </div>
                          ) : null}

                          <input
                            className="form-control-login m-2"
                            placeholder="Company Name"
                            {...register("companyName")}
                            // onKeyDown={handleKeyPress}
                          />
                          {errors?.companyName?.message ? (
                            <div
                              style={{
                                color: "red",
                                marginTop: "5px",
                                marginLeft: "7px",
                                fontSize: "12px",
                              }}
                            >
                              {errors.companyName.message}
                            </div>
                          ) : null}

                          <div class="tacbox">
                            <input
                              id="checkbox"
                              className="tacbox-input"
                              type="checkbox"
                              onChange={onChecked}
                            />
                            <label for="checkbox">
                              {" "}
                              I agree with these{" "}
                              <Link onClick={openModel}>
                                Terms and Conditions
                              </Link>
                              .
                            </label>
                          </div>

                          <div className="button-container-login mt-2">
                            {loading ? (
                              <button className="login-btn m-2">
                                Loading...
                              </button>
                            ) : (
                              <button
                                type="submit"
                                className={
                                  !eulaChecked
                                    ? "login-btn-disabled m-2"
                                    : "login-btn m-2"
                                }
                                disabled={!eulaChecked}
                              >
                                {loading ? "Creating.." : "Create My Account"}
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <div>
        <ReCAPTCHA
          sitekey={captchaSiteKey}
          // onChange={handleVerify}/
          size="invisible"
          ref={recaptchaRef}
        />
      </div>
      <Modal open={open} onClose={() => setOpen(false)}>
        <h3 className="eula-header">
          End-User License Agreement (EULA) for Talentou
        </h3>
        <div className="eula-content-div">
          <p>
            This End-User License Agreement ("Agreement") is a legal agreement
            between you ("User") and Talentou, governing your use of Talentou's
            services. By signing up for an account with Talentou, you agree to
            be bound by the terms of this Agreement.
          </p>
          <h6>1. Grant of License</h6>
          <p>
            Talentou grants users a limited, non-exclusive, non-transferable
            license to use the Talentou platform and services for the purpose of
            conducting one-way video interviews and streamlining the recruitment
            process.
          </p>
          <h6>2. Company Name and Logo Usage</h6>
          <p>
            By creating an account with Talentou in your company's name, you
            grant Talentou the right to use your company's name and logo for
            marketing and promotional purposes. Talentou agrees not to use your
            company's name or logo in any manner that is misleading or
            disparaging.
          </p>
          <h6>3. Confidentiality</h6>
          <p>
            Talentou agrees to keep all user, job order, candidate, and
            interview details confidential. Talentou will not share this
            information with any third party without the user's explicit
            consent, except for internal analytics purposes within Talentou.
          </p>
          <h6>4. Internal Analytics</h6>
          <p>
            User acknowledges and agrees that Talentou may use the data
            collected from user interactions within the platform for internal
            analytics purposes only. This data will be anonymized and used
            solely to improve the performance and functionality of Talentou's
            services.
          </p>
          <h6>5. Ownership</h6>
          <p>
            The user acknowledges that Talentou owns all rights, title, and
            interest in and to the Talentou platform, including all intellectual
            property rights. User agrees not to modify, adapt, translate,
            reverse engineer, decompile, or disassemble the Talentou platform.
          </p>
          <h6>6. Disclaimer of Warranty</h6>
          <p>
            The Talentou platform is provided "as is" and "as available,"
            without any warranty of any kind, either express or implied.
            Talentou disclaims all warranties, including but not limited to the
            implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement.
          </p>
          <h6>7. Limitation of Liability</h6>
          <p>
            In no event shall Talentou be liable for any indirect, incidental,
            special, consequential, or punitive damages arising out of or in
            connection with the use or inability to use the Talentou platform,
            even if Talentou has been advised of the possibility of such
            damages. In any case, Talentou's total liability under this
            Agreement shall not exceed the amount paid by users to Talentou for
            the use of the platform.
          </p>
          <h6>8. Termination</h6>
          <p>
            Talentou reserves the right to terminate this Agreement and User's
            access to the Talentou platform at any time, for any reason or no
            reason, with or without notice. Upon termination, User must cease
            all use of the Talentou platform and destroy all copies of any
            Talentou materials in User's possession or control.
          </p>
          <h6>9. Governing Law</h6>
          <p>
            This Agreement shall be governed by and construed in accordance with
            the laws of Virginia, USA, without regard to its conflicts of law
            principles.
          </p>
          <h6>10. Entire Agreement</h6>
          <p>
            This Agreement constitutes the entire agreement between User and
            Talentou regarding the subject hereof and supersedes all prior or
            contemporaneous understandings and agreements, whether oral or
            written.
          </p>
          <p>
            By signing up for an account with Talentou, the user acknowledges
            that they have read, understood, and agreed to be bound by the terms
            of this Agreement. If the user does not agree to these terms, the
            user should not sign up for an account with Talentou.
          </p>
        </div>
      </Modal>
    </>
  );
};

export default SignUp;
