import React, { useEffect } from "react";
// import "../../Setpassword.css";
import "../SetPassword/Setpassword.css";
import { Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import expired from "../../assets/expiryIcon.png";
import { commonUrl } from "../../global";

const ErrorPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    sessionStorage.clear();
    localStorage.clear();
    // window.location.reload()
    console.log("window.location")
  }, []);

  const backToLogin = () => {
    if (window.location.origin === commonUrl) {
      navigate("/");
    } else {
      navigate("/SignIn");
    }
  };
  return (
    <Layout>
      <div className="email-div">
        <Card className="varification-card m-5">
          <div className="content">
            <img
              style={{ width: "200px", height: "200px" }}
              src={expired}
              alt=""
            />

            <div>
              <p className="main-heading-success">Session Expired!</p>
              <p className="sub-heading">Please login again.</p>

              <button
                // disabled={loadingButton}
                onClick={backToLogin}
                className="login-btn"
              >
                Back to Login
              </button>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default ErrorPage;
