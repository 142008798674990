import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import "./tenant.css";
import plus from "../../../assets/plus.svg";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";

import { API, adminUrl, candidateUrl } from "../../../global";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import toast from "react-hot-toast";
import LayoutMain from "../../Layout/LayoutMain";

const AddTenant = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const [planList, setPlanList] = useState(null);

  // Access the search property of the location object, which contains the query parameters
  const searchParams = new URLSearchParams(location.search);

  // Get the value of the 'id' parameter from the query string
  //   const id = searchParams.get("id");
  const mode = searchParams.get("mode");
  const id = searchParams.get("id");

  useEffect(() => {

    const tenantUrl = adminUrl;
    const candidateDomain = candidateUrl;
    setValue('tenanturl',tenantUrl)
    setValue('candidateUrl',candidateDomain)
    fetch(`${API}/super-admin/plan/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setPlanList(response.result);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {});

    if (mode === "edit") {
      fetchTenantDeatils();
    }

    // eslint-disable-next-line
  }, []);

  const schema = yup
    .object({
      tenantName: yup.string().required("Tenant Name is a required field"),
      tenanturl: yup.string().required("Tenant URL is a required field"),
      candidateUrl: yup.string().required("Candidate URL is a required field"),
      hostName: yup.string().required("Tenant Key is a required field"),
      planId: yup.string().required("Plan is a required field"),
      tenantAddress: yup.string().required("Address is a required field"),
    })
    .required();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchTenantDeatils = () => {
    fetch(`${API}/super-admin/tenant/view?tenant_id=${id}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setValue("tenantName", response?.result?.name);
          setValue("tenantAddress", response?.result?.address);
          setValue("tenanturl", response?.result?.admin_url);
          setValue("hostName", response?.result?.tenant_key);
          setValue("candidateUrl", response?.result?.candidate_url);
          setValue("planId", response?.result?.license_plan_id);
          setValue("tenantDescription", response?.result?.description);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {});
  };

  const onBackClick = () => {
    navigate("/tenant");
  };

  const onSubmit = (data) => {
    setLoading(true);
    const tenantObj = {
      tenant_name: data.tenantName.trim(),
      address: data.tenantAddress.trim(),
      description: data.tenantDescription?.trim(),
      license_plan_id: data.planId,
      admin_url: data.tenanturl.trim(),
      candidate_url: data.candidateUrl.trim(),
      tenant_key: data.hostName.trim(),
    };
    let endPoint = "";
    let msg = "";
    if (mode === "edit") {
      endPoint = "update";
      msg = "updated";
      tenantObj.tenant_id = id;
    } else {
      endPoint = "create";
      msg = "created";
    }

    saveData(tenantObj, endPoint, msg);
  };

  const saveData = (tenantObj, endPoint, msg) => {
    fetch(`${API}/super-admin/tenant/${endPoint}`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tenantObj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          toast.success(`Tenant ${msg} successfully`);
          navigate(-1);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);

        toast.error("Error occurred please try again");
      });
  };

  const handleCancel = () => {
    navigate("/tenant");
  };
  return (
    <>
      
     <LayoutMain index={5}>
     <div className="w-100">
          <div
            className="table-class"
            style={{
              background: "#ffffff",
              margin: "20px",
              padding: "20px",
              borderRadius: "10px",
              // height: "100vh",
            }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Row>
                  <Col>
                    <div className="d-flex justify-content-between w-100">
                      <button className="icon-div" onClick={onBackClick}>
                        <img
                          src={arrowImage}
                          style={{ cursor: "pointer" }}
                          alt="back"
                          className="backImage"
                        />
                      </button>
                      <div className="create-tenant-label">
                        {mode === "edit" ? "Edit" : "Create"} Tenant
                      </div>
                      <div></div>
                    </div>
                  </Col>
                </Row>
                <div style={{ margin: "30px" }}>
                  <Row>
                    <Col>
                      <p className="column-heading">Tenant Details </p>
                      <div className="d-grid">
                        <label className="input-label">
                          Tenant Name <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          placeholder="Enter tenant name"
                          {...register("tenantName")}
                          className="input-style"
                        />
                        <p className="error-text">
                          {errors.tenantName?.message}
                        </p>
                      </div>
                      <div className="d-grid">
                        <label className="input-label">
                          Tenant Key <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          placeholder="Enter tenant key"
                          {...register("hostName")}
                          className="input-style"
                        />
                        <p className="error-text">{errors.hostName?.message}</p>
                      </div>
                      <div className="d-grid">
                        <label className="input-label">
                          Address <span style={{ color: "red" }}>*</span>
                        </label>
                        <textarea
                          placeholder="Enter tenant address"
                          {...register("tenantAddress")}
                          className="textarea-style"
                          type="text"
                        />
                        <p className="error-text">
                          {errors.tenantAddress?.message}
                        </p>
                      </div>
                      <div className="d-grid">
                        <label className="input-label">
                          Tenant Description
                        </label>
                        <input
                          placeholder="Enter tenant description"
                          {...register("tenantDescription")}
                          className="input-style"
                        />
                      </div>
                    </Col>
                    <Col>
                      <p className="column-heading">Host Details</p>
                      <div className="d-grid">
                        <label className="input-label">
                          Tenant URL <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          placeholder="Enter Tenant URL"
                          {...register("tenanturl")}
                          className="input-style"
                        />
                        <p className="error-text">
                          {errors.tenanturl?.message}
                        </p>
                      </div>
                      <div className="d-grid">
                        <label className="input-label">
                          Candidate URL <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          placeholder="Enter Candidate URL"
                          {...register("candidateUrl")}
                          className="input-style"
                        />
                        <p className="error-text">
                          {errors.candidateUrl?.message}
                        </p>
                      </div>
                     
                    </Col>
                  </Row>
                  <Row className="mt-3 w-100 h-25">
                    <p className="column-heading">
                      Plan Details <span style={{ color: "red" }}>*</span>
                    </p>
                    <Col xs={12}>
                      <div className="d-flex flex-column tenant-plan-dropdown">
                        <label className="input-label">Plan</label>
                        <select
                          className="select-style w-25"
                          {...register("planId")}
                        >
                          <option value="none" selected disabled hidden>
                            Select Plan
                          </option>
                          {planList?.map((item) => (
                            <option
                              className="custom-option"
                              value={item._id}
                              key={item._id}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <p className="error-text">{errors.planId?.message}</p>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="action-div">
                      {mode === "edit" ? (
                        <button className="save-button-style">Save</button>
                      ) : (
                        <div>
                          <button
                            className="btn text-white py-1 add-ques-button d-flex align-items-center gap-2"
                            type="submit"
                          >
                            <img
                              src={plus}
                              // className="mr-1"
                              alt="plus"
                              style={{ width: "23px", color: "#fff" }}
                            />
                            <div>
                              {loading ? "Creating..." : "Create Tenant"}
                            </div>
                          </button>
                        </div>
                      )}

                      <button
                        className="cancel-button-style px-2"
                        onClick={() => handleCancel()}
                      >
                        Cancel
                      </button>
                    </Col>
                  </Row>
                </div>
              </div>
            </form>
          </div>
          
        </div>
     </LayoutMain>
     
    </>
  );
};

export default AddTenant;
