import React, { useState, useMemo, useEffect } from "react";
import LayoutMain from "../Layout/LayoutMain";
import "./CompanyList.css";
import CompanyListATS from "./CompanyListATS";
import SearchInput from "../Reusable/SearchInput";
import DataTable from "react-data-table-component";
import { Tooltip } from "react-tooltip";
import edit from "../../../src/assets/edit-purple.svg";
import view from "../../../src/assets/view.svg";
import { useNavigate } from "react-router";
import moment from "moment";
import { API } from "../../global";
import toast from "react-hot-toast";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";

const CompanyList = () => {
  const [filterText, setFilterText] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [filterData, setFilterData] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [companyData, setCompanyData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [modalTitle, setModalTitle] = useState("Add");
  const [filteredItems,setFilteredItems] =  useState(null)
  const rowsPerPage = 10;

  const navigate = useNavigate();

  useEffect(() => {
    getCompanyList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {

    if (filterData === "") {
      setFilterText("");
      getCompanyList();
    }
   
    // eslint-disable-next-line
  }, [filterData]);

  const handleAddCompanyModal = () => {
    setShowAddCompany(false);
  };

  const showEditJob = (act) => {
    setShowAddCompany(true);
    setModalTitle("Edit");
    setCompanyId(act._id);
  };

  const getCompanyList = () => {
    setLoading(true);
    fetch(`${API}/admin/company/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        setLoading(false);
        if (val.success === true) {
          let company = [];
          // eslint-disable-next-line

          val.result.forEach((data) => {
            const obj = {
              _id: data._id,
              name: data.company_name,
              key_tech: data.key_technologies,
              contact_number: data.contact_number,
              address: data.address,
              website_url: data.website_url,
              billing_contact: data.billing_contact,
              created_date: data.created_at,
            };
            company.push(obj);
          });
          const filteredItem = company?.filter(
            (item) =>
              JSON.stringify(item).toLowerCase().indexOf(filterText.toLowerCase()) !==
              -1
          );
          setFilteredItems(filteredItem)
          setCompanyData(company);
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
  };

  const companyNameColumn = (row) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="company-name-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="company-name-column-tooltip"
          data-tooltip-content={`${row?.name}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row?.name}
        </div>
      </>
    );
  };

  const TechColumn = (row) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="company-name-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="company-name-column-tooltip"
          data-tooltip-content={`${row?.key_tech ? row?.key_tech : "none"}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {row?.key_tech ? row?.key_tech : "--"}
        </div>
      </>
    );
  };

  const createdDateColumn = (row) => {
    const formattedDate = moment(row.created_date).format("DD MMMM YYYY");
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="company-name-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div
          data-tooltip-id="company-name-column-tooltip"
          data-tooltip-content={`${formattedDate}`}
          data-tooltip-place="top"
          className="custom-table-cell"
        >
          {formattedDate}
        </div>
      </>
    );
  };

  const actionColumn = (act) => {
    return (
      <>
        <Tooltip
          border="1px solid #7C7B7B"
          id="ques-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div className="d-flex gap-4">
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => showEditJob(act)}
          >
            <img
              src={edit}
              style={{ cursor: "pointer", height: "15px", width: "15px" }}
              alt="delete icon"
              data-tooltip-id="ques-tooltip"
              data-tooltip-content="Edit Company"
              data-tooltip-place="top"
            />
          </button>

          <button
            style={{ border: "none", background: "none" }}
            onClick={() => {
              navigate(`/jobslist/${act._id}/${act.name}`);
            }}
          >
            <img
              src={view}
              alt="edit icon"
              style={{ cursor: "pointer" }}
              data-tooltip-id="ques-tooltip"
              data-tooltip-content="View Jobs"
              data-tooltip-place="top"
            />
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      name: "Company Name",
      cell: (row) => companyNameColumn(row),
      selector: (row) => row.name,
      width: "300px",
      center: true,
      //   sort: true,
      sortable: true,
    },
    {
      name: "Key Technologies",

      cell: (row) => TechColumn(row),
      selector: "key_tech",
      width: "300px",
      center: true,
      // sort: true,
      //   sortable: true,
    },

    {
      name: "Created Date",

      cell: (row) => createdDateColumn(row),
      selector: (row) => row.created_date,
      width: "300px",
      center: true,
      //   sort: true,
      sortable: true,
    },

    {
      name: "Actions",

      cell: (act) => actionColumn(act),

      // width: "300px",
      center: true,
      // sort: true,
      //   sortable: true,
    },
  ];

  

  const handleSearch = () => {
    setFilterText(filterData);
  };
  const subHeaderComponent = useMemo(() => {
    return (
      <SearchInput
        onFilter={(e) => setFilterData(e.target.value)}
        filterText={filterData}
        onSearch={handleSearch}
        enableOnClick={true}
      />
    );

    //eslint-disable-next-line
  }, [filterData]);

  const csvHeaders = [
    { label: "Company_Name", key: "Company_Name" },
    { label: "Key_Technologies", key: "Key_Technologies" },
    { label: "Website_URL", key: "Website_URL" },
    { label: "Billing_Contact", key: "Billing_Contact" },
    { label: "Address", key: "Address" },
    { label: "ContactNumber", key: "ContactNumber" },
  ];
  // Function to generate CSV data from filteredItems
  const generateCsvData = (items) => {
    return items?.map((item) => ({
      // Map your data fields here based on your DataTable columns
      // For example:
      Company_Name: item.name,
      Key_Technologies: item.key_tech,
      Website_URL: item.website_url,
      Billing_Contact: item.billing_contact,
      Address: item.address,
      ContactNumber: item.contact_number,
      // Review: reviewColumn(item),
      // Actions: actionColumn(item),
    }));
  };

  useEffect(() => {
    if (filteredItems) {
      // Call the function when filteredItems change
      const data = generateCsvData(filteredItems);
      setCsvData([...data]);
    }
  
  }, [filteredItems]);

  return (
    <>
      <LayoutMain index={9}>
        {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <div className="w-100">
            <div className="companylist-container">
              <div className="companylist-headers">
                <div>
                  <CompanyListATS
                    csvData={csvData}
                    csvHeaders={csvHeaders}
                    showAddCompany={showAddCompany}
                    modalTitle={modalTitle}
                    setShowAddCompany={setShowAddCompany}
                    setModalTitle={setModalTitle}
                    handleAddCompanyModal={handleAddCompanyModal}
                    companyId={companyId}
                    getCompanyList={getCompanyList}
                  />
                </div>
                <div className="company-list-label ms-2">
                  Company List ({companyData?.length})
                </div>
                <div>{subHeaderComponent}</div>
              </div>
              <div className="table-wrap fs-5 ">
                <DataTable
                  columns={columns.filter((column) => column !== null)}
                  data={filteredItems?.reverse()}
                  subHeaderComponent={subHeaderComponent}
                  customStyles={customStyles}
                  pagination={companyData?.length >= 10 ? true : false}
                  paginationPerPage={rowsPerPage}
                  paginationTotalRows={companyData?.length}
                  paginationDefaultPage={currentPage}
                  onChangePage={(page) => setCurrentPage(page)}
                  //   defaultSortFieldId={2}
                />
              </div>
            </div>
          </div>
        )}
      </LayoutMain>
    </>
  );
};

export default CompanyList;
