export function customStyles() {
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        cursor: "pointer",
        fontSize: "14.5px",
        borderBottom: " 1px solid #eee7f3",
      },
    },
  };

  return customStyles;
}

export function textEditorHeader() {
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ size: [] }],
      ["blockquote", "code-block"],
      ["align", { align: "center" }, { align: "right" }, { align: "justify" }],
      [{ list: "ordered" }, { list: "bullet" }],
    ],
  };

  return modules;
}

export function chooseRoleTour() {
  const chooseRoleTour = [
    {
      role: "Admin",
      permissions:
        "Can do Candidate and Job order uploads, Settings Changes, manage users, view auditlogs etc.",
    },
    {
      role: "Tech Lead",
      permissions:
        "Can create the questionnaire and able to give rating to the candidates.",
    },
    { role: "Recruiter", permissions: "Can schedule interviews." },
  ];

  return chooseRoleTour;
}

// export function jobListTour() {
//   const jobListTour = [
//     {
//       title: "Job List",
//       description: "Here you can view the job order created.",
//     },
//     {
//       title: "Candidate List",
//       description:
//         "Here you can view the list of candidates and schedule interviews.",
//     },
//   ];

//   return jobListTour;
// }

export function stepsAdmin() {
  const stepsAdmin = [
    {
      target: "body",
      title: "Welcome to Talentou",
      content: (
        <div className="tour-alignment">
          Your Secret AI Weapon for Hiring Success!{" "}
        </div>
      ),
      placement: "center",
    },
    {
      target: ".step-1",
      title: "Dashboard",
      content: (
        <div className="tour-alignment">
          Here you can see the live Recruitment Data.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-2",
      title: "Company List",
      content: (
        <div className="tour-alignment">
          Here you can view and create the companies.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-3",
      title: "Job List",
      content: (
        <div className="tour-alignment">
          Here you can view the job order created.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-4",
      title: "Candidate List",
      content: (
        <div className="tour-alignment">
          Here you can view the list of candidates and schedule interviews.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-5",
      title: "Kanban Board",
      content: (
        <div className="tour-alignment">
          Here you can see the status of candidates in board view.
        </div>
      ),
      placement: "right",
    },

    {
      target: ".step-6",
      title: "Settings",
      content: (
        <div className="tour-alignment">
          Where you can upload Job orders and Candidates list, ATS Integration,
          User Management, Setup your Interview settings and Theme settings.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-7",
      title: "Activity Log",
      content: (
        <div className="tour-alignment">
          Here you can see the list of activities carried out by all the users.
        </div>
      ),

      placement: "right",
    },

    {
      target: ".step-8",
      title: "Search",
      content: (
        <div className="tour-alignment">
          Here, you can easily search for any Job order or Candidate.
        </div>
      ),
    },
    {
      target: ".step-9",
      title: "Choose Role",
      content: (
        <div className="tour-alignment">
          <tbody>
            {chooseRoleTour().map((roleData, index) => (
              <tr key={index}>
                <td
                  style={{
                    width: "90px",
                    verticalAlign: "top",
                  }}
                >
                  <b>{roleData.role}</b>-
                </td>
                <td>{roleData.permissions}</td>
              </tr>
            ))}
          </tbody>
        </div>
      ),
    },
    {
      target: ".step-10",
      title: "Take a tour",
      content: (
        <div className="tour-alignment">
          Click the help icon to revisit the guided tour.
        </div>
      ),
    },
  ];

  return stepsAdmin;
}

export function stepsRecruiter() {
  const stepsRecruiter = [
    {
      target: "body",
      title: "Welcome to Talentou",
      content: (
        <div className="tour-alignment">
          Your Secret AI Weapon for Hiring Success!{" "}
        </div>
      ),
      placement: "center",
    },
    {
      target: ".step-1",
      title: "Dashboard",
      content: (
        <div className="tour-alignment">
          Here you can see the live Recruitment Data.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-2",
      title: "Company List",
      content: (
        <div className="tour-alignment">
          Here you can view and create the companies.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-3",
      title: "Job List",
      content: (
        <div className="tour-alignment">
          Here you can view the job order created.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-4",
      title: "Candidate List",
      content: (
        <div className="tour-alignment">
          Here you can view the list of candidates and schedule interviews.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-4",
      title: "Kanban Board",
      content: (
        <div className="tour-alignment">
          Here you can see the status of candidates in board view.
        </div>
      ),
      placement: "right",
    },

    {
      target: ".step-8",
      title: "Search",
      content: (
        <div className="tour-alignment">
          Here, you can easily search for any Job order or Candidate.
        </div>
      ),
    },
    {
      target: ".step-9",
      title: "Choose Role",
      content: (
        <div className="tour-alignment">
          <tbody>
            {chooseRoleTour().map((roleData, index) => (
              <tr key={index}>
                <td
                  style={{
                    width: "90px",
                    verticalAlign: "top",
                  }}
                >
                  <b>{roleData.role}</b>-
                </td>
                <td>{roleData.permissions}</td>
              </tr>
            ))}
          </tbody>
        </div>
      ),
    },
    {
      target: ".step-10",
      title: "Take a tour",
      content: (
        <div className="tour-alignment">
          Click the help icon to revisit the guided tour.
        </div>
      ),
    },
  ];

  return stepsRecruiter;
}

export function stepsTechLead() {
  const stepsTechLead = [
    {
      target: "body",
      title: "Welcome to Talentou",
      content: (
        <div className="tour-alignment">
          Your Secret AI Weapon for Hiring Success!{" "}
        </div>
      ),
      placement: "center",
    },
    {
      target: ".step-1",
      title: "Dashboard",
      content: (
        <div className="tour-alignment">
          Here you can see the live Recruitment Data.
        </div>
      ),
      placement: "right",
    },

    {
      target: ".step-3",
      title: "Job List",
      content: (
        <div className="tour-alignment">
          Here you can view the job order created.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-4",
      title: "Candidate List",
      content: (
        <div className="tour-alignment">
          Here you can view the list of candidates and schedule interviews.
        </div>
      ),
      placement: "right",
    },
    {
      target: ".step-11",
      title: "Manage Questionnaire",
      content: (
        <div style={{ textAlign: "left" }}>
          Here you can create and manage questionnaires for the job order
          respectively.
        </div>
      ),
      placement: "right",
    },

    {
      target: ".step-8",
      title: "Search",
      content: (
        <div className="tour-alignment">
          Here, you can easily search for any Job order or Candidate.
        </div>
      ),
    },
    {
      target: ".step-9",
      title: "Choose Role",
      content: (
        <div className="tour-alignment">
          <tbody>
            {chooseRoleTour().map((roleData, index) => (
              <tr key={index}>
                <td
                  style={{
                    width: "90px",
                    verticalAlign: "top",
                  }}
                >
                  <b>{roleData.role}</b>-
                </td>
                <td>{roleData.permissions}</td>
              </tr>
            ))}
          </tbody>
        </div>
      ),
    },
    {
      target: ".step-10",
      title: "Take a tour",
      content: (
        <div className="tour-alignment">
          Click the help icon to revisit the guided tour.
        </div>
      ),
    },
  ];

  return stepsTechLead;
}

export function reportArray() {
  const reportArray = [
    // {value:"Lifetime",label :"Lifetime",key : 'lifetime' },
    { value: "Today", label: "Today", key: "today" },
    { value: "Yesterday", label: "Yesterday", key: "yesterday" },
    { value: "Last 7 days", label: "Last 7 days", key: "last-7-days" },
    { value: "Last 30 days", label: "Last 30 days", key: "last-30-days" },
  ];

  return reportArray;
}

export function successPageText(type) {
  if (type === "register") {
    const textObj = {
      mainHeading: "Congratulations!",
      subHeading:
        " Your account has been successfully created. Please check your email for further instructions and details.",
    };

    return textObj;
  }

  if (type === "forgotPassword") {
    const textObj = {
      mainHeading: "Success!",
      subHeading:
        "Your password reset request is on its way. Keep an eye on your inbox for instructions to set a new password.",
    };
    return textObj;
  }
}
