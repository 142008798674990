import React from "react";
import "./login/login.css"
import back from '../../assets/back_arrow.svg';

const TermsAndCondition = (props) => {
  const hideModal =()=>{
    props.hide()
  }
  return (
    <div>
      <div className="agreement-body">
        <div className="agreement-body-div">
          <div>
            <button onClick={()=>hideModal()} style={{border:"none",background:"none"}}>
            <img
              src={back}
              style={{ cursor: "pointer" }}
              alt="back"
             
              
            />
            </button>
            
          </div>
          <div className="panel-heading text-center mt-3">
            <h5 className="panel-title">End User License Agreement (EULA)</h5>
          </div>
          <div className=" mt-4">
            This End User License Agreement is a legal contract between you and
            Ignitho Technologies India Pvt Ltd, a company incorporated under the
            laws of India, with its principal place of business at Chennai. This
            Agreement governs your use of the video interview platform provided
            by the Company.
          </div>
          <div>
            By accessing or using the video interview platform, you acknowledge
            that you have read, understood, and agree to be bound by the terms
            and conditions of this Agreement. If you do not agree with any part
            of this Agreement, you must not use the platform.
          </div>
          <br />
          <div className="sub-heads">1. Grant of License</div>
          <div>
            1.1. Subject to your compliance with the terms and conditions of
            this Agreement, the Company grants you a limited, non-exclusive,
            non-transferable, and revocable license to access and use the video
            interview platform solely for the purpose of participating in video
            interviews conducted by potential employers, recruiters, or other
            authorized entities.
          </div>
          <br />
          <div className="sub-heads">
            2. Candidate's Representations and Warranties
          </div>
          <div>
            {" "}
            2.1. By using the platform, you represent and warrant that:{" "}
          </div>
          <div>
            2.1.1. You are of legal age and have the legal capacity to enter
            into this Agreement.
          </div>
          <div>
            2.1.2. The information provided by you during the registration
            process is accurate and complete.
          </div>
          <div>
            2.1.3. You will comply with all applicable laws and regulations
            while using the platform.
          </div>
          <div>
            2.1.4. You will not use the platform for any unlawful or
            unauthorized purposes.
          </div>
          <div>
            2.1.5. You will not interfere with or disrupt the operation of the
            platform or any servers or networks associated with it.
          </div>
          <div>
            2.1.6. You will not attempt to gain unauthorized access to the
            platform or any part of it.
          </div>
          <div>
            2.1.7. You will not use the platform to transmit or store any
            content that is defamatory, abusive, obscene, or otherwise
            objectionable.
          </div>
          <br />
          <div className="sub-heads"> 3. Retention of Videos </div>
          <div>
            3.1. As part of the video interview process, the Company may retain
            the videos of your interviews beyond a single use.
          </div>
          <div>
            3.2. The retention of videos allows the Company to provide features
            such as interview review, analysis, and comparison for the benefit
            of candidates and authorized entities involved in the recruitment
            process.
          </div>
          <div>
            3.3. The Company will take reasonable measures to ensure the
            security and confidentiality of the retained videos.
          </div>
          <br />
          <div className="sub-heads"> 4. Intellectual Property Rights </div>
          <div>
            4.1. All intellectual property rights, including copyrights,
            trademarks, and any other proprietary rights, associated with the
            video interview platform shall remain the property of the Company.
          </div>
          <div>
            4.2. You acknowledge that you do not acquire any ownership rights in
            the platform or its content.
          </div>
          <br />
          <div className="sub-heads"> 5. Limitation of Liability </div>
          <div>
            5.1. To the maximum extent permitted by applicable law, the Company
            shall not be liable for any indirect, incidental, consequential, or
            punitive damages arising out of or in connection with the use of the
            platform.
          </div>
          <div>
            5.2. The Company's total liability, whether in contract, tort
            (including negligence), or otherwise, shall not exceed the amount
            paid by you, if any, for accessing and using the platform.
          </div>
          <br />
          <div className="sub-heads"> 6. Termination </div>
          <div>
            6.1. The Company reserves the right to suspend, terminate, or
            restrict your access to the platform, in whole or in part, without
            notice or liability, for any reason or no reason, including but not
            limited to your violation of this Agreement.
          </div>
          <div>
            6.2. Upon termination, your rights to access and use the platform
            shall immediately cease, and you shall no longer be authorized to
            use the platform.
          </div>
          <br />
          <div className="sub-heads"> 7. Governing Law and Jurisdiction</div>
          <div>
            7.1. This Agreement shall be governed by and construed in accordance
            with the laws of India.
          </div>
          <div>
            7.2. Any disputes arising out of or in connection with this
            Agreement shall be subject to the exclusive jurisdiction of the
            courts of India.
          </div>
          <br />
          <div className="sub-heads"> 8. Miscellaneous</div>
          <div>
            8.1. This Agreement constitutes the entire agreement between you and
            the Company regarding the subject matter herein and supersedes all
            prior or contemporaneous agreements or understandings, whether
            written or oral.
          </div>
          <div>
            8.2. If any provision of this Agreement is held to be invalid or
            unenforceable, such provision shall be severed from this Agreement,
            and the remaining provisions shall remain in full force and effect.
          </div>
          <div>
            8.3. The failure of the Company to enforce any right or provision of
            this Agreement shall not constitute a waiver of such right or
            provision.
          </div>
          <div>
            8.4. This Agreement may be amended or modified by the Company at any
            time, and any such amendments or modifications shall be effective
            upon posting the revised Agreement on the platform.
            <br />
          </div>
          <br />
          <div>
            By using the video interview platform, you acknowledge that you have
            read, understood, and agreed to the terms and conditions of this End
            User License Agreement.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndCondition;
