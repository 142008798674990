import { API } from "../global";

export function getTheme() {
  fetch(`${API}/admin/tenant/get-tenant-details`, {
    method: "GET",
    // headers: {
    //   "Content-Type": "application/json",
    // },
    // body:JSON.stringify({email:localStorage.getItem('emailData')})
  })
    .then((res) => res.json())
    .then((response) => {
      if (response.success) {
        setThemeData(response?.result?.tenant_theme_settings);
      } 
    })
    .catch(() => {});
}

export const setThemeData = (theme) => {
  const themeColor = {
    primaryColor: theme.theme_primary,
    primaryShade1: theme.theme_color1,
    primaryShade2: theme.theme_color2,
    primaryShade3: theme.theme_color3,
    primaryShade4: theme.theme_color4,
    primaryBtn: theme.btn_primary,
    secondaryBtn: theme.btn_secondary,
  };

  localStorage.setItem("themeColors", JSON.stringify(themeColor));
  localStorage.setItem('logoUrl',theme.logo_url ? theme.logo_url :"")


  document.documentElement.style.setProperty('--primary-color', theme.theme_primary);
  document.documentElement.style.setProperty('--primary-shade1',theme.theme_color2);
  document.documentElement.style.setProperty('--primary-shade2', theme.theme_color2);
  document.documentElement.style.setProperty('--primary-shade3', theme.theme_color3);
  document.documentElement.style.setProperty('--primary-shade4', theme.theme_color4);
  document.documentElement.style.setProperty('--primary-btn', theme.btn_primary);
  document.documentElement.style.setProperty('--secondary-btn', theme.btn_secondary);
};
