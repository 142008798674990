import React, { useState, useEffect } from "react";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import DataTable from "react-data-table-component";
import plus from "../../../assets/plus.svg";
import edit from "../../../assets/edit.svg";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router";
import view from "../../../assets/view.svg";
import deleteIcon from "../../../assets/delete.svg";
import { API } from "../../../global";
import Modal from "react-bootstrap/Modal";
import DeletePlan from "./deletePlan";
import { Row } from "react-bootstrap";
import toast from "react-hot-toast";
import LayoutMain from "../../Layout/LayoutMain";

const PlanList = () => {
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [planList, setPlanList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [planId, setPlanId] = useState("");

  const rowsPerPage = 13;
  const navigate = useNavigate();

  const modalStyle = {
    /* modal style for showing on center form the top of screen  */
    marginTop: "-130px",
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#F8EBFF40 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
      },
    },
  };

  useEffect(() => {
    fetchList();

    // eslint-disable-next-line
  }, []);

  const fetchList = () => {
    setLoading(true);
    fetch(`${API}/super-admin/plan/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLoading(false);
          setPlanList(response.result);
        } else {
          setLoading(true);
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {});
  };

  const createTenant = () => {
    navigate("/plans/planDetails?mode=add");
  };

  const onClickView = (data) => {
    navigate(`/plans/planDetails?mode=view&&id=${data._id}`);
  };

  const onClickedit = (data) => {
    navigate(`/plans/planDetails?mode=edit&&id=${data._id}`);
  };

  const calculateSerialNumber = (index) => {
    return (currentPage - 1) * rowsPerPage + index + 1;
  };

  const onDelete = (data) => {
    setDeleteModal(!deleteModal);
    setPlanId(data?._id);
  };

  const onCancel = () => {
    fetchList();
    setDeleteModal(!deleteModal);
  };

  const actionTable = (row) => {
    return (
      <div className="d-flex gap-3">
        <Tooltip
          id="view-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div>
          <button
            onClick={() => onClickView(row)}
            style={{ border: "none", background: "none" }}
          >
            <img
              src={view}
              style={{ cursor: "pointer" }}
              alt="login icon"
              data-tooltip-id="view-tooltip"
              data-tooltip-content="View"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
        <Tooltip
          id="edit-tooltip"
          variant="light"
          border="1px solid #7C7B7B"
          className="candidate-tooltip"
        />
        <div>
          <button
            onClick={() => onClickedit(row)}
            style={{ border: "none", background: "none" }}
          >
            <img
              src={edit}
              style={{ cursor: "pointer" }}
              alt="edit icon"
              data-tooltip-id="edit-tooltip"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
        <Tooltip
          id="delete-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div>
          <button
            onClick={() => onDelete(row)}
            style={{ border: "none", background: "none" }}
          >
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              alt="edit icon"
              data-tooltip-id="delete-tooltip"
              data-tooltip-content="Delete"
              data-tooltip-place="top"
              className="img-fluid"
            />
          </button>
        </div>
      </div>
    );
  };

  const columns = [
    {
      name: "S.No",
      sortable: true,
      selector: (row, i) => i + 1,
      cell: (row, index) => {
        return calculateSerialNumber(index);
      },
      center: true,
    },
    {
      name: "Plans",
      sortable: false,
      selector: (row) => row.name,
      center: true,
    },
    {
      name: "No.of Interviews",
      sortable: false,
      selector: (row) => row.total_interviews,
      center: true,
      style: {
        header: {
          display: "flex",
          justifyContent: "center",
          paddingRight: "55px",
          marginLeft: "10px",
        },
        display: "flex",
        justifyContent: "center",
        paddingRight: "55px",
        marginRight: "10px",
      },
    },
    // {
    //   name: "AI Powered Reviews",
    //   sortable: false,
    //   selector: (row) => row.total_ai_reviews,
    //   center: true,
    // },
    {
      name: "Actions",
      id: "action",
      sortable: false,
      cell: (row) => actionTable(row),
      style: {
        header: {
          display: "flex",
          "justify-content": "flex-end",
          margin: "10px",
        },
        display: "flex",
        "justify-content": "flex-end",
        margin: "10px",
      },
    },
  ];

  return (
    <>
       <LayoutMain index={6}>
       {loading ? (
          <>
            <div className="loader-div-job">
              <ProgressBarComponent />
            </div>
          </>
        ) : (
          <>
            <div className="joblist-details-section">
              <div
                className="can-table-class"
                style={{
                  background: "#ffffff",
                  margin: "20px",
                  padding: "20px",
                  borderRadius: "10px",

                  // height: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <div></div>
                  <div className="d-flex align-items-end justify-content-center w-25">
                    <div className="tenant-list-label">Plans List</div>
                  </div>

                  <button
                    className="btn text-white py-1 add-ques-btn"
                    onClick={createTenant}
                  >
                    <img
                      src={plus}
                      className="mr-1"
                      alt="plus"
                      style={{
                        width: "23px",
                        color: "#fff",
                        marginRight: "7px",
                      }}
                    />
                    Create Plan
                  </button>
                </div>

                <div className="table-wrap fs-5 ">
                  <DataTable
                    columns={columns}
                    data={planList}
                    defaultSortFieldId={2}
                    customStyles={customStyles}
                    onChangePage={(page) => setCurrentPage(page)}
                  />
                </div>
              </div>
              
            </div>
          </>
        )}
       </LayoutMain>
    

      <Modal show={deleteModal} onHide={onDelete} centered style={modalStyle}>
        <Modal.Body>
          <Row>
            <DeletePlan cancel={onCancel} planId={planId} />
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PlanList;
