import React, { useState } from "react";

import DataTableComponent from "../../Reusable/DataTable/DataTableComponent";
import greenEye from "../../../assets/eyeGreen.svg";
import edit from "../../../../src/assets/edit.svg";
import "../Settings.css";
import { API } from "../../../global";
import { Tooltip } from "react-tooltip";
import EmailTemplateEdit from "../EmailTemplate/EmailTemplateEdit";
import { useEffect } from "react";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
//admin/tenant/email-details/get
const EmailTemplate = () => {
  const navigate = useNavigate()

  const [showEdit, setShowEdit] = useState(false);
  const [templateList, setTemplateList] = useState([]);
  const [editData, setEditData] = useState({});
  const [loading,setLoading] = useState(true)

  useEffect(() => {
    fetchData();

    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    fetch(`${API}/admin/tenant/email-details/get`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((list) => {
        setLoading(false)
        if(list.success){
          setTemplateList(list.result.templates);
        }else{
         
          if (list.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(list.error.msg);
          }
        }
        
      });
  };

  const columns = [
    {
      name: "Template Name",
      sortable: false,
      selector: (row) => row.template_name,
    },
    {
      name: "Action",
      id: "action",
      sortable: false,
      cell: (act) => actionTable(act),
      style: {
        header: {
          display: "flex",
          "justify-content": "flex-end",
        },
        display: "flex",
        "justify-content": "flex-end",
      },
    },
  ];

  const actionTable = (act) => {
    return (
      <>
       <Tooltip
          id="emailTemp-setting-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div className="d-flex gap-4">
          <button
            style={{ border: "none", background: "none" }}
            onClick={() => openEdit(act)}
          >
            <img
              src={edit}
              style={{ cursor: "pointer" }}
              alt=""
              className="img-fluid"
              data-tooltip-id="emailTemp-setting-tooltip"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
            />
          </button>
          <button style={{ border: "none", background: "none" }}>
            <img
              src={greenEye}
              alt=""
              style={{ cursor: "pointer" ,opacity:"0.3"}}
              className="img-fluid"
              data-tooltip-id="View"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
            />
          </button>
        </div>
      </>
    );
  };

  const openEdit = (act) => {
    setShowEdit(!showEdit);
    setEditData(act);
  };

  const closeEdit = () => {
    setShowEdit(false);
  };

  return (
    <>
      {loading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <>
          {!showEdit ? (
            <div className="table-wrap table-height fs-5 m-3 ">
              <DataTableComponent
                data={templateList}
                columns={columns}
                title={"Choose a template to view or edit"}
              />
            </div>
          ) : (
            <EmailTemplateEdit closeEdit={closeEdit} editdata={editData} fetchData={fetchData} />
          )}
        </>
      )}
    </>
  );
};

export default EmailTemplate;
