import React from "react";

import { API } from "../../../global";
import { toast } from "react-hot-toast";

import warning from "../../../assets/warning.svg";
import { useState } from "react";
import ProgressBarComponent from '../../Reusable/ProgressBarComponent';
import { useNavigate } from "react-router";

const DeletePlan = (props) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false);
  

  const deleteData = () => {
    setLoading(true);

    fetch(`${API}/super-admin/plan/delete`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ plan_id: props.planId }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        if (res.success) {
          props.cancel();

          toast.success("Plan Deleted Successfully");
        }else{
          if (res.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(res.error.msg);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const cancel =()=>{
    props.cancel();
  }

  return (
    <>
      {loading ? (
        <ProgressBarComponent/>
      ) : (
        <div style={{ display: "flex" }}>
          <div>
            <img style={{ height: "160px" }} src={warning} alt="" />
          </div>
          <div style={{ width: "290px" }}>
            <p style={{ fontSize: "35px" }}>Are You Sure?</p>
            <p style={{ fontSize: "14px" }}>
              Do you really want to delete this record? This process cannot be
              undone
            </p>
            <div style={{ display: "flex", justifyContent: "space-evenly" }}>
              <button onClick={deleteData} className="delete-button" >
                Delete
              </button>
              <button className="cancel-button-delete" onClick={cancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default DeletePlan;
