import React from 'react';

class LighterShadesGenerator extends React.Component {
  generateLighterShades(baseColor, numShades) {
    const baseHSL = this.hexToHSL(baseColor);
    const lighterShades = [];

    for (let i = 1; i <= numShades; i++) {
      const lightness = baseHSL[2] + i * 10; // Adjust the step value as needed
      const lighterHex = this.hslToHex([baseHSL[0], baseHSL[1], lightness]);
      lighterShades.push(lighterHex);
    }

    return lighterShades;
  }

  hexToHSL(hex) {
    // Extract RGB components
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    // Find the maximum and minimum values to calculate lightness and saturation
    const cmax = Math.max(r, g, b);
    const cmin = Math.min(r, g, b);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;

    // Calculate hue
    if (delta !== 0) {
      if (cmax === r) {
        h = ((g - b) / delta) % 6;
      } else if (cmax === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
    }

    h = Math.round(h * 60);
    if (h < 0) {
      h += 360;
    }

    // Calculate lightness and saturation
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l];
  }

  hslToHex([h, s, l]) {
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let r, g, b;

    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    const rgb = [(r + m) * 255, (g + m) * 255, (b + m) * 255];
    return `#${rgb.map(value => Math.round(value).toString(16).padStart(2, '0')).join('')}`;
  }

  render() {
    const baseColor = '#1b4c60';
    const numShades = 10;

    const lighterShades = this.generateLighterShades(baseColor, numShades);
  

    return (
      <div>
        <div style={{ backgroundColor: baseColor, padding: '10px', marginBottom: '10px' }}>
          Base Color
        </div>
        {lighterShades.map((shade, index) => (
          <div key={index} style={{ backgroundColor: shade, padding: '10px', marginBottom: '10px' }}>
            Lighter Shade {index + 1}
          </div>
        ))}
      </div>
    );
  }
}

export default LighterShadesGenerator;



export const  lightenColor =(color, percent)=> {
  // Convert hex to RGB
  let r = parseInt(color.slice(1, 3), 16);
  let g = parseInt(color.slice(3, 5), 16);
  let b = parseInt(color.slice(5, 7), 16);

  // Convert RGB to HSL
  r /= 255;
   g /= 255;
   b /= 255;
  let max = Math.max(r, g, b), min = Math.min(r, g, b);
  let h, s, l = (max + min) / 2;

  if (max === min) {
      h = s = 0; // achromatic
  } else {
      let d = max - min;
      s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
      switch (max) {
          case r: h = (g - b) / d + (g < b ? 6 : 0); break;
          case g: h = (b - r) / d + 2; break;
          case b: h = (r - g) / d + 4; break;
          default: // Handle unexpected values of max
                   break;
      }
      h /= 6;
  }

  // Adjust lightness
  l = Math.min(1, Math.max(0, l + percent / 100));

  // Convert HSL to RGB
  let hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
  };
  let q = l < 0.5 ? l * (1 + s) : l + s - l * s;
  let p = 2 * l - q;
  let red = hue2rgb(p, q, h + 1 / 3) * 255;
  let green = hue2rgb(p, q, h) * 255;
  let blue = hue2rgb(p, q, h - 1 / 3) * 255;

  // Convert RGB to hex
  const hex = "#" + ((1 << 24) + (Math.round(red) << 16) + (Math.round(green) << 8) + Math.round(blue)).toString(16).slice(1);

  return hex;
}


export const colorGenerator =(color)=>{
  const generateLighterShades =(baseColor, numShades) =>{
    const baseHSL = hexToHSL(baseColor);
    const lighterShades = [];

    for (let i = 1; i <= numShades; i++) {
      const lightness = baseHSL[2] + i * 10; // Adjust the step value as needed
      const lighterHex = hslToHex([baseHSL[0], baseHSL[1], lightness]);
      lighterShades.push(lighterHex);
    }

    return lighterShades;
  }

  const hexToHSL=(hex)=> {
    // Extract RGB components
    const r = parseInt(hex.slice(1, 3), 16) / 255;
    const g = parseInt(hex.slice(3, 5), 16) / 255;
    const b = parseInt(hex.slice(5, 7), 16) / 255;

    // Find the maximum and minimum values to calculate lightness and saturation
    const cmax = Math.max(r, g, b);
    const cmin = Math.min(r, g, b);
    const delta = cmax - cmin;

    let h = 0;
    let s = 0;
    let l = 0;

    // Calculate hue
    if (delta !== 0) {
      if (cmax === r) {
        h = ((g - b) / delta) % 6;
      } else if (cmax === g) {
        h = (b - r) / delta + 2;
      } else {
        h = (r - g) / delta + 4;
      }
    }

    h = Math.round(h * 60);
    if (h < 0) {
      h += 360;
    }

    // Calculate lightness and saturation
    l = (cmax + cmin) / 2;
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return [h, s, l];
  }

 const  hslToHex = ([h, s, l]) =>{
    s /= 100;
    l /= 100;

    const c = (1 - Math.abs(2 * l - 1)) * s;
    const x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    const m = l - c / 2;

    let r, g, b;

    if (h >= 0 && h < 60) {
      r = c;
      g = x;
      b = 0;
    } else if (h >= 60 && h < 120) {
      r = x;
      g = c;
      b = 0;
    } else if (h >= 120 && h < 180) {
      r = 0;
      g = c;
      b = x;
    } else if (h >= 180 && h < 240) {
      r = 0;
      g = x;
      b = c;
    } else if (h >= 240 && h < 300) {
      r = x;
      g = 0;
      b = c;
    } else {
      r = c;
      g = 0;
      b = x;
    }

    const rgb = [(r + m) * 255, (g + m) * 255, (b + m) * 255];
    return `#${rgb.map(value => Math.round(value).toString(16).padStart(2, '0')).join('')}`;
  }


  const baseColor = color.hex;
    const numShades = 4;

    const lighterShades = generateLighterShades(baseColor, numShades);
    return lighterShades
}