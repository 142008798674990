import React from 'react'
import { useState } from 'react';
import { BsShareFill } from "react-icons/bs";
import Modal from 'react-bootstrap/Modal';
import { API } from '../../global';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

const ShareRecording = () => {
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const roleId = localStorage.getItem("current_role_key");
    const [email,setEmail] = useState([])
    const [emailError,setEmailError] = useState('')
    const [loading,setLoading] = useState(false)

    const handleClose = () => setShow(false);
    const handleShareLink = () => {
     
    //  if (!validateEmail(email).includes(false)) {
       setEmailError("");
       const obj = {
         emails: email,
         interview_id: localStorage.getItem("interview-id"),
       };
       setLoading(true)
       fetch(`${API}/admin/interview/share/recording-link`, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
           authorization: `Bearer ${localStorage.getItem("auth_token")}`,
         },
         body: JSON.stringify(obj),
       })
         .then((res) => res.json())
         .then((response) => {
           if (response.success) {
             setShow(false)
             setEmail([])
             setLoading(false)
             toast.success("Link sent Successfully");            
           } else {
             
             if (response.error.code ===  421) {
              navigate("/error");
            }else{
              toast.error(response.error.msg);
            }
             setLoading(false)
           }
         })
         .catch(() => {
          setLoading(false)
         });
    //  } else {
    //    setEmailError("Invalid Email Address");
    //  }
    };

    // const validateEmail = (email) => {
    //   let error =[]
    //   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //   // eslint-disable-next-line
    //   email.map((emailData)=>{
    //     return  error.push(emailRegex.test(emailData))
    //   })
    //   return error
    // };

    const handleChange =(e)=>{
     setEmail(e.target.value.split(','))
    }


  return (
    <div>
       {roleId &&     <button type='button' className='share-button' onClick={() => setShow(true)}>
        <BsShareFill/> Share Recording</button>}       
        <Modal show={show} onHide={handleClose}
        backdrop="static" animation={false} centered>
        <Modal.Header closeButton >
          <Modal.Title style={{ color: '#600097' }}>Share Recording</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='pt-2 d-flex justify-content-between gap-2'>
            <input
              placeholder="Enter Email Address"
              type="text"
              onChange={(e)=>handleChange(e)}
            //   value={interviewViewLink}
            //   onChange={(e) => setInterviewViewLink(e.target.value)}
              className="form-control"
            />
           
            <button type="button" className='copy-link-btn px-3 py-0' disabled={loading} onClick={() => handleShareLink()}>
              {loading ? "Sending.." : "Send"}
            </button>
          </div>
          <p style={{fontSize:'14px',color:'red'}}>{emailError}</p>
          <p className="share-link-info">To share with multiple email addresses, insert commas between the email IDs.</p>

          {/* </div> */}
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default ShareRecording