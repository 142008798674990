import React, { useState, useEffect } from "react";
import "../Settings.css";
import DataTableComponent from "../../Reusable/DataTable/DataTableComponent";
import edit from "../../../../src/assets/edit.svg";
import mailBox from "../../../assets/MessageBox.svg";
import deleteIcon from "../../../assets/delete.svg";
// import Modal from "react-bootstrap/Modal";
import Container from "react-bootstrap/Container";
import { API } from "../../../global";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import UserManagementForm from "./UserManagementForm";
import DeleteUser from "./DeleteUser";
import toast from "react-hot-toast";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import { Tooltip } from "react-tooltip";
import { useNavigate } from "react-router";

const customStyles = {
  modal: {
    maxWidth: "600px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};
const customStylesDelete = {
  modal: {
    maxWidth: "525px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};

const UserManagement = (props) => {

  const navigate = useNavigate()

  const [openEditModal, setOpenEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [userData, setuserData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [type, setType] = useState("");
  const [roleData, setRoleData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const fetchData = () => {
    fetch(`${API}/admin/tenant/user/role-list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then(async (response) => {
        if (response.success) {
          await setRoleData(response.result);
          await fetchUserData(response.result);
         
        }else{
         
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const fetchUserData = (roleData) => {
    fetch(`${API}/admin/tenant/user/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setLoading(false);
          let tempList = [];
          // eslint-disable-next-line array-callback-return
          response.result?.map((item) => {
            let roleName = [];
            roleData.forEach((obj1) => {
              const matchingObj = item.user_roles.find(
                (obj2) => obj1._id === obj2.role_id
              );

              if (matchingObj) {
                roleName.push(obj1.description);
              }
            });
            const obj = {
              type: roleName.join(", "),
              name: item.name,
              email: item.email,
              _id: item._id,
            };
            tempList.push(obj);
          });
          setuserData(tempList);
        }else{
          
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {});
  };

  const actionTable = (row) => {
    return (
      <>
        <Tooltip
          id="user-management-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div className="d-flex gap-2">
          <button style={{ border: "none", background: "none" }}>
            <img
              src={mailBox}
              alt=""
              style={{ opacity: "0.3" }}
              className="img-fluid grayscale"
              data-tooltip-id="user-management-tooltip"
              data-tooltip-content="send reset password email"
              data-tooltip-place="top"
            />
          </button>

          <button
            onClick={() => editData(row)}
            style={{ border: "none", background: "none" }}
          >
            <img
              src={edit}
              style={{ cursor: "pointer" }}
              alt=""
              className="img-fluid"
              data-tooltip-id="user-management-tooltip"
              data-tooltip-content="Edit"
              data-tooltip-place="top"
            />
          </button>
          <button
            onClick={() => deleteData(row)}
            style={{ border: "none", background: "none" }}
          >
            <img
              src={deleteIcon}
              style={{ cursor: "pointer" }}
              alt=""
              className="img-fluid"
              data-tooltip-id="user-management-tooltip"
              data-tooltip-content="Delete"
              data-tooltip-place="top"
            />
          </button>
        </div>
      </>
    );
  };

  const columns = [
    {
      name: "Name",
      sortable: false,
      selector: (row) => row.name,
      center: true,
    },
    {
      name: "Email",
      sortable: false,
      selector: (row) => row.email,
      center: true,
    },
    {
      name: "Roles",
      sortable: false,
      selector: (row) => row.type,
      center: true,
    },
    {
      name: "Actions",
      id: "action",
      sortable: false,
      cell: (row) => actionTable(row),
      style: {
        header: {
          display: "flex",
          "justify-content": "flex-end",
          margin: "10px",
        },
        display: "flex",
        "justify-content": "flex-end",
      },
    },
  ];

  const addUserModal = () => {
    setSelectedData([]);
    setOpenEditModal(!openEditModal);
    setType("add");
  };

  const editData = (row) => {
    setSelectedData(row);
    setOpenEditModal(!openEditModal);
    setType("edit");
  };

  const deleteData = (row) => {
    setSelectedData(row);
    setDeleteModal(!deleteModal);
  };

  const closeModalEdit = () => {
    setOpenEditModal(false);
  };

  const closeModalDelete = () => {
    setDeleteModal(false);
  };

  return (
    <>
      {loading && userData.length === 0 ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <Container fluid>
          <div className="table-wrap fs-5 m-3 ">
            {userData.length !== 0 && (
              <DataTableComponent /* common component for data table */
                data={userData}
                columns={columns}
                title={"Add or remove team members"}
                addUser={addUserModal}
                action={true}
                titlebtn={"Add User"}
              />
            )}
          </div>
        </Container>
      )}

      <Modal
        open={openEditModal}
        onClose={editData}
        styles={customStyles}
        center
      >
        <UserManagementForm
          roleData={roleData}
          type={type}
          selectedData={selectedData}
          closeMOdal={closeModalEdit}
          resetData={fetchData}
        />
      </Modal>

      {/*  modal for delete user*/}

      <Modal
        open={deleteModal}
        onClose={deleteData}
        styles={customStylesDelete}
        center
      >
        <DeleteUser
          resetData={fetchData}
          closeMOdal={closeModalDelete}
          selectedData={selectedData}
        />
      </Modal>

      {/*  modal for delete user*/}
      {/* <Modal show={deleteModal} onHide={deleteData} centered style={modalStyle}>
        <Modal.Body>
          <Row>
            <DeleteUser
              resetData={fetchData}
              closeMOdal={closeModalDelete}
              selectedData={selectedData}
            />
          </Row>
        </Modal.Body>
      </Modal> */}
    </>
  );
};

export default UserManagement;
