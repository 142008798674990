import React from "react";

const CommonCard = (props) => {
  return (
    <div>
      <div className="customCard">
        <div className="cardBody">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div className={props.styelName}>
              <p style={{ margin: "10px" }}>{props.title}</p>
            </div>
          </div>
          <div style={{padding:'20px'}}>
            <p className="cardSubTitle">{props.interview}</p>
            <div className="titleDiv">
              <div className="cardBackgroundTitle">
                <p style={{ margin: "10px" }}>{props.aiReview}</p>
              </div>
            </div>
            <p className="cardSubTitle">{props.fraudDetection}</p>
            <div className="titleDiv">
              <div className="cardBackgroundTitle">
                <p style={{ margin: "10px" }}>{props.support}</p>
              </div>
            </div>
            {/* <p className="cardSubTitle">Support</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommonCard;
