import React, { useState } from "react";
import CandidateImportList from "./CandidateList-Import-Template.xlsx";
// import import1 from "../../../src/assets/import-job.svg";
// import { GoFile } from "react-icons/go";
// import { IoClose } from "react-icons/io5";
// import BarLoader from "react-spinners/BarLoader";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { API } from "../../global";
import { useNavigate } from "react-router";
import ImportPopup from "../Reusable/ImportPopup/ImportPopup";

const ImportCandidate = ({
  handleImportJobModal,
  handleClose,
  fileInputRef,
  setImportJobFile,
  setImportFile,
  importJobFile,
  importfile,
  jobId,
}) => {
  const navigate = useNavigate();
  const [fileError, setFileError] = useState("");
  const [loading, setLoading] = useState(false);
  const [bgFile, setBgFile] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");

  const importObj = {
    title: "Candidate",
    fileError: fileError,
    loading: loading,
    bgFile: bgFile,
    btnDisable: btnDisable,
    fileInputRef: fileInputRef,
    responseMsg: responseMsg,
    FileName: "CandidateList-Import-Template",
  };

  const handleBrowseClick = () => {
    fileInputRef.current.click();
    // simulateFileUpload();
  };

  
  const checkDuplicateCandidateId = (candidate) => {
    const candidateIds = candidate.map((can) => can.ats_candidate_id);
    const duplicates = candidateIds.filter(
      (name, index, self) => self.indexOf(name) !== index
    );

    if (duplicates.length > 0) {
      setBgFile(true);
      setResponseMsg("Duplicate candidate id found");
    } else {
      handlePostCandidateList(candidate);
    }
  };

  const handleFileChange = (e) => {
    setResponseMsg("");
    setBgFile(false);
    setBtnDisable(false);

    const file = e.target.files[0];
    const fileName = file.name
      .split(".")[0]
      .replace(/[0-9()[\]{}]/g, "")
      .trim();

    const allowedTypes = [
      "application/vnd.ms-excel",
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    ]; // Allowed file types
    const maxSize = 10 * 1024 * 1024; // 10MB
    if (file && allowedTypes.includes(file.type) && file.size <= maxSize) {
      setFileError("");
      setImportFile(true);
      setImportJobFile(file);

      if (fileName === "CandidateList-Import-Template") {
        const reader = new FileReader();
        reader.onload = (event) => {
          const data = event.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const json = XLSX.utils.sheet_to_json(worksheet, {
            defval: "",
          });

          if (json.length !== 0) {
            const template = [
              "CandidateId",
              "Name",
              "Email",
              "Skills",
              "Experience",
              "Location",
              "ContactNumber",
            ];
            const column = Object.keys(json[0]);

            const dataCount = column.length;

            const isMatch = column.every(
              (columnName, index) => columnName === template[index]
            );

            if (isMatch && dataCount === 7) {
              // Function to check if the required fields are missing and return the missing fields
              const getMissingFields = ({
                CandidateId,
                Name,
                Email,
                Skills,
              }) => {
                const missingFields = [];
                if (!CandidateId) missingFields.push("CandidateID");
                if (!Name) missingFields.push("Name");
                if (!Email) missingFields.push("Email");
                if (!Skills) missingFields.push("Skills");
                return missingFields;
              };

              // Transform and validate the candidates into an array of objects
              (() => {
                const allMissingFields = new Set(); // Use a Set to collect unique missing fields

                const transformedCandidates = json.reduce((acc, candidate) => {
                  const missingFields = getMissingFields(candidate);

                  if (missingFields.length === 0) {
                    const transformedCandidate = {
                      ats_candidate_id: candidate.CandidateId,
                      name: candidate.Name,
                      email: candidate.Email,
                      skills: candidate.Skills,
                      experience: candidate.Experience,
                      location: candidate.Location,
                      contact_number: candidate.ContactNumber,
                    };

                    acc.push(transformedCandidate); // Push the valid candidate object into the array
                  } else {
                    // Add missing fields to the Set
                    missingFields.forEach((field) =>
                      allMissingFields.add(field)
                    );
                  }

                  return acc;
                }, []);

                // Check if there are any valid transformed candidates
                if (transformedCandidates.length > 0) {
                  // Handle the array of transformed candidates
                  checkDuplicateCandidateId(transformedCandidates)
                  // handlePostCandidateList(transformedCandidates);
                }

                // Show a consolidated error message if there are missing fields
                if (allMissingFields.size > 0) {
                  const missingFieldsArray = Array.from(allMissingFields); // Convert Set to array
                  setResponseMsg(
                    `The required ${
                      missingFieldsArray.length > 1 ? "fields" : "field"
                    } "${missingFieldsArray.join(", ")}" ${
                      missingFieldsArray.length > 1 ? "are" : "is"
                    }  missing; please complete it and re-upload.`
                  );
                }
              })();
            } else {
              toast.error("Template column name is mismatched");
              setImportFile(false);
            }
          } else {
            setResponseMsg("Uploaded file has no data");
          }
        };

        if (e.target.files && e.target.files.length > 0) {
          reader.readAsArrayBuffer(e.target.files[0]);
        } else {
          toast.error("Please upload the file");
        }
      } else {
        setImportFile(false);
        toast.error("Please ensure the file name matches the template.");
      }
    } else {
      setImportFile(false);
      setFileError("Please select a valid file (CSV or XLSX) under 10MB.");
    }

  };

  const isObjectEmpty = (objectName) => {
    return Object.keys(objectName).length === 0;
  };
  

  const handlePostCandidateList = (newCandidateList) => {
    let  currentJobId = localStorage.getItem('currentJob');

    setLoading(true);
    setBtnDisable(true);
    fetch(`${API}/admin/candidate/import`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({
        candidates: newCandidateList,
        job_order_id: currentJobId ? currentJobId : jobId,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setBtnDisable(false);
        if (res.success) {
          if (isObjectEmpty(res.result.upsertedIds)) {
            setResponseMsg("Error in uploading file");
            return;
          }
          setResponseMsg("File uploaded successfully");
          // fileInputRef.current.value = "";
          //     handleImportJobModal();

          setBgFile(true);
        } else {
          // toast.error("File format is mismatched");
          setBgFile(false);
          if (res.error.code === 421) {
            navigate("/error");
          } else {
            setResponseMsg(res.error.msg);
          }
          //   setCandidateListloading(false);
        }
      });
  };

  return (
    <div>
      <ImportPopup
        handleFileChange={handleFileChange}
        handleBrowseClick={handleBrowseClick}
        handleImportModal={handleImportJobModal}
        handleClose={handleClose}
        importFile={importJobFile}
        importfile={importfile}
        importObj={importObj}
        ImportList={CandidateImportList}
      />
    </div>
  );
};

export default ImportCandidate;
