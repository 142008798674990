import React from 'react'
import DataTable from 'react-data-table-component';

const CandidateListTable = ({isRowDisabled,canlist,columns,user_role,filteredItems,customStyles,searchComponent,rowDisabledCriteria,setCandidates,ExpandedComponent,rowsPerPage,currentPage,setCurrentPage}) => {

  const paginationOptions = {
    rowsPerPageText: 'Rows per page:',
    rangeSeparatorText: 'of',
    // selectAllRowsItem: true,
    // selectAllRowsItemText: '',
    paginationRowsPerPageOptions: [10, 20, 50, 100,200,500],
  };

  return (
    <>
      <DataTable
        selectableRows={user_role !== "talentou_tech_lead" ? true : false}
        columns={columns.filter((column) => column !== null)}
        data={filteredItems}
        defaultSortFieldId={2}
        customStyles={customStyles}
        onSelectedRowsChange={(e) => {
          setCandidates(e.selectedRows);
        }}
        paginationRowsPerPageOptions={
          paginationOptions.paginationRowsPerPageOptions
        }
        searchComponent={searchComponent}
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        pagination={canlist.length >= 10 ? true :false}
        paginationPerPage={10}
        paginationTotalRows={canlist.length}
        paginationDefaultPage={currentPage}
        onChangePage={(page) => setCurrentPage(page)}
        selectableRowDisabled={isRowDisabled}
        paginationComponentOptions={paginationOptions}
        paginationServer={false}
      />
    </>
  );
}

export default CandidateListTable