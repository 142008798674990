import React, { useRef } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { toast } from "react-hot-toast";
import * as XLSX from "xlsx";
import { API } from "../../../global";
import jobOrder from "../../../assets/job-order.svg";
import candidate from "../../../assets/candidate-white.svg";
import ATSCard from "./ATSCard";
import { useState } from "react";
import JobOrderList from "./JobOrder-template.xlsx";
import CandidateList from "./CandidateList-template.xlsx";
import { useNavigate } from "react-router";

const ATSExcelUpload = () => {
  const navigate = useNavigate();

  const [jobListloading, setJobListLoading] = useState(false);
  const [candidateListloading, setCandidateListloading] = useState(false);
  const JobListInputRef = useRef(null);
  const CandidateListInputRef = useRef(null);

  // const getTenantID = JSON.parse(localStorage.getItem("atsData"));

  // const tenantId = getTenantID && getTenantID[0].tenant_id;

  const handleUploadJoblistClick = () => {
    JobListInputRef.current.click();
  };

  const handleJobList = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;

        const workbook = XLSX.read(data, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        const json = XLSX.utils.sheet_to_json(worksheet, {
          defval: "",
        });

        if (json.length !== 0) {
          const template = [
            "Joborder_Id",
            "Company",
            "Jobrole",
            "Skills",
            "Location",
          ];

          const column = Object.keys(json[0]);

          const dataCount = column.length;

          const isMatch = column.every(
            (columnName, index) => columnName === template[index]
          );

          console.log(isMatch, "isMatch");
          console.log(column, "dataCount");

          if (isMatch && dataCount === 5) {
            // Function to check if the required fields are missing and return the missing fields
            const getMissingFields = ({
              Joborder_Id,
              Company,
              Jobrole,
              Skills,
            }) => {
              const missingFields = [];
              if (!Joborder_Id) missingFields.push("Joborder_Id");
              if (!Company) missingFields.push("Company");
              if (!Jobrole) missingFields.push("Jobrole");
              if (!Skills) missingFields.push("Skills");

              return missingFields;
            };

            // Transform and validate the job orders into an array of objects
            (() => {
              const allMissingFields = new Set(); // Use a Set to collect unique missing fields

              const transformedJobOrders = json.reduce((acc, jobOrder) => {
                const missingFields = getMissingFields(jobOrder);

                if (missingFields.length === 0) {
                  const transformedJobOrder = {
                    joborder_id: jobOrder.Joborder_Id,
                    company_name: jobOrder.Company,
                    job_role: jobOrder.Jobrole,
                    skills: jobOrder.Skills,
                    location: jobOrder.Location,
                  };

                  acc.push(transformedJobOrder); // Push the valid job order object into the array
                } else {
                  // Add missing fields to the Set
                  missingFields.forEach((field) => allMissingFields.add(field));
                }

                return acc;
              }, []);

              // Check if there are any valid transformed job orders
              if (transformedJobOrders.length > 0) {
                // Handle the array of transformed job orders
                handlePostJoblist(transformedJobOrders);
              }

              // Show a consolidated error message if there are missing fields
              if (allMissingFields.size > 0) {
                const missingFieldsArray = Array.from(allMissingFields); // Convert Set to array
                toast.error(
                  `The required ${
                    missingFieldsArray.length > 1 ? "fields" : "field"
                  } "${missingFieldsArray.join(", ")}" ${
                    missingFieldsArray.length > 1 ? "are" : "is"
                  } missing; please complete it and re-upload.`
                );
              }
            })();
          } else {
            toast.error("Template column name is mismatched");
          }
        } else {
          toast.error("Upload file has no data");
        }
      };
      if (e.target.files && e.target.files.length > 0) {
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        toast.error("Please upload the file");
      }
    } else {
      toast.error("Please upload the file");
    }
  };

  const handlePostJoblist = (newjoborder) => {
    setJobListLoading(true);
    fetch(`${API}/admin/tenant/ats-settings/import-jobs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ job_orders: newjoborder }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success("File uploaded successfully");
          JobListInputRef.current.value = "";
          setJobListLoading(false);
        } else {
          // toast.error("File format is mismatched");

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
          setJobListLoading(false);
        }
      });
  };

  const handleUploadCandidateClick = () => {
    CandidateListInputRef.current.click();
  };

  const handleCandidateList = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const data = event.target.result;
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet, {
          defval: "",
        });
        if (json.length !== 0) {
          const template = [
            "JoborderId",
            "CandidateId",
            "Name",
            "Email",
            "Skills",
            "Experience",
            "Location",
            "ContactNumber",
          ];
          const column = Object.keys(json[0]);

          const dataCount = column.length;

          const isMatch = column.every(
            (columnName, index) => columnName === template[index]
          );

          if (isMatch && dataCount === 8) {
            const getMissingFields = ({
              JoborderId,
              CandidateId,
              Name,
              Email,
              Skills,
            }) => {
              const missingFields = [];
              if (!CandidateId) missingFields.push("CandidateID");
              if (!JoborderId) missingFields.push("JoborderId");
              if (!Name) missingFields.push("Name");
              if (!Email) missingFields.push("Email");
              if (!Skills) missingFields.push("Skills");
              return missingFields;
            };

            (() => {
              const allMissingFields = new Set(); // Use a Set to collect unique missing fields

              const transformedCandidates = json.reduce((acc, candidate) => {
                const missingFields = getMissingFields(candidate);

                if (missingFields.length === 0) {
                  const transformedCandidate = {
                    joborder_id: candidate.JoborderId,
                    candidate_id: candidate.CandidateId,
                    name: candidate.Name,
                    email: candidate.Email,
                    skills: candidate.Skills,
                    experience: candidate.Experience,
                    location: candidate.Location,
                    contact_number: candidate.ContactNumber,
                  };

                  acc.push(transformedCandidate); // Push the valid candidate object into the array
                } else {
                  // Add missing fields to the Set
                  missingFields.forEach((field) => allMissingFields.add(field));
                }

                return acc;
              }, []);

              // Check if there are any valid transformed candidates
              if (transformedCandidates.length > 0) {
                // Handle the array of transformed candidates
                handlePostCandidateList(transformedCandidates);
              }

              // Show a consolidated error message if there are missing fields
              if (allMissingFields.size > 0) {
                const missingFieldsArray = Array.from(allMissingFields); // Convert Set to array
                toast.error(
                  `The required ${
                    missingFieldsArray.length > 1 ? "fields" : "field"
                  } "${missingFieldsArray.join(", ")}" ${
                    missingFieldsArray.length > 1 ? "are" : "is"
                  }  missing; please complete it and re-upload.`
                );
              }
            })();
          } else {
            toast.error("Template column name  is mismatched");
          }
        } else {
          toast.error("Upload file has no data");
        }
      };

      if (e.target.files && e.target.files.length > 0) {
        reader.readAsArrayBuffer(e.target.files[0]);
      } else {
        toast.error("Please upload the file");
      }
    } else {
      toast.error("Please upload the file");
    }
  };

  const handlePostCandidateList = (newCandidateList) => {
    setCandidateListloading(true);
    fetch(`${API}/admin/tenant/ats-settings/import-candidates`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ candidates: newCandidateList }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.success) {
          toast.success("File uploaded successfully");
          CandidateListInputRef.current.value = "";
          setCandidateListloading(false);
        } else {
          // toast.error("File format is mismatched");

          if (res.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(res.error.msg);
          }
          setCandidateListloading(false);
        }
      });
  };

  return (
    <div>
      <Row>
        <Col md={6}>
          <ATSCard
            title="Job Order"
            icon={jobOrder}
            description=" Download the job order template, fill it with your data,
                      and effortlessly upload for seamless spreadsheet
                      integration"
            inputRef={JobListInputRef}
            handleUploadClick={handleUploadJoblistClick}
            handleList={handleJobList}
            loading={jobListloading}
            fileRef={JobOrderList}
            fileName="Job-Order-Template"
          />
        </Col>
        <Col md={6}>
          <ATSCard
            title="Candidate List"
            icon={candidate}
            description="Download the candidate list template, fill it with your
                      data, and effortlessly upload for seamless spreadsheet
                      integration"
            inputRef={CandidateListInputRef}
            handleUploadClick={handleUploadCandidateClick}
            handleList={handleCandidateList}
            loading={candidateListloading}
            fileRef={CandidateList}
            fileName="Candidate-List-Template"
          />
        </Col>
      </Row>
    </div>
  );
};

export default ATSExcelUpload;
