

export function getThemeColor() {
  const themeColor = localStorage.getItem("themeColors");
  return JSON.parse(themeColor);
}

export function toSentenceCase(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}


export async function  sessionTimeOut(){
  sessionStorage.clear();
  localStorage.clear();
  // window.location.reload();
  // history.push('/');
}

export  function checkAtsData (atsKey){
  const atsKeyData = JSON.parse(localStorage.getItem('atsData'))
  const atsData = atsKeyData.filter((itm)=>itm.ats_key === atsKey)
  if(atsData.length !== 0){
    return atsData[0].status
  }else{
    return 'inactive'
  }
}