import { SET_TENANT_DETAILS } from "../Actions/tenantDetailsActions";

const initialState = {
  authData: null,
};

const tenantDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TENANT_DETAILS:
      return {
        ...state,
        authData: action.payload,
      };
    default:
      return state;
  }
};

export default tenantDetailsReducer;
