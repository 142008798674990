export const blobUrl = "https://ihadev.blob.core.windows.net";
export const adminUrl = "https://hiringadmin.talentou.com";
export const candidateUrl = "https://hiring.talentou.com";
export const commonUrl = "https://hiringadmin.talentou.com";
export const captchaSiteKey = "6Lc5kpkpAAAAAJ_Qk947aOsjOLqcni9FuqUytf8C";
// export const commonUrl = "http://localhost:3001";
//export const API = "https://talentou-api.ignithocloud.com/api/v3"
function apiGenerator() {
  // get host_name
  const hostName = window.location.hostname;
  if (hostName === "localhost") {
    return "https://hiring-api.talentou.com/api/v3";

    // return "http://localhost:5555/api/v3";
  } else {
    // Split the string into an array of words
    const domainKeys = hostName.split(".");
    // Ensure there are at least three words
    if (domainKeys.length >= 3) {
      // Get the first word and append "-api"
      const tenantKey = domainKeys[0].replace("admin", "") + "-api";
      // Concatenate the modified first word with the other two words
      // Output the result
      console.log(tenantKey);
      return (
        "https://" + tenantKey + "." + domainKeys.slice(1).join(".") + "/api/v3"
      );
    } else {
      console.log("Sorry, invalid tenant, please contact Talentou Support");
      return;
    }
  }
}

export const API = apiGenerator();
