import React from "react";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import Nav from "../Reusable/Navbar/Nav";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router";

const LayoutMain = ({ children, index }) => {
  const navigate = useNavigate();
  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  return (
    <div>
      <div className="position-relative">
        <Nav handleTour={handleTour} />
        <div className="container-fluid text-center d-flex  m-0 p-0">
          <div className="sidemenu-section">
            <SideMenu menuIndex={index} />
          </div>

          <div className="joblist-details-section">
            {children}
            <div>
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LayoutMain;
