import React from "react";
import { Dropdown } from "react-bootstrap";
import arrow from "../../assets/dropdown-arrow-grey.svg";
import "../Candidatelist/Candidatelist.css";

const CanDropdown = ({
  selectedValue,
  handleSelectedValue,
  width,
  DropdownArr,
  defaultText,
  backgroundColor,
  color,
  border,
  img,
  disabled,
  loading,
  disableDefault,
}) => {
  // console.log("DropdownArr",DropdownArr)
  // console.log("selectedValue",selectedValue)
  return (
    <div>
      {/* <Tooltip
        id="dropdown-tooltip"
        variant="light"
        className="candidate-tooltip"
      /> */}
      <Dropdown onSelect={handleSelectedValue}>
        <Dropdown.Toggle
          disabled={disabled}
          className=" d-flex justify-content-between align-items-center"
          style={{
            width: width,
            backgroundColor: backgroundColor,
            color: color,
            fontWeight: "500",
            border: `1px solid ${border} `,
          }}
          // data-tooltip-id="dropdown-tooltip"
          // data-tooltip-content={selectedValue || defaultText}
          // data-tooltip-place="top"
        >
          {img && (
            <img style={{ marginRight: "15px" }} src={img} alt="" width={15} />
          )}
          <div
            style={{
              width: 150,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
            }}
          >
            {loading ? <>Loading...</> : <>{selectedValue || defaultText}</>}
          </div>{" "}
          <img src={arrow} alt="" width={15} />
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{
            width: width,
            backgroundColor: "#F8EBFF",
            overflow: "hidden",
            textOverflow: "ellipsis",
            height: DropdownArr?.length > 5 ? "200px" : "auto",
            overflowY: DropdownArr?.length > 5 ? "scroll" : "hidden",
          }}
        >
          {DropdownArr?.length === 0 ? (
            <Dropdown.Item
              style={{ width: width }}
              className="no-records-found"
            >
              No Records found
            </Dropdown.Item>
          ) : (
            <>
              {selectedValue && !disableDefault && (
                <Dropdown.Item
                  style={{
                    width: width,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  eventKey={defaultText}
                >
                  {defaultText}
                </Dropdown.Item>
              )}
              {DropdownArr?.map((arr, i) => (
                <Dropdown.Item
                  style={{
                    width: width,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                  eventKey={arr.value}
                >
                  {arr.label}
                </Dropdown.Item>
              ))}
            </>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default CanDropdown;
