import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import toast from "react-hot-toast";
import { API } from "../../global";
import { useNavigate } from "react-router";

export const UpdateAlign = ({ recordingDetails, editDisable, setLoading }) => {
  const navigate = useNavigate()

  const [aligned, setAligned] = useState(false);
  const [interviewScheduleId, setInterviewScheduleId] = useState(null);

  useEffect(() => {
    getAlignedStatus();
    // eslint-disable-next-line
  }, []);

  const getAlignedStatus = () => {
    setAligned(recordingDetails?.aligned);

    const getScheduleID = recordingDetails?._id;

    setInterviewScheduleId(getScheduleID);
  };

  const updateAlignedStatus = (aligned, interviewScheduleId) => {
    setLoading(true);
    if (editDisable) {
      toast.error("Sorry you are not allowed to rate this interview");
    } else {
      setAligned(aligned);

      const updatedAligned = {
        interview_schedule_id: interviewScheduleId,
        aligned: aligned,
      };
      fetch(`${API}/admin/interview/review/update-aligned`, {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(updatedAligned),
      })
        .then((res) => res.json())
        .then((val) => {
          if (val.success) {
            toast.success("Aligned Updated Successfully");
          } else {
           
            if (val.error.code ===  421) {
              navigate("/error");
            }else{
              toast.error(val.error.msg);
            }
          }
        });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="aligned-inner-container">
        <div className="text-center overall-rating-label pt-2">Aligned</div>
        <Dropdown className="aligned-dropdown-main text-center py-2">
          <Dropdown.Toggle
            disabled={editDisable}
            id="dropdown-basic"
            className="aligned-dropdown"
          >
            {aligned === true ? "Yes" : aligned === false ? "No" : "Select"}
          </Dropdown.Toggle>
          <Dropdown.Menu style={{ width: "60px" }}>
            <Dropdown.Item
              value="Yes"
              onClick={(e) => updateAlignedStatus(true, interviewScheduleId)}
              className="aligned-dropdown-item"
            >
              Yes
            </Dropdown.Item>
            <Dropdown.Item
              value="No"
              onClick={(e) => updateAlignedStatus(false, interviewScheduleId)}
              className="aligned-dropdown-item"
            >
              No
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};
