import React, { useState } from 'react';
import chaticon from '../../assets/Chat Bot Icon.svg';
import './Footer.css';

const Footer = () => {
  const hideChatbot = true;
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const togglePopover = () => {
    setIsPopoverVisible(!isPopoverVisible);
  };

  return (
    <footer className="text-center footer-copyrights rounded-2">
      <p style={{ padding: '10px 0 10px', margin: '0' }}><small>Copyrights © 2024 Talentou. All Rights Reserved. v3.1.0</small></p>
      {!hideChatbot &&
        (
          <div className="popover_wrapper">
      
          <button style={{border:"none",background:"none"}} onClick={togglePopover}>
          <img
            src={chaticon}
            alt=""
            style={{ width: '30px', cursor: 'pointer' }}
            
          />
          </button>
          
          {isPopoverVisible && (
            <div className="popover_content z-3">
              <div className="popover_title mt-2">Chat with Talbot</div>
               <div className="px-4 mb-2" style={{ fontSize: '14px' }}>
                <iframe
                  src="https://webchat.botframework.com/embed/Talentou-QnA-bot?s=Rtj16dKzh94.Oq4xGGnWxOAkBFH537d_pZ30HrS0y34sKYjUGtvvaKU"
                  style={{ height: '500px' }} title='Chat Bot'
                ></iframe>
              </div>
            </div>
          )}
        </div>
        )}
     
    </footer>
  );
};

export default Footer;
