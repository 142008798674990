import React, { useEffect, useRef, useState } from "react";
import DownloadCsv from "./DownloadCsv";
import plus from "../../../src/assets/add-purple.svg";
import pluswhite from "../../../src/assets/add-white.svg";
// import arrowImage from "../../assets/icons/ArrowIcon.svg";
import Modal from "react-responsive-modal";
import { Dropdown } from "react-bootstrap";
// import { useNavigate } from "react-router";
import "./Candidatelist.css";
import CreateCandidate from "./CreateCandidate";
import ImportCandidate from "./ImportCandidate";
import { Tooltip } from "react-tooltip";
import { checkAtsData } from "../Functions/commonFunctions";

const CandidateATS = ({
  filteredItems,
  modalTitle,
  setModalTitle,
  setShowAddJob,
  showAddJob,
  handleAddJobModal,
  jobId,
  getCandidateDetails,
  singleCanDetails,
  setSingleCanDetails,
  setJob_role,
  changeJobId,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredAdd, setIsHoveredAdd] = useState(false);
  const [showImportJob, setShowImportJob] = useState(false);
  const user_role = localStorage.getItem("current_role_key");
  const [atsStatus, setAtsStatus] = useState("");

  // const navigate = useNavigate();

  useEffect(() => {
    const activeAts = checkAtsData("talentou_ats");
    setAtsStatus(activeAts);
    localStorage.setItem("atsStatus", activeAts);
  }, []);

  const handleHoverAdd = () => {
    setIsHoveredAdd(true);
  };

  const handleMouseLeaveAdd = () => {
    setIsHoveredAdd(false);
  };
  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  const fileInputRef = useRef(null);
  const [importJobFile, setImportJobFile] = useState(null);
  const [importfile, setImportFile] = useState(false);

  const handleClose = () => {
    setImportJobFile("");
    setImportFile(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the value of the file input element
    }

    // fileInputRef.current.click();
  };
  const handleImportJobModal = () => {
    setShowImportJob(false);
    handleClose();
    getCandidateDetails();
  };

  const customStylesAddJob = {
    modal: {
      maxWidth: "630px", // Set your custom width here
    },
    closeIcon: {
      display: "none", // Hide the close icon
    },
  };

  const handleAddCandidate = () => {
    setShowAddJob(true);
    setModalTitle("Add");
    setSingleCanDetails("");
  };

  return (
    <>
      <Tooltip
        id="can-talentou-ats-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <div className="d-flex gap-2" style={{ width: "80%" }}>
        {/* <div className="icon-div me-2">
          <img
            src={arrowImage}
            style={{ cursor: "pointer" }}
            onClick={() => navigate(-1)}
            alt="back"
            className="backImage"
          />
        </div> */}
        {user_role !== "talentou_tech_lead" ? (
          <>
            {" "}
            {atsStatus === "active" && (
              <div
                className="candidate-csv-download"
                onMouseEnter={handleHoverAdd}
                onMouseLeave={handleMouseLeaveAdd}
              >
                {" "}
                <>
                  <div className="d-flex align-item-center justify-content-center ">
                    <Dropdown>
                      <Dropdown.Toggle
                        className="d-flex align-items-center gap-2 m-0"
                        style={{
                          background: "none",
                          border: "none",
                          fontSize: "13px",
                          padding: "9px 5px",
                        }}
                        data-tooltip-id="can-talentou-ats-tooltip"
                        data-tooltip-content="Add Candidate"
                        data-tooltip-place="top"
                      >
                        <img
                          src={isHoveredAdd ? pluswhite : plus}
                          alt="plus"
                          width={12}
                        />
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        style={{
                          width: "125px",
                          backgroundColor: "#F8EBFF",
                          marginTop: "12px",
                          marginLeft: "-10px",
                          padding: "0px",
                        }}
                      >
                        <Dropdown.Item
                          onClick={() => {
                            handleAddCandidate();
                          }}
                          style={{ fontSize: "13px" }}
                        >
                          Add Candidate
                        </Dropdown.Item>
                        <Dropdown.Item
                          style={{ fontSize: "13px" }}
                          onClick={() => setShowImportJob(true)}
                        >
                          Import Candidate
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </>
              </div>
            )}
            <div
              className="candidate-csv-download"
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}
            >
              <DownloadCsv
                filteredItems={filteredItems}
                isHovered={isHovered}
              />
            </div>
          </>
        ) : (
          ""
        )}

        <Modal
          styles={customStylesAddJob}
          open={showAddJob}
          onClose={() => handleAddJobModal()}
          center
        >
          <CreateCandidate
            modalTitle={modalTitle}
            handleAddJobModal={handleAddJobModal}
            jobId={jobId}
            getCandidateDetails={getCandidateDetails}
            singleCanDetails={singleCanDetails}
            changeJobId={changeJobId}
          />
        </Modal>
        <Modal
          styles={customStylesAddJob}
          open={showImportJob}
          onClose={() => handleImportJobModal()}
          center
        >
          <ImportCandidate
            jobId={jobId}
            handleImportJobModal={handleImportJobModal}
            handleClose={handleClose}
            fileInputRef={fileInputRef}
            setImportJobFile={setImportJobFile}
            setImportFile={setImportFile}
            importJobFile={importJobFile}
            importfile={importfile}
            getCandidateDetails={getCandidateDetails}
          />
        </Modal>
      </div>
    </>
  );
};

export default CandidateATS;
