import React, { useState } from "react";
import DataTable from "react-data-table-component";
import CallGreen from "../../assets/contact-green.svg";
import MailGreen from "../../assets/mail-green.svg";
// import mailIcon from "../../assets/mailbox.svg";
import LocationGreen from "../../assets/location-green.svg";
import AddBlue from "../../assets/add-blue.svg";
import { ThreeDots } from "react-loader-spinner";
import "./Candidatelist.css";
import { Tooltip } from "react-tooltip";
import { API } from "../../global";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";

const CustomNoDataComponent = () => (
  <div style={{ padding: "20px", textAlign: "center" }}>
    <p>At the moment, there are no candidates available from AI suggestions.</p>
  </div>
);

const AISuggestion = ({
  handleCopyClick,
  aiCandidateData,
  jobId,
  refreshData,
}) => {
  const [loader, setLoader] = useState(false);
  const [loadingIndex, setLoadingIndex] = useState(0);
  const navigate = useNavigate();

  const contactColumn = (row) => {
    return (
      <>
        <Tooltip
          id="email-column-tooltip"
          variant="light"
          className="candidate-tooltip"
        />
        <div className="d-flex gap-2">
          <div className="">
            <button style={{background:'none',border:'none'}} onClick={() => handleCopyClick("+91 987654321")}>
            <img
              src={CallGreen}
              alt="phone-no"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${row?._id ? "+91 987654321" : ""}`}
              data-tooltip-place="top"
              className="action-icon"
             
              width={25}
            />
            </button>
          
          </div>

          <div className="">
          <button style={{background:'none',border:'none'}} onClick={() => handleCopyClick(row?.email)}>
          <img
              src={MailGreen}
              alt="email"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${row?.email ? row?.email : ""}`}
              data-tooltip-place="top"
              className=" action-icon"
              width={25}
            />
          </button>
            
          </div>
          <div className="">
          <button style={{background:'none',border:'none'}} >
          <img
              src={LocationGreen}
              alt="location"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content={`${row?.state ? row?.state : ""}`}
              data-tooltip-place="top"
              className=" action-icon"
            />
          </button>
          
          </div>
        </div>
      </>
    );
  };

  const addToList = (row, index) => {
    const atachObj = {
      candidate_id: row._id,
      job_order_id: jobId,
    };
    setLoader(true);
    setLoadingIndex(index);

    fetch(`${API}/admin/candidate/attach-to-job-order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(atachObj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          toast.success("Added to job successfully.");
          setLoader(false);
          refreshData();
        } else {
          setLoader(false);
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg); 
          }
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
        setLoader(false);
      });
  };

  const actionColumn = (row, index) => {
    return (
      <>
        <Tooltip
          id="email-column-tooltip"
          variant="light"
          className="candidate-tooltip"
          border="1px solid #7C7B7B"
        />
        <div className="d-flex gap-2">
          <button
            onClick={() => addToList(row, index)}
            style={{ background: "none", border: "none" }}
          >
            {loader && loadingIndex === index ? (
              <>
                <ThreeDots
                  visible={true}
                  height="10"
                  width="200"
                  color="#77dd77"
                  radius="9"
                  ariaLabel="three-dots-loading"
                  wrapperClass=""
                />
              </>
            ) : (
              <img
                src={AddBlue}
                alt="phone-no"
                data-tooltip-id="email-column-tooltip"
                data-tooltip-content="Add to Job List"
                data-tooltip-place="top"
                className="action-icon"
              />
            )}
          </button>

          {/* <div className="">
            <img
              src={mailIcon}
              alt="location"
              data-tooltip-id="email-column-tooltip"
              data-tooltip-content="Generate Link"
              data-tooltip-place="top"
              className="cursor-pointer p-1"
            />
          </div> */}
        </div>
      </>
    );
  };

  // const ratingElement = (count) => {
  //   const defaultStars = 5;

  //   const newArray = Array.from({ length: defaultStars }, (_, index) => (
  //     <>
  //       <Tooltip
  //         id="AIrating-column-tooltip"
  //         variant="light"
  //         className="candidate-tooltip"
  //       />
  //       <span
  //         key={index}
  //         className={`fa fa-star ${index < count.relevancy ? "checked" : ""}`}
  //         data-tooltip-id="AIrating-column-tooltip"
  //         data-tooltip-content={`Overall Rating: ${count.relevancy}`}
  //         style={{ cursor: "pointer" }}
  //       ></span>
  //     </>
  //   ));

  //   return newArray;
  // };
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#77DD77 0% 0% no-repeat padding-box",
      },
    },
    rows: {
      style: {
        backgroundColor: "#77DD77 0% 0% no-repeat padding-box",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        fontSize: "14.5px",
        borderBottom: "1px solid #eee7f3",
      },
    },
  };
  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      center: true,
    },
    {
      name: "Skills",
      selector: (row) => row.skills.join(","),
      center: true,
      width: "330px",
    },
    {
      name: "Contact",
      center: true,
      cell: (row) => contactColumn(row),
    },
    // {
    //   name: "Relevancy",
    //   center: true,
    //   selector: (row) => ratingElement(row),
    // },
    {
      name: "Action",
      center: true,
      cell: (row, index) => actionColumn(row, index),
    },
  ];

  return (
    <div className="ai-suggestion">
      <div className="ai-suggestion-title">AI Suggestions</div>
      <div className="table-wrap-ai-suggestion">
        <DataTable
          noDataComponent={<CustomNoDataComponent />}
          columns={columns}
          data={aiCandidateData.candidates}
          customStyles={customStyles}
        />
      </div>
    </div>
  );
};

export default AISuggestion;
