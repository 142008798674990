import React, { useEffect, useState } from "react";
import "./Settings.css";
import Nav from "../Reusable/Navbar/Nav";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import Footer from "../Footer/Footer";
import InterviewSettings from "./InterviewSettings";
import EmailTemplate from "./EmailTemplate/EmailTemplate";
import UserManagement from "./UserManagement/UserManagement";
import AuthenticationSetting from "./AuthSettings/AuthenticationSetting.jsx";
import License from "./License";
import ATSIntegration from "./ATSIntegration/ATSIntegration";
import ThemeSettings from "./ThemeSettings/ThemeSettings.jsx";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { Container, Row, Col } from "react-bootstrap";
import PolicyDocument from "./Policy Document/PolicyDocument.jsx";
import { useNavigate, useParams } from "react-router";

const Setting = () => {
  const navigate = useNavigate();
  const { type } = useParams();
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    setActiveTab(Number(type));

    // eslint-disable-next-line
  }, []);

  
  //   fetch(`${API}/admin/tenant/setting/all/${decoded.tenant_id}`, {
  //     method: "GET",
  //     headers: {
  //       authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //     },
  //   })
  //     .then((res) => res.json())
  //     .then((response) => {
  //       if (response.success) {
  //         setTenantData(response.data);
  //       }else{
          
  //         if (response.error.code ===  421) {
  //           navigate("/error");
  //         }else{
  //           toast.error(response.error.msg);
  //         }
  //       }
  //     })
  //     .catch(() => {});
  // };

  const handleTabClick = (index, value, event) => {
    setActiveTab(index);
    navigate(`/settings/${event.target.getAttribute("value")}`);
  };

  const handleTour = () => {
    localStorage.setItem("tourGuid", true);
    navigate("/admin-dashboard");
  };

  return (
    <>
      <Nav handleTour={handleTour} />
      <div className="d-flex">
        <div className="sidemenu-section">
          <SideMenu menuIndex={4} />
        </div>
        <Container
          fluid
          className="m-0 p-0 d-flex justify-content-between flex-column"
        >
          <div className="tabs">
            <Row className="m-0 p-0 h-100">
              <Col xs={12}>
                <Tabs
                  defaultIndex={activeTab}
                  onSelect={(index, value, event) =>
                    handleTabClick(index, value, event)
                  }
                >
                  <TabList>
                    <Tab
                      value={"interview_settings"}
                      style={{ textAlign: "center" }}
                    >
                      Interview Settings
                    </Tab>
                    <Tab
                      value={"theme_settings"}
                      style={{ textAlign: "center" }}
                    >
                      Theme Settings
                    </Tab>
                    <Tab
                      value={"email_template"}
                      style={{ textAlign: "center" }}
                    >
                      E-Mail Templates
                    </Tab>
                    <Tab value={"ats"} style={{ textAlign: "center" }}>
                      ATS Integration
                    </Tab>
                    <Tab
                      value={"authentication_settings"}
                      style={{ textAlign: "center" }}
                    >
                      Authentication Settings
                    </Tab>
                    <Tab
                      value={"user_management"}
                      style={{ textAlign: "center" }}
                    >
                      User Management
                    </Tab>
                    <Tab
                      value={"policy_document"}
                      style={{ textAlign: "center" }}
                    >
                      Policy Document
                    </Tab>
                    <Tab value={"license"} style={{ textAlign: "center" }}>
                      License
                    </Tab>
                  </TabList>

                  <TabPanel>
                    <InterviewSettings />
                  </TabPanel>
                  <TabPanel>
                    <ThemeSettings  />
                  </TabPanel>
                  <TabPanel>
                    <EmailTemplate />
                  </TabPanel>
                  <TabPanel>
                    <ATSIntegration />
                  </TabPanel>
                  <TabPanel>
                    <AuthenticationSetting />
                  </TabPanel>
                  <TabPanel>
                    <UserManagement/>
                  </TabPanel>
                  <TabPanel>
                    <PolicyDocument />
                  </TabPanel>
                  <TabPanel>
                    <License />
                  </TabPanel>
                </Tabs>
              </Col>
            </Row>
          </div>
          <div>
            <Row className="p-0 m-0">
              <Footer />
            </Row>
          </div>
        </Container>

      </div>
    </>
  );
};

export default Setting;
