import React, { useEffect, useState } from "react";
import pluswhite from "../../../src/assets/add-white.svg";
import toast from "react-hot-toast";
import { API } from "../../global";
import { useNavigate } from "react-router";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";

const CreateCompany = (props) => {
  const { modalTitle, companyId, handleAddCompanyModal, getCompanyList } =
    props;
  const navigate = useNavigate();
  const [companyName, setCompanyName] = useState("");
  const [companyError, setCompanyError] = useState("");
  const [websiteURL, setWebsiteURL] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [billcontactNo, setBillContactNo] = useState("");
  const [keyTech, setKeyTech] = useState("");
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState("");
  const [initialLoading, setInitialLoading] = useState(false);
  const [contactError, setContactError] = useState("");
  const [billcontactError, setBillContactError] = useState("");

  useEffect(() => {
    if (modalTitle.toLowerCase() === "edit") {
      viewCompanyDetails();
      setInitialLoading(true);
    }
    //eslint-disable-next-line
  }, []);

  const validation = () => {
    let error = true;
    if (!companyName) {
      setCompanyError("Company name is a required field.");
      error = false;
    }

    return error;
  };

  const handleChange = (e) => {
    if (e.target.id === "companyName") {
      setCompanyName(e.target.value);
      setCompanyError("");
    }

    if (e.target.id === "websiteURL") {
      setWebsiteURL(e.target.value);
    }

    if (e.target.id === "contactNo") {
      setContactNo(e.target.value);
      setContactError("");
    }

    if (e.target.id === "billingContact") {
      setBillContactNo(e.target.value);
      setBillContactError("");
    }

    if (e.target.id === "skills") {
      setKeyTech(e.target.value);
    }

    if (e.target.id === "address") {
      setAddress(e.target.value);
    }
  };

  const onSubmit = (data) => {
    const CompanyObj = {
      company_name: companyName,
      website_url: websiteURL,
      contact_number: contactNo,
      billing_contact: billcontactNo,
      key_technologies: keyTech,
      address: address,
    };

    // console.log(CompanyObj, "CompanyObj");

    // if (companyId) {
    //   jobObj.company_id = companyId;
    // } else {
    //   jobObj.company_name = value;
    // }
    function isValidPhoneNumber(phoneNumber) {
      const indianFormat = /^(?:\+?91)?[6789]\d{9}$/;
      const usFormat = /^\(\d{3}\) \d{3}-\d{4}$/;
      return indianFormat.test(phoneNumber) || usFormat.test(phoneNumber);
    }

    // Initialize flag to track validity of both phone numbers
    let isValid = true;

    if (CompanyObj.contact_number !== "") {
      // Validate contact number
      if (!isValidPhoneNumber(contactNo)) {
        setLoading(false);
        setContactError("Please enter a valid phone number");
        // Update flag if contact number is invalid
        isValid = false;
      }
    }

    if (CompanyObj.billing_contact !== "") {
      // Validate billing contact number
      if (!isValidPhoneNumber(billcontactNo)) {
        setLoading(false);
        setBillContactError("Please enter a valid phone number");
        // Update flag if billing contact number is invalid
        isValid = false;
      }
    }

    // Check if either contact number or billing contact number is invalid
    if (!isValid) {
      return;
    }
    // Continue with further processing if both phone numbers are valid
    setLoading(true);
    let path = "";
    let message = "";
    if (modalTitle.toLowerCase() === "edit") {
      path = "update";
      CompanyObj.company_id = companyId;
      message = "updated";
    } else {
      path = "create";
      message = "created";
    }
    if (!validation()) {
      setLoading(false);
    } else {
      saveData(path, message, CompanyObj);
    }
  };

  const saveData = (path, message, CompanyObj) => {
    fetch(`${API}/admin/company/${path}`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(CompanyObj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          toast.success(`Company ${message} successfully`);

          handleAddCompanyModal();
          getCompanyList();
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
        toast.error("Error occurred please try again");
      });
  };

  const viewCompanyDetails = () => {
    fetch(`${API}/admin/company/view?company_id=${companyId}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        setInitialLoading(false);

        if (val.success === true) {
          console.log(val.result, "val.result");
          setCompanyName(val?.result?.company_name);
          setWebsiteURL(val?.result?.website_url);
          setContactNo(val?.result?.contact_number);
          setBillContactNo(val?.result?.billing_contact);
          setKeyTech(val?.result?.key_technologies);
          setAddress(val?.result?.address);
        } else {
          // setInitialLoading(false);

          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);
      });
  };

  return (
    <>
      {initialLoading ? (
        <>
          <div className="loader-div-createATS">
            <ProgressBarComponent />
          </div>
        </>
      ) : (
        <div className="d-flex flex-column gap-3">
          <h5>{modalTitle} Company</h5>
          <div className="d-flex justify-content-between w-100 gap-3">
            <div className="w-100">
              <label for="companyName" className="fw-bold title-ats">
                Company Name
              </label>
              <br />
              <div className="d-flex gap-2">
                <input
                  type="text"
                  className="form-control w-100  input-add-job job-order-create"
                  id="companyName"
                  placeholder="Enter company name"
                  onChange={(e) => handleChange(e)}
                  value={companyName}
                />
              </div>
              <p style={{ color: "red" }}>{companyError}</p>
            </div>

            <div className="w-100">
              <label for=" websiteURL" className="fw-bold title-ats">
                Website URL
              </label>
              <br />
              <div className="d-flex gap-2">
                <input
                  type="text"
                  className="form-control w-100  input-add-job job-order-create"
                  id="websiteURL"
                  placeholder="Enter website URL"
                  onChange={(e) => handleChange(e)}
                  value={websiteURL}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 gap-3">
            <div className="w-100">
              <label for="contactNo" className="fw-bold title-ats">
                Contact
              </label>
              <br />

              <div className="d-flex gap-2">
                <input
                  type="text"
                  className="form-control w-100  input-add-job job-order-create"
                  id="contactNo"
                  placeholder="Enter phone number"
                  onChange={(e) => handleChange(e)}
                  value={contactNo}
                />
              </div>
              <p style={{ color: "red" }}>{contactError}</p>
            </div>

            <div className="w-100">
              <label for="billingContact" className="fw-bold title-ats">
                Billing Contact
              </label>
              <br />
              <div className="d-flex gap-2">
                <input
                  type="text"
                  className="form-control w-100  input-add-job job-order-create"
                  id="billingContact"
                  placeholder="Enter phone number"
                  onChange={(e) => handleChange(e)}
                  value={billcontactNo}
                />
              </div>
              <p style={{ color: "red" }}>{billcontactError}</p>
            </div>
          </div>
          <div>
            <label for="skills" className="fw-bold title-ats">
              Key Technologies (Please insert a comma between the skills.)
            </label>
            <div className="d-flex gap-2">
              <textarea
                type="text"
                className="form-control job-text-area w-100 input-add-job ml-3"
                id="skills"
                placeholder="Enter key technology"
                onChange={(e) => handleChange(e)}
                value={keyTech}
              />
            </div>
          </div>
          <div>
            <label for="address" className="fw-bold title-ats">
              Address
            </label>
            <div className="d-flex gap-2">
              <textarea
                type="text"
                className="form-control job-text-area w-100 input-add-job ml-3"
                id="address"
                placeholder="Enter address"
                onChange={(e) => handleChange(e)}
                value={address}
              />
            </div>
            {/* <p style={{ color: "red" }}>{skillError}</p> */}
          </div>

          <div className="action-div-add-job">
            <button
              className="cancel-button-style"
              onClick={() => {
                handleAddCompanyModal();
                // handleCancel();
              }}
            >
              Cancel
            </button>
            <button
              className="btn text-white py-1 add-ats-company-button pt-1"
              type="submit"
              onClick={onSubmit}
            >
              {modalTitle === "Edit" ? (
                <span className="mt-1">
                  {/* Update */}
                  {loading ? "Loading.." : "Update"}
                </span>
              ) : (
                <>
                  {loading ? (
                    "Loading.."
                  ) : (
                    <>
                      <img src={pluswhite} alt="plus" width={12} />
                      <span className="ms-2 mt-1">Add Company</span>
                    </>
                  )}
                </>
              )}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CreateCompany;
