import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import crossIcon from "../../../src/assets/closeIcon.svg";

const TextInput = ({
  placeHolder,
  answerData,
  handleKeyDown,
  removeKeyWord,
  handleInputChangeKeyWord,
  answerKeyword,
  id,
  row,
  type,
}) => {
  return (
    <div>
      <Container fluid>
        <Row>
          <Col className="p-0">
            <div
              className={
                type === "talentou-ats"
                  ? "skill-input-ats-div fs-6"
                  : "border border-2 skill-input-div fs-6"
              }
            >
              <div>
                <ul className="skill-input-ul p-0 ">
                  {answerData && answerData.length > 0
                    ? answerData
                        .filter((data) => data !== "") // Remove empty strings
                        .map((data, i) => (
                          <li className="skill-input-li" key={i}>
                            {data}
                            <button
                              onClick={() => removeKeyWord(i)}
                              style={{ background: "none", border: "none" }}
                            >
                              <span>
                                <img
                                  className="keyword-remove"
                                  alt=""
                                  src={crossIcon}
                                />
                              </span>
                            </button>
                          </li>
                        ))
                    : ""}
                </ul>
              </div>

              <textarea
                type="text"
                placeholder={placeHolder}
                className="skill-input"
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={(e) => handleInputChangeKeyWord(e)}
                value={answerKeyword}
                rows={row}
                id={id}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TextInput;
