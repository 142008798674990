import React from "react";
import { Col, Row } from "react-bootstrap";
import "./ConfirmModal.css";


const ConfirmModal = (props) => {
  const closeModal=()=>{
    props.handleCloseModal()
  }

  const handleConfirm=()=>{
    props.handleConfirm()
  }
  return (
    <div>
      <Row>
        <Col>
          <div>
            <img
              style={{ height: "141px", width: "141px", margin: "25px" }}
              src={props.img}
              alt=""
            />
          </div>
        </Col>
        <Col>
          <div style={{margin:'20px',textAlign:'center'}}>
            <p style={{ fontSize: "35px" }}>Are You Sure?</p>
            <p style={{ fontSize: "14px" }}>{props.subTitle}</p>
            <div style={{ display: "flex", justifyContent: "space-evenly",gap:"10px" }}>
              <button disabled={props.loading} onClick={()=>handleConfirm()} className="confirm-btn">{props.loading ? 'Loading...' :props.buttonTitle}</button>
              <button onClick={()=>closeModal()} className="cancel-button-delete">Cancel</button>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ConfirmModal;
