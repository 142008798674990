import React, { useState, useCallback, useEffect } from "react";
import download from "../../../src/assets/Purple csv- Download.svg";
import downloadwhite from "../../../src/assets/White csv- Download.svg";
import { CSVLink } from "react-csv";
import { Tooltip } from "react-tooltip";

const DownloadCsv = ({ filteredItems, isHovered }) => {
  const [csvData, setCsvData] = useState([]);
  const csvHeaders = [
    { label: "Name", key: "Name" },
    { label: "Skills", key: "Skills" },
    { label: "Email", key: "Email" },
    { label: "Experience", key: "Experience" },
    { label: "ATSStatus", key: "ATSStatus" },
    { label: "Interview Status", key: "Status" },
    { label: "Location", key: "Location" },
    { label: "Review", key: "Review" },
    { label: "Overall", key: "Overall" },
  ];


  const generateCsvData = useCallback((items) => {
    return items.map((item) => ({
      Name: item.name,
      Skills: item.skills.join(", "),
      Email: item.email,
      Experience: item.experience,
      ATSStatus: item.ats_status ? item.ats_status : "-",
      Status: item.latest_interview ? item.latest_interview.status : "",
      Location: item.location,
      Review: item.latest_interview?.aligned
        ? item.latest_interview?.aligned === true
          ? "Selected"
          : "Not Selected"
        : "",
      Overall: item.latest_interview
        ? item.latest_interview?.overall_rating
        : "",
    }));
  }, []); // Empty dependency array since it doesn't depend on any external variables

  useEffect(() => {
    // Only update the state if there is a change in filteredItems
    const data = generateCsvData(filteredItems);

    setCsvData((prevData) => {
      // Use a function in setState to avoid re-renders causing an infinite loop
      if (JSON.stringify(prevData) !== JSON.stringify(data)) {
        return [...data];
      }
      return prevData;
    });
  }, [generateCsvData, filteredItems]);
  return (
    <>
      <Tooltip
        id="can-talentou-ats-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <CSVLink
        data={csvData}
        headers={csvHeaders}
        filename={"candidates.csv"}
        style={{
          color: "white",
          textDecoration: "none",
          paddingInline: "4px",
        }}
        data-tooltip-id="can-talentou-ats-tooltip"
        data-tooltip-content="Download CSV"
        data-tooltip-place="top"
      >
        <img src={isHovered ? downloadwhite : download} alt=""  />
      </CSVLink>
    </>
  );
};

export default DownloadCsv;
