import React from "react";
import { ProgressBar } from "react-loader-spinner";
import { getThemeColor } from "../Functions/commonFunctions";
// @import url('../../Styles/sassStyles.css');


const ProgressBarComponent = () => {
  return (
    <div className="mt-5 d-flex flex-column justify-content-center align-items-center">
      <ProgressBar
        height="80"
        width="80"
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor="lightgrey"
        barColor={getThemeColor()?.primaryColor}
      />
      <div className="load-text text-center fw-bold">
        Please wait, Loading...
      </div>
    </div>
  );
};

export default ProgressBarComponent;
