import React, { useEffect, useState } from "react";
import freshTeam from "../../../assets/Freshteam-gray.svg";
import settings from "../../../assets/Settings-gray.svg";
import "../Settings.css";
import connectImage from "../../../assets/Disconnected.svg";
import connectedImage from "../../../assets/ATSConnect.svg";
import dataBase from "../../../assets/Database.svg";
import zoho from "../../../assets/Zoho-gray.svg";
import make from "../../../assets/Make-gray.svg";
import zapier from "../../../assets/Zapier-gray (2).svg";
import bullHorn from "../../../assets/Bullhorn-gray.svg";
import bambooHR from "../../../assets/BambooHR-gray.svg";
import bullHorncolor from "../../../assets/Bullhorn.svg";
import bambooHRcolor from "../../../assets/bambooHr.svg";
import talentouIcon from "../../../assets/Talentou - Fav Icon 1.png";
import makecolor from "../../../assets/Make.svg";
import zohocolor from "../../../assets/Zoho.svg";
import zapiercolor from "../../../assets/Zapierlogo.svg";
import freshTeamcolor from "../../../assets/Freshteam.svg";
import databasecolor from "../../../assets/Databasecolor.svg";
import lockUnlock from "../../../assets/lock-unlock-white.svg";
import openCatIcon from "../../../assets/opencats_101225.jpg";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ATSExcelUpload from "../ATSIntegration/ATSExcelUpload";
import Backdrop from "../BackDrop";
import ATSSettingsCard from "./ATSSettingsCard";
import { API } from "../../../global";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router";
import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import Modal from "react-responsive-modal";

const astList = [
  {
    key: "talentou_ats",
    img: talentouIcon,
    subTitle:
      "Simplifies recruitment by enabling job order creation, candidate management, and interview scheduling, enhancing efficiency in the hiring process.",
  },
  {
    key: "opencats",
    img: openCatIcon,
    subTitle:
      "Simplifies recruitment by enabling job order creation, candidate management, and interview scheduling, enhancing efficiency in the hiring process.",
  },
];

// console.log(astList[0], "astList");
const exampleList = [
  {
    title: "Bullhorn",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: bullHorn,
    colorImg: bullHorncolor,
    bgImg: "#F36B21",
    active: false,
  },
  {
    title: "BambooHR",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: bambooHR,
    colorImg: bambooHRcolor,
    bgImg: "#73C41D",
    active: false,
  },
  {
    title: "Freshteam",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: freshTeam,
    // background: "#A1A1A1",
    colorImg: freshTeamcolor,
    bgImg: "#7052CC",
    active: false,
  },
  {
    title: "Zapier",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: zapier,
    colorImg: zapiercolor,
    bgImg: "#FF4A00",
    active: false,
  },
  {
    title: "Zoho",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: zoho,
    // background: "#A1A1A1",
    colorImg: zohocolor,
    bgImg: "#E42527",
    active: false,
  },
  {
    title: "make",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: make,
    colorImg: makecolor,
    bgImg: "#6C00CB",
    active: false,
  },
  {
    title: "Connect to database",
    subTitle:
      "Allows you to track hours worked, manage benefits enrollment, and run payroll.",
    connected: false,
    img: dataBase,
    // background: "#A1A1A1",
    colorImg: databasecolor,
    bgImg: "#00C22D",
    active: false,
  },
  {
    title: "Unlock ATS Integration",
    subTitle:
      "Upgrade to our premium plan to access and activate our advanced ATS options.",
    connected: false,
    img: lockUnlock,
    background: "#A1A1A1",
    colorImg: bambooHRcolor,
    bgImg: "#F36B21",
    active: false,
  },
];

const customStylesReactModal = {
  modal: {
    maxWidth: "470px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};

const ATSIntegration = () => {
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  // const [title, setTitle] = useState("");
  const [title] = useState("");
  const [newConfig] = useState(false);
  const [imagebgcolor] = useState("");
  const [colorImg] = useState("");
  const [ats_client_id] = useState("");
  const [ats_client_secret] = useState("");
  const [atsListData, setAtsListData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopUp] = useState(false);
  const [atsIntegrationData, setATSIntegrationData] = useState("");
  const [atsButtonLoading, setAtsButtonLoading] = useState(false);
  const [hideConnect, setHideConnect] = useState("");
  const [hideDisconnect, setHideDisconnect] = useState("");
  const [activeIndex, setActiveIndex] = useState("");
  const [inactiveIndex, setInactiveIndex] = useState("");

  const [isOpen, setIsOpen] = useState(false);

  // const handleOpen = () => {
  //   setIsOpen(true);
  // };

  useEffect(() => {
    getAtsList();
    // eslint-disable-next-line
  }, []);

  const getAtsList = () => {
    fetch(`${API}/admin/tenant/ats-settings/view`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((val) => {
        setLoading(false);
        if (val.success === true) {
          let atsData = [];
          // eslint-disable-next-line
          val.result.map((data) => {
            // eslint-disable-next-line
            astList.forEach((item) => {
              if (item.key === data.ats_key) {
                const obj = {
                  id: data._id,
                  title: data.ats_name,
                  subTitle: item.subTitle,
                  connected: data.status === "active" ? true : false,
                  img: item.img,
                  ats_key: data.ats_key,
                  active: data.status === "active" ? true : false,
                };
                atsData.push(obj);
              }
            });
          });
          setAtsListData(atsData.reverse());
        } else {
          // setInitialLoading(false);

          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleBackdropClose = () => {
    setIsOpen(false);
  };

  const handleClose = () => {
    setShow(false);
    setShowPopUp(false);
  };
  // const handleShow = (data) => {
  //   setShow(true);
  //   setTitle(data.title);
  //   // setImage(data.img)
  //   setImagebgcolor(data.bgImg);
  //   setColorImg(data.colorImg);
  // };

  const [list] = useState(exampleList);

  const connectLink = (data) => {
    if (data.active) {
      setShowPopUp(true);
      setATSIntegrationData(data);
    } else {
      updateAtsIntegrate(data);
    }
  };

  const updateAtsIntegrate = (data) => {
    const atsObj = {
      ats_settings_id: data.id,
      ats_key: data.ats_key,
      status: data.active ? "inactive" : "active",
    };
    fetch(`${API}/admin/tenant/ats-settings/update`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(atsObj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success === true) {
          toast.success(`Updated successfully`);
          setAtsButtonLoading(false);
          getTenantDetails();
          setShowPopUp(false);
          getAtsList();
        } else {
          if (val.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(`${val.error.msg}`);
          }
        }
      })
      .catch(() => {
        // setInitialLoading(false);
      });
  };

  const getTenantDetails = () => {
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "GET",
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          const atsSettings = response.result.tenant_ats_settings;
          localStorage.setItem("atsData", JSON.stringify(atsSettings));
        } else {
          toast.error(response.error.msg);
        }
      })
      .catch(() => {});
  };

  // const getATSConfiguration = (data) => {
  //   fetch(
  //     `${API}/admin/tenant/setting/all/${localStorage.getItem("tenant-id")}/${
  //       data.title
  //     }`,
  //     {
  //       method: "GET",
  //       headers: {
  //         authorization: `Bearer ${localStorage.getItem("auth_token")}`,
  //       },
  //     }
  //   )
  //     .then((res) => res.json())
  //     .then((val) => {
  //       if (val.success === true) {
  //         if (val.data.length === 1) {
  //           setNewConfig(true);
  //           localStorage.setItem("ats-settings-id", val.data[0]._id);
  //           setAts_client_id(val.data[0].client_id);
  //           setAts_client_secret(val.data[0].client_secret);
  //         } else {
  //           setNewConfig(false);
  //           setAts_client_id("");
  //           setAts_client_secret("");
  //         }
  //       } else {
  //         toast.error(`${val.err}`);
  //       }
  //     });
  //   setNewConfig(false);
  // };

  const atsDisable = () => {
    setAtsButtonLoading(true);
    updateAtsIntegrate(atsIntegrationData);
  };

  const onMouseConnect = (type, index) => {
    if (type === "connect") {
      setHideConnect(true);
      setActiveIndex(index);
    }
    if (type === "disconnect") {
      setHideDisconnect(true);
      setInactiveIndex(index);
    }
  };

  const onMouseDisconnect = (type, index) => {
    if (type === "connect") {
      setHideConnect(false);
      setActiveIndex(index);
    }
    if (type === "disconnect") {
      setHideDisconnect(false);
      setInactiveIndex(index);
    }
  };
  return (
    <div className="ats-main-container">
      {loading ? (
        <>
          <div className="loader-div-job">
            <ProgressBarComponent />
          </div>
        </>
      ) : (
        <>
          <Row>
            <Col>
              <p className="mt-5 custom-datatable-title">
                Choose the ATS you would like to integrate with Talentou.
              </p>
            </Col>
          </Row>
          <div>
            <Row>
              {atsListData.map((data, index) => (
                <Col lg={3}>
                  <div className="card" style={{ margin: "5px" }}>
                    <div className="card-body ats-card-body">
                      <div
                        className="icon_img"
                        style={{
                          background: data.background,
                        }}
                      >
                        <img src={data.img} alt="" />
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        <p
                          style={{
                            fontSize: "20px",
                            color: "#333333",
                            fontWeight: "bold",
                            margin: "0px",
                          }}
                        >
                          {data.title}
                        </p>
                        <p style={{ fontSize: "12px", color: "#A1A1A1" }}>
                          {data.subTitle}
                        </p>
                        <hr className="solid"></hr>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div></div>
                          {data.active ? (
                            <>
                              <button
                                className="connectedButton"
                                onClick={() => connectLink(data)}
                                onMouseEnter={() =>
                                  onMouseConnect("disconnect", index)
                                }
                                onMouseLeave={() =>
                                  onMouseDisconnect("disconnect", index)
                                }
                              >
                                {hideDisconnect && inactiveIndex === index ? (
                                  <img src={connectImage} alt="" />
                                ) : (
                                  <img src={connectedImage} alt="" />
                                )}
                                <span style={{ paddingLeft: "15px" }}>
                                  {/* Connected */}
                                </span>
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                className="connectButton"
                                onClick={() => connectLink(data)}
                                onMouseEnter={() =>
                                  onMouseConnect("connect", index)
                                }
                                onMouseLeave={() =>
                                  onMouseDisconnect("connect", index)
                                }
                              >
                                {hideConnect && activeIndex === index ? (
                                  <>
                                    <img src={connectedImage} alt="" />
                                  </>
                                ) : (
                                  <>
                                    <img src={connectImage} alt="" />
                                  </>
                                )}
                                <span style={{ paddingLeft: "15px" }}>
                                  {/* Connect */}
                                </span>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </div>
        </>
      )}

      <Row>
        <Col>
          <p className="mt-5 custom-datatable-title">Import Data</p>
        </Col>
      </Row>
      <ATSExcelUpload />

      <Row>
        <Col>
          <p className="mt-5 custom-datatable-title">Coming soon: ATSs</p>
        </Col>
      </Row>

      <div>
        <Row>
          {list.map((data, index) => (
            <Col lg={3}>
              <div className="card" style={{ margin: "5px" }}>
                <div className="card-body ats-card-body">
                  <div
                    className="icon_img"
                    style={{
                      background: data.background,
                    }}
                  >
                    <img src={data.img} alt="" />
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <p
                      style={{
                        fontSize: "20px",
                        color: "#333333",
                        fontWeight: "bold",
                        margin: "0px",
                      }}
                    >
                      {data.title}
                    </p>
                    <p style={{ fontSize: "12px", color: "#A1A1A1" }}>
                      {data.subTitle}
                    </p>
                    <hr className="solid"></hr>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <button
                          style={{ border: "none", background: "none" }}
                          // onClick={() => {
                          //   handleShow(data);
                          //   handleOpen();
                          //   getATSConfiguration(data);
                          // }}
                          disabled
                        >
                          <img src={settings} alt="" />
                        </button>
                      </div>
                      {data.active ? (
                        <>
                          <button
                            className="connectedButton"
                            onClick={() => connectLink(data)}
                          >
                            Upgrade My plan
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="connectButton"
                            disabled
                            // onClick={() => connectLink(data, index)}
                          >
                            <img src={connectImage} alt="" />
                            {/* <span style={{ paddingLeft: "15px" }}>Connect</span> */}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      {show ? (
        <ATSSettingsCard
          title={title}
          setShow={setShow}
          setIsOpen={setIsOpen}
          isOpen={isOpen}
          imagebgcolor={imagebgcolor}
          handleClose={handleClose}
          colorImg={colorImg}
          handleBackdropClose={handleBackdropClose}
          newConfig={newConfig}
          ats_client_id={ats_client_id}
          ats_client_secret={ats_client_secret}
        />
      ) : (
        ""
      )}
      <Backdrop isOpen={isOpen} onClose={handleBackdropClose} />
      <Modal
        open={showPopup}
        onClose={handleClose}
        center
        styles={customStylesReactModal}
      >
        {/* <h4 className="modal-heading">Are you sure do u want to disconnect this ATS?</h4> */}
        <p
          style={{
            textAlign: "center",
            marginTop: "20px",
            fontSize: "20px",
            fontWeight: "bolder",
          }}
        >
          Are you sure you want to disconnect this ATS?
        </p>

        <div className="btn-div">
          <button onClick={atsDisable} className="model-yes-btn">
            {atsButtonLoading ? "Loading.." : "Yes"}
          </button>
          <button onClick={handleClose} className="model-yes-btn-ats">
            No
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ATSIntegration;
