import React, { useEffect, useState } from "react";

import toast from "react-hot-toast";
import Nav from "../Reusable/Navbar/Nav";
import SideMenu from "../Reusable/SideMenu/SideMenu";
import plans from "../../assets/plan.svg";
import tenant from "../../assets/tenantAdd.svg";
import totInterview from "../../assets/purpleSchedule.svg";
// import joborders from "../../assets/Jobwhite.svg";
// import candidates from "../../assets/CandidatesWhite.svg";
import "../AdminDashboard/AdminDashboard.css";
import "./SuperAdminDashboard.css";
import recruitJob from "../../assets/recruitJob.svg";
import recruitCandidate from "../../assets/recruitCandidate.svg";
import { Col, Row } from "react-bootstrap";
import { API } from "../../global";
import ProgressBarComponent from "../Reusable/ProgressBarComponent";
import Footer from "../Footer/Footer";
import { useNavigate } from "react-router";
// import {
//   Bar,
//   BarChart,
//   CartesianGrid,
//   Legend,
//   XAxis,
//   YAxis,
//   ResponsiveContainer,
// } from "recharts";
// import tenantplan from '../../assets/tenant-plan.svg';

const SuperAdminDashboard = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [superAdminData, setSuperAdminData] = useState(null);

  useEffect(() => {
    getSuperAdminData();

    // eslint-disable-next-line
  }, []);

  const getSuperAdminData = () => {
    fetch(`${API}/super-admin/dashboard/dashboard-data`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        if (response.success) {
          setSuperAdminData(response.result);
        } else {
          if (response.error.code === 421) {
            navigate("/error");
          } else {
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const detailsArray = [
    { count: superAdminData?.total_tenants, img: tenant, title: "Tenants" },
    { count: superAdminData?.total_plans, img: plans, title: "Plans" },
  ];

  // const PlanArray = [
  //   { count: 5, plan_user: "Tenants", plan_type: "Basic" },
  //   { count: 3, plan_user: "Tenants", plan_type: "Plus" },
  //   { count: 2, plan_user: "Tenants", plan_type: "Premium" },
  // ];

  // const data = [
  //   {
  //     name: "Dec 1",
  //     Tenant: 4,
  //     Interview: 3,
  //     Review: 2,
  //   },
  //   {
  //     name: "Dec 2",
  //     Tenant: 4,
  //     Interview: 2,
  //     Review: 3,
  //   },
  //   {
  //     name: "Dec 3",
  //     Tenant: 3,
  //     Interview: 2,
  //     Review: 2,
  //   },
  //   {
  //     name: "Dec 4",
  //     Tenant: 1,
  //     Interview: 8,
  //     Review: 2,
  //   },
  //   {
  //     name: "Dec 5",
  //     Tenant: 4,
  //     Interview: 1,
  //     Review: 2,
  //   },
  //   {
  //     name: "Dec 6",
  //     Tenant: 4,
  //     Interview: 3,
  //     Review: 1,
  //   },
  //   {
  //     name: "Dec 7",
  //     Tenant: 4,
  //     Interview: 5,
  //     Review: 9,
  //   },
  // ];

  // const CustomLegend = ({ items }) => {
  //   return (
  //     <div
  //       style={{
  //         display: "flex",
  //         flexDirection: "column",
  //         marginLeft: "10px",
  //         gap: "10px",
  //       }}
  //     >
  //       {items.map((item, index) => (
  //         <div
  //           key={index}
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             marginBottom: "5px",
  //           }}
  //         >
  //           <div
  //             style={{
  //               width: "6px", // Customize the width for your vertical line
  //               height: "30px",
  //               backgroundColor: item.color,
  //               marginRight: "5px",
  //               borderRadius: "1px",
  //             }}
  //           />
  //           <span style={{ color: "black", fontSize: "14px" }}>
  //             {item.value}
  //           </span>
  //         </div>
  //       ))}
  //     </div>
  //   );
  // };

  // const legendItems = [
  //   {
  //     value: "Interview",
  //     type: "line",
  //     color: "#FFA289",
  //     payload: { value: "Interview", type: "line", color: "black" },
  //   },
  //   {
  //     value: "Tenant",
  //     type: "line",
  //     color: "#E9D6FE",
  //     payload: { value: "Tenant", type: "line", color: "black" },
  //   },
  //   {
  //     value: "Review",
  //     type: "line",
  //     color: "#96F7C7",
  //     payload: { value: "Review", type: "line", color: "black" },
  //   },
  //   // Add more legend items as needed
  // ];

  return (
    <div className="main-container">
      <Nav />
      <div className="d-flex">
        <div className="sidemenu-section">
          <SideMenu menuIndex={1} />
        </div>
        {loading ? (
          <div className="loader-div-job">
            <ProgressBarComponent />
          </div>
        ) : (
          <div className="d-flex w-100 justify-content-between flex-column">
            <div className="container-fluid py-4 super-admin-main">
              <Row className="px-2">
                <Col>
                  {" "}
                  <div className="admin-dashboard-title">Dashboard</div>
                </Col>
                <Col className="d-flex justify-content-end">
                  <select
                    disabled
                    className="form-select super-admin-dashboard-dropdown"
                  >
                    <option selected value={""}>
                      Lifetime
                    </option>
                    <option>Last 30 Days</option>
                    <option>All Time</option>
                  </select>
                </Col>
              </Row>

              <Row className="spr-admin-tenant-details px-2 py-4">
                {detailsArray.map((details, i) => (
                  <Col
                    className={`super-admin-tenants-card super-admin-tenants-card-${i} px-3 mx-2`}
                    key={i}
                    xs={3}
                  >
                    <div className="spr-ad-status-count-container py-1">
                      <div className="status-count">{details.count}</div>
                      <div className="status-text">{details.title}</div>
                    </div>
                    <div className="img-container mt-4">
                      <img src={details.img} alt="" />
                    </div>
                  </Col>
                ))}
              </Row>

              <Row className="admin-main-recruitment-data-container mx-2 pb-4 pt-4">
                <Col className="admin-recruitement-data px-3">
                  <div className="data-count-container py-1">
                    <div className="status-count">
                      {superAdminData?.total_interviews}
                    </div>
                    <div className="data-text">Interviews</div>
                  </div>
                  <div className="img-container mt-4">
                    <img src={totInterview} alt="" />
                  </div>
                </Col>
                <Col className="admin-recruitement-data px-3">
                  <div className="data-count-container py-1">
                    <div className="status-count">
                      {superAdminData?.total_job_orders}
                    </div>
                    <div className="data-text">Job Orders</div>
                  </div>
                  <div className="img-container mt-4">
                    <img src={recruitJob} alt="" />
                  </div>
                </Col>
                <Col className="admin-recruitement-data px-3">
                  <div className="data-count-container py-1">
                    <div className="status-count">
                      {superAdminData?.total_candidates}
                    </div>
                    <div className="data-text">Candidates</div>
                  </div>
                  <div className="img-container mt-4">
                    <img src={recruitCandidate} alt="" />
                  </div>
                </Col>

                <Col className="admin-recruitement-data">
                  <p className="interview-status-title">Recruitment Data</p>
                </Col>
              </Row>

              {/* commenting the code for future use   */}

              {/* <Row className="px-2 py-2">
            <Col md={5}>
              <div className="admin-dashboard-title">Plan Analytics</div>

              <div className="plan-card mt-4">
                {PlanArray.map((plan, i) => (
                  <div className={`plan-card-${i}`}>
                    <div
                      className="plan-detail"
                      style={{ textAlign: "center" }}
                    >
                      <div className="plan_count">{plan.count}</div>
                      <div>{plan.plan_user}</div>
                    </div>
                    <div className="py-2" style={{ textAlign: "center" }}>
                      {plan.plan_type}
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={7}>
              <div className="d-flex justify-content-between items-center">
                <div className="admin-dashboard-title">Usage Analytics</div>
                <select className="form-select super-admin-dashboard-dropdown">
                  <option selected value={""}>
                    Week
                  </option>
                  <option>Last 30 Days</option>
                  <option>All Time</option>
                </select>
              </div>

              <div className="usage_graph py-2 px-2 mt-3">
                <ResponsiveContainer width="100%" className="customize-graph">
                  <BarChart
                    data={data}
                    margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
                  >
                    <CartesianGrid strokeDasharray="5 5" />
                    <XAxis
                      dataKey="name"
                      tick={{ fontSize: 14 }}
                      axisLine={false}
                      tickLine={false}
                    />
                    <YAxis
                      tick={{ fontSize: 14 }}
                      axisLine={false}
                      tickLine={false}
                    />
                    <Legend
                      layout="vertical"
                      align="left"
                      verticalAlign="middle"
                      content={<CustomLegend items={legendItems} />}
                    />

                    <Bar dataKey="Interview" fill="#FFA289" />
                    <Bar dataKey="Tenant" fill="#E9D6FE" />
                    <Bar dataKey="Review" fill="#96F7C7" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </Col>
          </Row> */}
            </div>
            <div>
              <Footer />
            </div>
          </div>
        )}
      </div>
      <div></div>
    </div>
  );
};

export default SuperAdminDashboard;
