import React from "react";

import import1 from "../../../assets/import-job.svg";
import { GoFile } from "react-icons/go";
import { IoClose } from "react-icons/io5";
import BarLoader from "react-spinners/BarLoader";

const ImportPopup = ({
  handleFileChange,
  handleBrowseClick,
  handleImportModal,
  handleClose,
  importFile,
  importfile,
  importObj,
  ImportList,
}) => {
  const {
    title,
    fileError,
    fileInputRef,
    loading,
    bgFile,
    btnDisable,
    responseMsg,
    FileName,
  } = importObj;

  const SuccessIcon = () => (
    <svg
      width="60"
      height="60"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        fill="none"
        stroke="#17b05d"
        stroke-width="10"
        cx="100"
        cy="100"
        r="90"
        strokeLinecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />
      <polyline
        fill="none"
        stroke="#17b05d"
        points="44,114 86.5,142 152,69"
        strokeWidth="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="tick"
      />
    </svg>
  );

  const ErrorIcon = () => (
    <svg
      width="50"
      height="50"
      viewBox="0 0 200 200"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle
        fill="none"
        stroke="#b01717"
        stroke-width="10"
        cx="100"
        cy="100"
        r="90"
        stroke-linecap="round"
        transform="rotate(-90 100 100)"
        className="circle"
      />

      <path
        className="ui-error-line1"
        d="M70,70 L130,130"
        stroke="#b01717"
        stroke-width="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />

      <path
        className="ui-error-line2"
        d="M130,70 L70,130"
        stroke="#b01717"
        stroke-width="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return (
    <div>
      {importfile ? (
        <>
          <div className="import-upload-file mb-2">
            <div className="mb-1">
              {/* <b style={{ fontSize: "14px" }}>
                {" "}
                {uploadProgress === 100 ? "Uploaded File" : "Uploading File"}
              </b> */}
            </div>
            {responseMsg && (
              <>
                {responseMsg.includes("successfully") ? (
                  <span className="response-msg">
                    <div className="">
                      <SuccessIcon />
                    </div>

                    <div>{responseMsg}</div>
                  </span>
                ) : (
                  <span className="response-msg-error">
                    <div className="">
                      <ErrorIcon />
                    </div>
                    <div
                      style={{
                        textAlign: "center",
                        minWidth: "60%",
                        width: "70%",
                        maxWidth: "70%",
                      }}
                    >
                      {responseMsg}
                    </div>
                  </span>
                )}
              </>
            )}
            <div
              className={
                bgFile ? "upload-file-green mt-3" : "upload-file-card mt-3"
              }
            >
              <div className="d-flex gap-2 align-items-center p-2">
                {" "}
                <div>
                  <GoFile color={bgFile ? "#17b05d" : "#c9c9c9"} />
                </div>
                <div className="upload-file-name">{importFile.name}</div>
              </div>

              <div>
                {loading ? (
                  <div className="px-2">
                    <BarLoader color="#17b05d" width="150" />
                  </div>
                ) : (
                  <button
                    className="mx-1"
                    style={{ background: "none", border: "none" }}
                    onClick={() => handleClose()}
                  >
                    <IoClose size={20} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <h5>Import {title}</h5>

          <div className="import-job-dragdrop mt-4">
            <button
              className="add-job-download-btn"
              onClick={handleBrowseClick}
            >
              <img src={import1} alt="" className="mb-2" />
            </button>

            <div className="import-job-dragdropContent">
              <b>
                <span>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                  <button
                    className="add-job-download-btn"
                    onClick={handleBrowseClick}
                  >
                    Click here to select a file
                  </button>
                </span>{" "}
              </b>
            </div>
            <p style={{ color: "red" }}>{fileError}</p>
            <div className="import-job-sub-content">
              Supported formats .csv, .xlxs - Max file size 10MB
            </div>
          </div>

          <div className="import-download-temp py-3">
            <p className="import-job-sub-content" style={{ fontSize: "13px" }}>
              <sup>* </sup>Note : Download the candidate list template, fill it
              with your data, and effortlessly upload for seamless spreadsheet
              integration. Please upload all details in a single Excel sheet; do
              not include multiple sheets.
            </p>
            <button className="add-job-download-btn">
              <a
                href={ImportList}
                download={FileName}
                target="_blank"
                rel="noreferrer"
                className="template-href"
              >
                Download Template
              </a>
            </button>
          </div>
        </>
      )}

      <div className="text-center mt-3">
        <button
          className="close-button-style"
          onClick={() => handleImportModal()}
          disabled={btnDisable}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ImportPopup;
