import React, { useRef, useState } from "react";
import Modal from "react-responsive-modal";
import download from "../../../src/assets/Purple csv- Download.svg";
import downloadwhite from "../../../src/assets/White csv- Download.svg";
import { Dropdown } from "react-bootstrap";
import plus from "../../../src/assets/add-purple.svg";
import pluswhite from "../../../src/assets/add-white.svg";
import { CSVLink } from "react-csv";
import "./Jobslist.css";
import JobOrderList from "../Settings/ATSIntegration/JobOrder-template.xlsx";
import CreateJob from "./CreateJob";
import ImportJob from "./ImportJob";
import { Tooltip } from "react-tooltip";

const JoblistATS = ({
  csvData,
  csvHeaders,
  handleAddJobModal,
  showAddJob,
  setShowAddJob,
  modalTitle,
  setModalTitle,
  jobOrderId,
  getJobs,
  atsStatus,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoveredAdd, setIsHoveredAdd] = useState(false);
  const [showImportJob, setShowImportJob] = useState(false);
  // const [showAddJob, setShowAddJob] = useState(false);
  // const handleAddJobModal = () => {
  //   setShowAddJob(false);
  // };

  const handleImportJobModal = () => {
    setShowImportJob(false);
    handleClose();
  };

  const customStylesAddJob = {
    modal: {
      maxWidth: "930px", // Set your custom width here
    },
    closeIcon: {
      display: "none", // Hide the close icon
    },
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleHoverAdd = () => {
    setIsHoveredAdd(true);
  };

  const handleMouseLeaveAdd = () => {
    setIsHoveredAdd(false);
  };

  const fileInputRef = useRef(null);
  const [importJobFile, setImportJobFile] = useState(null);
  const [importfile, setImportFile] = useState(false);

  const handleClose = () => {
    setImportJobFile("");
    setImportFile(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null; // Reset the value of the file input element
    }
    getJobs();
    // fileInputRef.current.click();
  };

  return (
    <>
      <Tooltip
        id="can-talentou-ats-tooltip"
        variant="light"
        className="candidate-tooltip"
        border="1px solid #7C7B7B"
      />
      <div className="d-flex postion-relative" style={{ width: "16%" }}>
        <div className="job-icons-left">
          {atsStatus === "active" && (
            <div
              className="job-csv-download "
              onMouseEnter={handleHoverAdd}
              onMouseLeave={handleMouseLeaveAdd}
            >
              <div className="d-flex align-item-center justify-content-center">
                <Dropdown>
                  <Dropdown.Toggle
                    className="d-flex align-items-center gap-2 p-1 m-0"
                    style={{ background: "none", border: "none" }}
                    // variant="success"
                    // id="dropdown-basic"
                    data-tooltip-id="can-talentou-ats-tooltip"
                    data-tooltip-content="Add Job"
                    data-tooltip-place="top"
                  >
                    <img
                      src={isHoveredAdd ? pluswhite : plus}
                      // className="py-1"
                      alt="plus"
                      width={12}
                    />
                    {/* <div style={{ fontSize: "14px" }}>Add Job</div> */}
                  </Dropdown.Toggle>

                  <Dropdown.Menu
                    style={{
                      width: "93px",
                      backgroundColor: "#F8EBFF",
                      marginTop: "6px",
                      marginLeft: "-10px",
                      padding: "0px",
                    }}
                  >
                    <Dropdown.Item
                      style={{ fontSize: "13px" }}
                      onClick={() => {
                        setShowAddJob(true);
                        setModalTitle("Add");
                      }}
                    >
                      Add Job
                    </Dropdown.Item>
                    <Dropdown.Item
                      style={{ fontSize: "13px" }}
                      onClick={() => setShowImportJob(true)}
                    >
                      Import Job
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          )}

          <div
            className="job-csv-download"
            onMouseEnter={handleHover}
            onMouseLeave={handleMouseLeave}
          >
            <CSVLink
              data={csvData}
              headers={csvHeaders}
              filename={"joblist.csv"}
              style={{
                color: isHovered ? "white" : "#46006E",
                textDecoration: "none",
                paddingInline: "4px",
              }}
              data-tooltip-id="can-talentou-ats-tooltip"
              data-tooltip-content="Download CSV"
              data-tooltip-place="top"
            >
              <img
                src={isHovered ? downloadwhite : download}
                alt=""
              />
            </CSVLink>
          </div>
        </div>
      </div>
      <Modal
        styles={customStylesAddJob}
        open={showAddJob}
        onClose={() => handleAddJobModal()}
        center
      >
        <CreateJob
          getJobs={getJobs}
          modalTitle={modalTitle}
          handleAddJobModal={handleAddJobModal}
          jobOrderId={jobOrderId}
        />
      </Modal>
      <Modal
        styles={customStylesAddJob}
        open={showImportJob}
        onClose={() => handleImportJobModal()}
        center
      >
        <ImportJob
          fileInputRef={fileInputRef}
          importfile={importfile}
          setImportFile={setImportFile}
          importJobFile={importJobFile}
          setImportJobFile={setImportJobFile}
          handleImportJobModal={handleImportJobModal}
          handleClose={handleClose}
          JobOrderList={JobOrderList}
          getJobs={getJobs}
        />
      </Modal>
    </>
  );
};

export default JoblistATS;
