import React from 'react';
import './Settings.css';

const Backdrop = ({ isOpen, handleBackdropClose }) => {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleBackdropClose();
    }
  };
  return isOpen ? <div className="backdrop" onKeyDown={handleKeyDown} onClick={handleBackdropClose}></div> : null;
};

export default Backdrop;