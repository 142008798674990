import React, { useEffect, useState } from "react";

import { API } from "../../../global";
import { textEditorHeader } from "../../Functions/Constant";
import { toast } from "react-hot-toast";

import ProgressBarComponent from "../../Reusable/ProgressBarComponent";
import "./PolicyDocument.css";
import ReactQuill from "react-quill";
import { useNavigate } from "react-router";

const PolicyDocument = () => {
  const modules = textEditorHeader();
  const navigate = useNavigate()


  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [initialTitle, setInitialTitle] = useState("");
  const [type, setType] = useState("");
  const [policyId, setPolicyid] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [initialContent, setInitalContent] = useState("");

  useEffect(() => {
    fetchPolicyDetails();

    // eslint-disable-next-line
  }, []);

  const fetchPolicyDetails = () => {
    setLoading(true);
    fetch(`${API}/admin/tenant/policy-document`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((response) => {
        if (response.success) {
          setInitialLoading(false);
          setInitalContent(response?.result?.content);
          setContent(response?.result?.content);
          setTitle(response?.result?.title);
          setInitialTitle(response?.result?.title);
          setType(response?.result?.type);
          setPolicyid(response?.result?._id);
          setLoading(false);
        }else{
        
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setInitialLoading(false);

        // setLoading(false);
      });
  };

  const handleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleChangeContent = (val) => {
    setContent(val);
  };

  const onSave = () => {
    setLoading(true);

    const policyObj = {
      policy_id: policyId,
      content: content,
      type: type,
      title: title,
    };

    fetch(`${API}/admin/tenant/policy-document/update`, {
      method: "POST",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(policyObj),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);

        if (response.success) {
          toast.success("Policy Updated Successfully");
          fetchPolicyDetails();
        } else {
          
          if (response.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(response.error.msg);
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const reset = () => {
    setContent(initialContent);
    setTitle(initialTitle);
  };

  return (
    <>
      {initialLoading ? (
        <div className="loader-div-job">
          <ProgressBarComponent />
        </div>
      ) : (
        <>
          <div className="px-4 mt-4">
            <label className="document-label">Policy Document</label>

            <select
              className="form-select custom-spacing  w-25 mb-3 mb-3 document-input"
              aria-label="Policy-form"
              value={"eula"}
              disabled
            >
              <option value="eula" selected className="opt document-input">
                End User License Agreement (EULA)
              </option>
              <option value="privacy_policy" className="opt document-input">
                Privacy Policy
              </option>
              <option value="termsandconditions" className="opt document-input">
                Terms and Conditions
              </option>
            </select>

            <label htmlFor="title" className="document-label">
              Title
            </label>
            <div>
              <input
                onChange={handleChange}
                type="text"
                placeholder="Enter your title"
                className="w-100 p-3 rounded-3 mb-3 document-input"
                value={title}
              />

              <div>
                <label htmlFor="title" className="document-label">
                  Message
                </label>

                <div className="policy-doc-textbox">
                  <ReactQuill
                    placeholder="Enter your message"
                    className="quillData"
                    modules={modules}
                    onChange={(val) => handleChangeContent(val)}
                    value={content}
                  />
                </div>

                <div className="ql-btn d-flex gap-3 justify-content-end m-4">
                  <button
                    className="btn-save px-4"
                    disabled={loading}
                    onClick={onSave}
                  >
                    {loading ? "Saving...." : "Save"}
                  </button>
                  <button onClick={reset} className="btn btn-can px-4">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default PolicyDocument;
