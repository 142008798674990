import React, { useEffect, useState } from "react";
import "./Setpassword.css";
import { Card } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import { successPageText } from "../Functions/Constant";

const SuccesssPage = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");

  // Access the search property of the location object, which contains the query parameters
  const searchParams = new URLSearchParams(location.search);
  // Get the value of the 'id' parameter from the query string
  const type = searchParams.get("type");

  const backToLogin = () => {
    navigate("/");
  };

  useEffect(() => {
    const data = successPageText(type);
    setSubHeading(data.subHeading);
    setHeading(data.mainHeading);
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <div className="email-div">
        <Card className="varification-card m-5">
          <div className="content">
            <svg
              width="100"
              height="100"
              viewBox="0 0 200 200"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                fill="none"
                stroke="#68E534"
                stroke-width="5"
                cx="100"
                cy="100"
                r="90"
                strokeLinecap="round"
                transform="rotate(-90 100 100)"
                class="circle"
              />
              <polyline
                fill="none"
                stroke="#68E534"
                points="44,114 86.5,142 152,69"
                strokeWidth="5"
                strokeLinecap="round"
                strokeLinejoin="round"
                class="tick"
              />
            </svg>

            <div>
              <p className="main-heading-success">{heading}</p>
              <p className="sub-heading"> {subHeading}</p>

              <button
                // disabled={loadingButton}
                onClick={backToLogin}
                className="login-btn"
              >
                Back to Login
              </button>
            </div>
          </div>
        </Card>
      </div>
    </Layout>
  );
};

export default SuccesssPage;
