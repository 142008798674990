import React, { useState } from "react";
import "./InitialPage.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { API, commonUrl } from "../../global";
import { useNavigate } from "react-router";
import { Modal } from "react-responsive-modal";
import Layout from "../Layout/Layout";
import validator from "email-validator";

const customStylesReactModal = {
  modal: {
    maxWidth: "525px", // Set your custom width here
  },
  closeIcon: {
    display: "none", // Hide the close icon
  },
};

const InitialPage = () => {
  const [loading, setLoading] = useState(false);
  const [showPopUp, setShowPopUp] = useState(false);
  const [showTenantDetails, setShowTenantDeatils] = useState(false);
  const [tenantDetails, setTenantDetails] = useState([]);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  const schema = yup
    .object({
      email: yup
        .string()
        .required("Email is a required field")
        .test(
          "email-valid",
          "Please, use standard email format. (for e.g., username@example.com)",
          (value) => validator.validate(value)
        ),
    })
    .required();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onCloseModal = () => {
    setShowPopUp(false);
  };
  const onSubmit = (data) => {
    setLoading(true);
    console.log("data", data);
    setEmail(data.email);
    fetch(`${API}/admin/tenant/get-tenant-details`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify({ email: data.email }),
    })
      .then((res) => res.json())
      .then((response) => {
        setLoading(false);
        setTenantDetails(response.result);
        if (response.success) {
          if (response.result) {
            if (commonUrl === response?.result?.admin_url) {
              navigate("/SignIn");
              localStorage.setItem("emailData", data.email);
            } else {
              setShowTenantDeatils(true);
            }
          }
        } else {
         
          if (response.error.code ===  421) {
          navigate("/error");
          }else{
            if (response.error.msg === "Tenant Not Found, please contact admin") {
              setShowPopUp(true);
            }
          }
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const startSignUp = () => {
    if (email) {
      localStorage.setItem("emailData", email);
    }
    navigate("/SignUp");
  };

  const navigateToSignUp = () => {
    navigate("/SignUp");
    localStorage.removeItem("emailData");
  };

  return (
    <>
      <Layout>
        <div className="logo-div">
          <div className="login-body">
            <div className="container">
              <div className="row">
                <div
                  className="col-lg-12"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh",
                  }}
                >
                  
                    {showTenantDetails ? (
                      <div className="url-card">
                      <div className="tenant-details-div">
                        <p className="heading-label">Already registered!!</p>
                        <p style={{ textAlign: "center" }}>
                          To access the login page, kindly click on the provided
                          link below.
                        </p>
                        <div className="tenant-div">
                          {/* <img
                                src={
                                  tenantDetails?.tenant_theme_settings?.logo_url
                                }
                              ></img> */}
                          <a
                            className="link-tag"
                            href={tenantDetails?.admin_url}
                          >
                            {tenantDetails?.admin_url}
                          </a>
                        </div>
                      </div>
                      </div>
                    ) : (
                      <>
                        <div className="login-card-email">
                          <div
                            className="panel-heading"
                            style={{ textAlign: "center" }}
                          >
                            <p className="signin-label mt-4">
                              Sign in to continue
                            </p>
                          </div>
                          <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="basic-form-div">
                              <input
                                className="form-control-login"
                                placeholder="E-Mail ID"
                                {...register("email")}
                                // onKeyDown={handleKeyPress}
                              />
                              {errors?.email?.message ? (
                                <div
                                  style={{
                                    color: "red",
                                    marginTop: "5px",
                                    fontSize: "12px",
                                  }}
                                >
                                  {errors.email.message}
                                </div>
                              ) : null}

                              <div className="button-container-login mt-2">
                                {loading ? (
                                  <button className="login-btn">
                                    Loading...
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="login-btn mt-2"
                                  >
                                    Sign in
                                  </button>
                                )}
                              </div>
                            </div>
                          </form>

                          <div className="sign-up-card">
                            <div className="insideDiv">
                              <p className="signin-label mt-3">
                                New to our platform?
                              </p>
                              <p className="sub-title">
                                Create your account and get started!
                              </p>
                              <div className="button-container-login mt-2">
                                <button
                                  onClick={navigateToSignUp}
                                  className="single-signon-btn"
                                >
                                  Create Account
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Layout>
      <Modal
        styles={customStylesReactModal}
        open={showPopUp}
        onClose={() => onCloseModal()}
        center
      >
        <div className="modal-div">
          <p className="modal-heading">You are not registered with us!!</p>
          <p className="model-sub-text">
            Would you like to create an account on Talentou?
          </p>
          <div className="btn-div">
            <button className="model-cancel" onClick={onCloseModal}>
              Not Now
            </button>
            <button className="model-yes-btn" onClick={startSignUp}>
              Create
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default InitialPage;
