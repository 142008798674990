import React from "react";
import "./ConfirmWithText.css";

const ConfirmWithText = (props) => {
  const {
    remarkTitle,
    remarkSubTitle,
    remarkPlaceholder,
    remarkButtonTitle,
    commentError,
    rescheduleLoading,
    setShowCancel,
    acceptRemark,
    handleChange
  } = props;

 
  return (
    <div>
      <>
        <div className="d-grid ">
          <h4 className="reschedule-header">{remarkTitle}</h4>
          <p style={{ marginTop: "10px" }}>{remarkSubTitle}</p>
        </div>
        <div style={{ display: "grid" }}>
          <textarea
            type="text"
            onChange={handleChange}
            className="textarea-style"
            placeholder={remarkPlaceholder}
          />
          <p style={{ color: "red" }}>{commentError}</p>
          <div className="d-flex justify-content-end gap-2 mt-2">
            <button
              className="confirm-cancel-btn"
                onClick={() => setShowCancel(false)}
            >
              No
            </button>
            <button
              disabled={rescheduleLoading}
              className="confirm-yes-btn"
              // onClick={() => cancelInterview()}
                onClick={()=>acceptRemark()}
            >
              {rescheduleLoading ? "Loading..." : remarkButtonTitle}
            </button>
          </div>
        </div>
      </>
    </div>
  );
};

export default ConfirmWithText;
