// import Button from "react-bootstrap/Button";
// import "./SignIn.css";
import talentouLogo from "./../../assets/Talentou Logo-login.svg";
import { useMsal, useAccount } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { API } from "../../global";
import toast from "react-hot-toast";
import microsoft from "../../assets/login-microsoft-login.svg";
import { TailSpin } from "react-loader-spinner";

export default function SignIn() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
   console.log("account",account)
  const navigate = useNavigate();
  const [buttonName, setButtonName] = useState("Processing...");
  const [loadingButton, setLoadingButton] = useState(true);
  const [disableLoginBtn,setDisableLogin] = useState(true)
  //const [graphApiData, setGraphApiData] = useState(null);
  // login function with azure AD
  const handleLogin = async (instance) => {
    //console
    setLoadingButton(true);
    setButtonName("Processing...");
    instance.loginRedirect(loginRequest);
  };
  useEffect(() => {
    if (!account) {
      setLoadingButton(false);
      setButtonName("Sign in with Microsoft");
    }
  }, [account]);

  useEffect(()=>{
    const azureId= localStorage.getItem("azureId")
    const clientId = localStorage.getItem("clientId")
    console.log("azureId",azureId)
    if(clientId && azureId){
      setDisableLogin(false)
    }
  },[])

  useEffect(() => {
    if (account) {
      instance
        .acquireTokenSilent({
          scopes: ["User.Read"],
          account: account,
        })
        .then((response) => {
          if (response) {
            activeDirectryLogin(response);
          }
        });
    }
  });
  // active-directry-login
  const activeDirectryLogin = (res) => {
    const accessTocken = res.accessToken;
    const obj = {
      name: res.account.name,
      ad_id: res.uniqueId,
      active_directory_group_id: res.idTokenClaims.groups,
    };
    fetch(`${API}/admin/auth/login/active-directory`, {
      method: "POST",
      headers: {
        "content-type": "application/json",
        authorization: `Bearer ${accessTocken}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if (val.success) {
          toast.success(val.info.msg);
          localStorage.setItem("show_guided_tour", val.result.show_guided_tour);
          localStorage.setItem("auth_token", val.result.token);
          localStorage.setItem("user-name", val.result.name);
          localStorage.setItem(
            "user_role_keys",
            JSON.stringify(val.result.user_role_keys)
          );
          localStorage.setItem("current_role_key", val.result.current_role_key);
          if (localStorage.getItem("sharedLink")) {
            navigate(localStorage.getItem("sharedLink"));
          } else {
            if (val.result.current_role_key === "talentou_super_admin") {
              navigate("/superAdmin-dashboard");
            } else {
              navigate("/admin-dasboard");
            }
          }
          setLoadingButton(false);
        } else {
          toast.error(val.error.msg);
        }
      })
      .catch(() => {
        toast.error("Error occurred please try again");
      });
  };


  // console.log("disableLoginBtn",disableLoginBtn,"account",account)

  return (
    <div className="login-body">
      <div className="container">
        <div className="row">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "90vh",
            }}
          >
            <div className="single-signon-login-card">
              <div className="panel-heading" style={{ textAlign: "center" }}>
                <img
                  src={talentouLogo}
                  className="login-logo"
                  alt="Ignitho Logo"
                />
                {!account && (
                  <p className="signin-label">Sign in to continue</p>
                )}
              </div>
              {account || disableLoginBtn ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      margin: "20px",
                    }}
                  >
                    <TailSpin
                      visible={true}
                      height="80"
                      width="80"
                      color="#600097"
                      ariaLabel="tail-spin-loading"
                      radius="1"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  </div>
                </>
              ) : (
                <div className="button-container-login">
                  <button
                    onClick={() => {
                      handleLogin(instance);
                    }}
                    disabled={loadingButton || disableLoginBtn}
                    className="single-signon-btn mt-3"
                  >
                    <img src={microsoft} className="btn-logo" alt="azure"></img>
                    {buttonName}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="signin-page">
    //   <div className="login-box">
    //     <div
    //       className="logo"
    //       // style={{marginBottom:"25px"}}
    //     >
    //       <img src={talentouLogo} className="h-100 w-100" alt="Ignitho Logo" />
    //     </div>
    //     <br />
    //     <div>Sign in with single sign-on</div>
    //     <div className="btn-text">
    //       <div className="sign-text">
    //         <button
    //           className="btn-login"
    //           onClick={() => {
    //             handleLogin(instance);
    //           }}
    //         >
    //           {buttonName}
    //         </button>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

// if group id is found but if it is not there in database - we need to update the db by creating a function
// if group id is not found but if it is there in database - we need to delete that in the db by creating a function
