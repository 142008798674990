import React, { useState, useEffect } from "react";
import Nav from "../../Reusable/Navbar/Nav";
import "./AddQuestion.css";
import { API } from "../../../global";
import { useNavigate } from "react-router";
import deleteimg from "../../../assets/delete.svg";
import { toast } from "react-hot-toast";
import { useParams } from "react-router-dom";
import Footer from "../../Footer/Footer";
import SideMenu from '../../Reusable/SideMenu/SideMenu';
import crossIcon from '../../../assets/closeIcon.svg';
import arrowImage from '../../../assets/icons/ArrowIcon.svg';
import {textEditorHeader} from '../../Functions/Constant';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import SwitchComponent from '../../Reusable/SwitchComponent'

export default function AddQuestion() {
  let { jobid } = useParams();
  const duration = 1;
  const modules = textEditorHeader()
  const [question, setQuestion] = useState([
    {
      maximum_duration: duration,
      question: "",
      answer_key: "",
      text_box_required:false,
      questionnaire_id: "",
    },
  ]);
  const [jobOrderList, setJobOrderList] = useState([]);
  const [jobOrderID, setJobOrderID] = useState();
  const [questionnaireName, setQuestionnaireName] = useState("");
  const navigate = useNavigate();
  const [answerKeyword,setAnswerKeyWord] = useState([{index:'',value:''}]);
  const [inputKeywordList,setInputkeyWordList] =  useState([]);
  const [questionnaireId,setQuestionnaireId] =  useState('');
  const [showAddQuestion,setShowAddQuestion] = useState(false)
  const [processing,setProcessing] = useState(false)
  const [hideContinue,setHideContinue] = useState(false)


  const addMore = (i,type) => {
    const obj = {
      questionnaire_id: questionnaireId,
      question: question[i].question,
      answer_key: question[i].answer_key,
      text_box_required: question[i].text_box_required,
      maximum_duration: question[i].maximum_duration *60,
    };
    // console.log("obj",obj)
    const list = [...question];
    addQuestionApi(obj,'addMore')
    setQuestion([
      ...list,
      {
        maximum_duration: duration,
        question: "",
        answer_key: "",
        text_box_required:false,
        questionnaire_id: "",
      },
    ]);

  };

  const addQuestionApi=(obj,type)=>{
    fetch(`${API}/admin/questionnaire/question/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
      body: JSON.stringify(obj),
    })
      .then((res) => res.json())
      .then((val) => {
        if(val.success){
          if(type === 'save'){
            navigate("/questionnaires");
        }
        
        }else{
         
          if (val.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(val.error.msg);
          }
       }
      })
      .catch((err) => { 
        toast.error(err.msg);
      });
      setInputkeyWordList([])
  }

  
  //   const Questionnaire = {
  //     duration: questionDuration,
  //     questions: questionLength,
  //     name: questionnaireName,
  //     joborder_id: jobOrderID,
  //   };

    
    
  //   if (
  //     question[index].question.trim() !== "" &&
  //     question[index].answer_key.trim() !== '' 
  //   ) {
  //     let id = localStorage.getItem("Questionnaire _ID");
     
  //     const list = [...question];
  //     setInputkeyWordList([])
  //     if (a === "add") {
  //       setQuestion([
  //         ...list,
  //         {
  //           maximum_duration: duration,
  //           question: "",
  //           answer_key: "",
  //           text_box_required: false,
  //           questionnaire_id: "",
  //         },
  //       ]);
        
  //     }
  //   } else {
  //     toast.error("Please fill the required details");
  //   }

    

  // };

  const saveQuestion =()=>{
    const list = [...question];
    const qusData=list[list.length -1]
    const obj = {
      questionnaire_id: questionnaireId,
      question: qusData.question,
      answer_key: qusData.answer_key,
      text_box_required: qusData.text_box_required,
      maximum_duration:qusData.maximum_duration *60,
    };
    addQuestionApi(obj,'save')
  }

  useEffect(() => {
    getJobOrder();
    // eslint-disable-next-line
    if (jobid !== undefined) {
      setJobOrderID(jobid);
    }

    let id = localStorage.getItem("Questionnaire_ID");
    if (id !== null || id !== undefined) {
      getQuestionnaireById();
    }
    // eslint-disable-next-line
  }, []);

  const getJobOrder = () => {
    fetch(`${API}/admin/job-order/list`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("auth_token")}`,
      },
    })
      .then((res) => res.json())
      .then((jobData) => { 
        if(jobData.success){
          setJobOrderList(jobData.result); 
        }else{
          if (jobData.error.code ===  421) {
            navigate("/error");
          }else{
            toast.error(jobData.error.msg)
          }
        }
      
      })
      .catch((err) => console.error("API Failed:", err));
  };

  const getQuestionnaireById = () => {
    fetch(
      `${API}/admin/question/questionnaire/${localStorage.getItem(
        "Questionnaire_ID"
      )}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then((ques) => {
        setJobOrderID(ques.data.joborder_id);
      
        setQuestionnaireName(ques.data.name);
      })
      .catch((err) => console.error("API Failed:", err));
  };

  

  
  const handleDecrease = (i, maxDuration) => {
    if (maxDuration > 1) {
      setQuestion((current) => current.map((item, index) => i === index ? { ...item, maximum_duration: item.maximum_duration - 1 } : item))
    }
  };

  const handleIncrease = (i, maxDuration) => {
    if (maxDuration < 3) {
      setQuestion((current) => current.map((item, index) => i === index ? { ...item, maximum_duration: item.maximum_duration + 1 } : item))
    }
  };

 
  //Add new Question function
  const handleRemoveClick = (index) => {
    const list = [...question];
    list.splice(index, 1);
    setQuestion(list);
  
  };
 

  // Text input code
  const handleChange = (check, index) => {
    const list = [...question];
    list[index]["text_box_required"] = check
    setQuestion(list);
  };
  const handleChangeQuestion =(content,i)=>{
    const list = [...question];
    list[i]['question'] = content;
    setQuestion(list);
  }

  


  const handleKeyDown = (event,i) => {
    if (event.key === "Enter") {
      if(answerKeyword.value !== undefined){
        setInputkeyWordList([...inputKeywordList, answerKeyword.value]);
      setAnswerKeyWord("");
      const list = [...question];
      let answerArray = [...inputKeywordList, answerKeyword.value];
      list[i]["answer_key"] =answerArray.toString()
      setQuestion(list);
      }
      else{
        toast.error("Please type the keywords")
      }
    }
  };
  
  const removeKeyWord =(data,i)=>{
    const list = [...question];
    let tempList = (list[i]["answer_key"]).split(',')
    const findIndex = tempList.findIndex((item) => item === data);
    if (findIndex !== -1) {
      tempList.splice(findIndex, 1);
      list[i]["answer_key"] = tempList.toString()
      setQuestion(list);
      setInputkeyWordList([...tempList]);
    }

  }

  const handleInputChangeKeyWord =(i,e)=>{
    let obj={
      index:i,
      value:e.target.value
    }
    setAnswerKeyWord(obj)
  }

  
  const saveQuestionnaire =()=>{
    const questionnaire ={
      job_order_id:jobOrderID,
      name:questionnaireName
    }
    setHideContinue(true)
    setProcessing(true)
    


    fetch(
      `${API}/admin/questionnaire/create`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${localStorage.getItem("auth_token")}`,
        },
        body: JSON.stringify(questionnaire),
      }
    )
      .then((res) => res.json())
      .then((response) => {
        if(response.success){
          setQuestionnaireId(response.result.questionnaire_id)
          setShowAddQuestion(true)
          setProcessing(false)
          
        }
       
      })
      .catch((err) =>     setHideContinue(false)
      );

  }
  return (
    <>
      <div>
        <Nav />
        <div className="d-flex">
          <div className="sidemenu-section">
            <SideMenu menuIndex={3} />
          </div>
          <div className="w-100">
            {/*Question body */}
            <div className="mx-4 list-head pb-4">
              <div className="d-flex justify-content-between pt-4 px-4">
                <button className="icon-div"  onClick={() => navigate(-1)}>
                  <img
                    src={arrowImage}
                    alt=""
                    className="backImage"
                  />
                </button>
                <div className="table-label">
                  <h5>Add a new questionnaire </h5>
                </div>
                <div></div>
              </div>

              <div className="question-body mx-4 rounded-4 mt-3 pt-3 px-4">
                {/* <form className=""> */}
                <div className="form-group question-name">
                  <div className="d-flex justify-content-between w-100 gap-3">
                    <div className="w-100">
                      <label for="QuestionName" className="fs-6 fw-bold">
                        Questionnaire Name<sup>*</sup>
                      </label>
                      <br />
                      <div className="d-flex gap-2">
                        <input
                          type="text"
                          className="fs-6 form-control-lg p-2 w-100"
                          id="Questionnaire Name"
                          placeholder="Enter the questionnaire name"
                          value={questionnaireName}
                          onChange={(e) => setQuestionnaireName(e.target.value)}
                          disabled={showAddQuestion}
                        />
                      
                      </div>
                    </div>
                    <div className="w-100">
                      <label for="QuestionName" className="fs-6 fw-bold">
                        Job Order<sup>*</sup>
                      </label>
                      <br />
                      <select
                        className="form-select add-job-select p-2 "
                        aria-label="Default select example"
                        style={{ color: "#707070" ,height:"45px"}}
                        disabled={
                          jobid !== undefined && showAddQuestion ? true : false
                        }
                        value={jobOrderID}
                        onChange={(e) => setJobOrderID(e.target.value)}
                      >
                        <option selected disabled value={""}>
                          Select the Job Order
                        </option>
                        {jobOrderList?.map((list, i) => (
                          <>
                            {!list.questionnaire && (
                              <option value={list?._id}>{list.job_role}</option>
                            )}
                          </>
                        ))}
                      </select>
                    </div>
                  </div>
                  {!hideContinue && (
                    <div className="d-flex justify-content-end w-100 mt-2 ">
                      <button
                        className={!questionnaireName  || !jobOrderID  ? "continue-button-disable":"continue-button"}
                        onClick={saveQuestionnaire}
                        disabled={!questionnaireName && !jobOrderID}
                      >
                        {processing ? "Processing..." : "Continue"}
                      </button>
                    </div>
                   )} 
                </div>
                {showAddQuestion && (
                  <>
                    <div className="py-1 hr-line-ques">
                      <hr />
                    </div>
                    {question.map((a, i) => (
                      <div>
                        <div className="form-group question-input">
                          <div className="d-flex justify-content-between">
                            <div className="fs-6 fw-bold">
                              Question {i + 1}
                              <sup>*</sup>
                            </div>
                            <div className="d-flex gap-3 add-opt">
                              {i !== 0 && (
                                <button style={{border:"none",background:"none"}} onClick={() => handleRemoveClick(i)}>
                                  <img
                                  src={deleteimg}
                                  alt="delete"
                                  style={{ cursor: "pointer" }}
                                  
                                />
                                </button>
                                
                              )}
                            </div>
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <ReactQuill
                              placeholder="Enter your question here"
                              modules={modules}
                              value={a.question}
                              onChange={(val) => handleChangeQuestion(val, i)}
                            />
                          </div>
                        </div>

                        <div>
                          <div className="d-flex gap-1">
                            <label for="answerIndex" className="fs-6 fw-bold">
                              Answer {i + 1}
                              <sup>*</sup>
                            </label>{" "}
                            <p>
                              <i>
                                (Please Press Enter button after typing
                                keywords)
                              </i>
                            </p>
                          </div>

                          <Row>
                            <Col>
                              <div className=" border border-3 ans-input-div fs-6">
                                <div>
                                  <ul className="key-input-ul">
                                    {a.answer_key &&
                                      a.answer_key.split(",").map((data) => (
                                        <li className="key-input-li">
                                          {data}
                                          <span
                                           
                                          >
                                            <button style={{border:"none",background:"none"}}
                                              onClick={() =>
                                              removeKeyWord(data, i)
                                            }>
                                              <img
                                              className="keyword-remove"
                                               alt=""
                                               src={crossIcon}
                                            />
                                            </button>
                                            
                                          </span>
                                        </li>
                                      ))}
                                  </ul>
                                </div>

                                <input
                                  key={i}
                                  type="text"
                                  placeholder="Enter your answer keywords here"
                                  className="key-input"
                                  onKeyDown={(e) => handleKeyDown(e, i)}
                                  onChange={(e) =>
                                    handleInputChangeKeyWord(i, e)
                                  }
                                  value={
                                    answerKeyword.index === i
                                      ? answerKeyword?.value
                                      : ""
                                  }
                                  id="answerKey"
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>

                        {/*Body Footer options */}
                        <div className="pt-2 d-flex justify-content-between mb-2">
                          <div className="pt-2 d-flex gap-5">
                            <div>
                              <h5 className="fs-6 fw-bold pb-2">
                                Duration for Question {i + 1} (min)
                              </h5>
                              <div className="number d-flex">
                                <span
                                  className="minus py-3"
                                  style={{ cursor: "pointer" }}
                                 
                                >
                                  <button
                                  style={{border:"none",background:"none"}}
                                   onClick={() =>
                                    handleDecrease(i, a.maximum_duration)
                                  }
                                  >
                                  -
                                  </button>
                                  
                                </span>
                                <input
                                  type="number"
                                  value={a.maximum_duration}
                                  placeholder="Select Duration"
                                  readOnly
                                  name="maximum_duration"
                                  // onChange={(e) => handleInputChange(e, i)}
                                  className="form-control text-center"
                                />
                                <span
                                  className="plus py-3"
                                  style={{ cursor: "pointer" }}
                                 
                                >
                                  <button
                                  style={{border:"none",background:"none"}}
                                   onClick={() =>
                                    handleIncrease(i, a.maximum_duration)
                                  }
                                  >
                                  +
                                  </button>
                                </span>
                              </div>
                            </div>

                            <div>
                              <div className="mt-0 ms-5 gap-3 text-center">
                                <label className="fs-6 fw-bold">
                                  Text Input
                                </label>
                                <br />
                                <div className="mt-3">
                                  <SwitchComponent
                                    switchButton={handleChange}
                                    type={i}
                                    value={a.text_box_required}
                                    switchText={true}
                                    
                                  />
                                </div>
                                
                              </div>
                            </div>
                          </div>

                          <div className="d-flex gap-3 py-5 buttons">
                            <button
                              type="button"
                              className="btn btn-md"
                              style={{
                                visibility:
                                  i === question.length - 1
                                    ? "visible"
                                    : "hidden",
                                backgroundColor: "#46006E",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                              onClick={() => addMore(i, "add")}
                            >
                              Add a new question
                            </button>
                            <button
                              className="btn btn-md"
                              style={{
                                visibility:
                                  i === question.length - 1
                                    ? "visible"
                                    : "hidden",
                                backgroundColor: "#00C22D",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                              disabled={
                                i === question.length - 1 ? false : true
                              }
                              onClick={saveQuestion}
                            >
                              Save
                            </button>
                            <button
                              className="btn btn-md"
                              style={{
                                visibility:
                                  i === question.length - 1
                                    ? "visible"
                                    : "hidden",
                                backgroundColor: "#E9E9E9",
                                fontWeight: "500",
                              }}
                              onClick={() => navigate("/questionlist")}
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
            <div className="mt-4">
              <Footer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

