import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import dashIcon from "../../../assets/dash-icon.svg";
import joblist from "../../../assets/jobList.svg";
import settings from "../../../assets/dash-settings.svg";
import arrowImage from "../../../assets/icons/ArrowIcon.svg";
import talentuo from "../../../assets/Talentou-Logo-nav.svg";

const exampleRowList = [
  { slNo: "1", name: "AB Corporate", email: "info@abc.com" },
  { slNo: "2", name: "DE Foundation", email: "sales@def.com" },
  { slNo: "3", name: "GH International", email: "info@ghi.com" },
  { slNo: "4", name: "JK Limited", email: "info@jkl.com" },
  { slNo: "5", name: "MN Outsourcing", email: "sales@mno.com" },
];

const ThemePreview = (props) => {
  const {
    shadeTwo,
    primaryColor,
    slectedLogo,
    tableStyle,
    shadeOne,
    primaryShade4,
    primaryBtnColor,
    secondaryBtnColor,
  } = props;
  return (
    <>
      <Row>
        <Col>
          <div style={{ margin: "10px" }}>
            <p className="textLabel">Preview</p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <nav
            className="navbar px-4"
            style={{ backgroundColor: primaryColor }}
          >
            <div>
              {slectedLogo ? (
                <img
                  src={slectedLogo}
                  alt="logo"
                  style={{ width: "100%", height: "50px" }}
                  className="img-fluid"
                />
              ) : (
                <img
                  src={talentuo}
                  alt="logo"
                  style={{ width: "100%", height: "50px" }}
                  className="img-fluid"
                />
              )}
            </div>

            <div
              className="profile-card ms-auto align-middle "
              style={{ marginRight: "30px" }}
            ></div>
          </nav>
          <div
            className="container-fluid text-center d-flex m-0 p-0"
            style={{ backgroundColor: primaryShade4 }}
          >
            <div
              className="sidemenu-section-preview"
              style={{ backgroundColor: shadeOne }}
            >
              <div
                className="example-side-nav"
                style={{ backgroundColor: shadeOne }}
              >
                <div
                  className="pt-4 px-3 gap-4 side-menu-container"
                  style={{ backgroundColor: shadeOne }}
                >
                  <img
                    src={dashIcon}
                    alt="dashboard"
                    className="side-menu-icon"
                    data-tooltip-id="sidemenu-tooltip"
                    data-tooltip-content="Dasbboard"
                    data-tooltip-place="bottom"
                  />
                  <img
                    src={joblist}
                    alt="joblist"
                    width="20px"
                    className="side-menu-icon"
                    data-tooltip-id="sidemenu-tooltip"
                    data-tooltip-content="Job List"
                    data-tooltip-place="bottom"
                  />
                  <img
                    src={settings}
                    alt="settings"
                    width="20px"
                    data-tooltip-id="sidemenu-tooltip"
                    data-tooltip-content="Settings"
                    data-tooltip-place="bottom"
                  />
                </div>
              </div>
            </div>
            <div className="w-75 h-100 d-flex ms-5 mt-5">
              <div
                className="table-class w-100"
                style={{
                  background: "#ffffff",
                  padding: "20px",
                  borderRadius: "10px",
                  height: "100%",
                }}
              >
                <div className="d-flex  align-items-end">
                  <div
                    style={{
                      borderRadius: "100%",
                      padding: "3px 5px 5px 5px",
                      background: shadeTwo,
                      border: "none",
                    }}
                  >
                    <img
                      src={arrowImage}
                      style={{ cursor: "pointer" }}
                      alt="back"
                      className="backImage"
                    />
                  </div>
                  <div
                    style={{
                      color: primaryColor,
                      fontSize: "20px",
                      fontWeight: "600",
                      paddingLeft: "130px",
                    }}
                  >
                    Sample Data
                  </div>
                </div>

                <div className="table-main-div">
                  <table style={tableStyle}>
                    <thead>
                      <tr
                        style={{
                          borderBottom: `1px solid ${primaryColor} `,
                        }}
                      >
                        <th
                          className="custom-th"
                          style={{ color: primaryColor }}
                        >
                          S.No
                        </th>
                        <th
                          className="custom-th"
                          style={{ color: primaryColor }}
                        >
                          Company Name
                        </th>
                        <th
                          className="custom-th"
                          style={{ color: primaryColor }}
                        >
                          Company Name
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {exampleRowList.map((data) => (
                        <tr className="custom-td">
                          <td>{data.slNo}</td>
                          <td>{data.name}</td>
                          <td>{data.email}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Row>
                    <Col xs={6}>
                      <div style={{ display: "flex", marginTop: "40px" }}>
                        <button
                          className="custom-button-primary"
                          style={{
                            backgroundColor: primaryBtnColor,
                            borderColor: primaryBtnColor,
                          }}
                        >
                          Export
                        </button>
                        <button
                          className="custom-button-secondary"
                          style={{
                            backgroundColor: secondaryBtnColor,
                            borderColor: secondaryBtnColor,
                          }}
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ThemePreview;
