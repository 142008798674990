import React from "react";
import arrowImage from "../../assets/icons/ArrowIcon.svg";
import video from "../../assets/Video-Icon.png";
import "./ViewRecordings.css";
import { useNavigate } from "react-router";

const SelectVideoPage = ({type}) => {
  const navigate = useNavigate();
  return (
    <div className={`py-3 px-4 h-75 d-flex ${type !== 'view'? '':'mt-5'}`}>
      <div >
        {type !== 'view' &&
        <button onClick={() => navigate(-1)} className="icon-div">
        <img
          src={arrowImage}
          style={{ cursor: "pointer" }}
          alt="back"
          className="backImage"
          
        />
      </button>
        }
        
      </div>
      <div className={`${type !== 'view'?'video-preview mt-5':'video-preview-view'}`}>
        <img
          src={video}
          className="video-icon"
          alt="Video-Icon"
          style={{ width: "260px", height: "220px" }}
        />
        <div
          style={{
            color: "#600097",
            fontSize: 20,
            fontWeight: 600,
            cursor: "default",
          }}
        >
          Select a video to view it here
        </div>
      </div>
    </div>
  );
};

export default SelectVideoPage;
